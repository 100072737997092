import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {filterMsalAccounts} from "../../utils/filter-msal.accounts";
import {AccountInfo} from "@azure/msal-browser";

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
})
export class UserInfoComponent implements OnInit {
    public name: string;
    initials: string;

    constructor(private authService: MsalService) {
    }

    public ngOnInit() {
        const msalAccount = filterMsalAccounts(this.authService.instance.getAllAccounts())[0]
        if (msalAccount) {
            this.determineName(msalAccount);
        }
    }

    public logout() {
        this.authService.logout();
    }

    private determineName(msalAccount: AccountInfo) {
        const username = msalAccount.idTokenClaims.name || '';
        const parts = username.split(',');

        if (parts.length === 2) {
            const firstName = parts.pop().trim();
            const lastName = parts[0];

            this.initials = `${firstName[0]}${lastName[0]}`;
            this.name = `${firstName} ${parts.join(',')}`;
        } else {
            this.name = username;
            this.initials = username.split(' ').map(word => word[0]).join('').toUpperCase();
        }
    }
}
