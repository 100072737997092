import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Tag} from '../../models/tag';
import {Media} from '../../models/media';
import {MediaService} from '../../services/media.service';

@Component({
    selector: 'app-media-info-tags',
    templateUrl: './media-info-tags.component.html'
})
export class MediaInfoTagsComponent implements OnChanges {
    @Input() media: Media;

    @Output() tagClick = new EventEmitter();
    @Output() tagsSaved = new EventEmitter<Media>();

    control = new FormControl([], control => {
        return control.value.every(tag => tag.name.length <= 255) ? {tagNameTooLong: true} : null;
    });
    showTagSelect = false;
    loading = false;
    btnLabel: string;
    tags: Tag[] = [];

    constructor(
        private mediaService: MediaService
    ) {
    }

    ngOnChanges(): void {
        this.initTags(this.media);
    }

    editTags() {
        this.control.patchValue(this.tags);
        this.showTagSelect = true;
    }

    async saveTags() {
        this.loading = true;

        try {
            const tagIds = this.control.value.map((tag: Tag) => tag.id);
            const mediaItem = await this.mediaService.patchTags(this.media.id, [...tagIds]).toPromise();

            this.tagsSaved.emit(mediaItem);
            this.initTags(mediaItem);
        } catch (e) {
            console.error('saveTags error', e);
        } finally {
            this.showTagSelect = false;
            this.loading = false;
        }
    }

    private initTags(media: Media) {
        if (media) {
            this.tags = [...media.tags];
            this.btnLabel = media.tags && media.tags.length > 0 ? 'bewerken' : 'toevoegen';
        }
    }
}
