<vwui-header>
    {{ environment.appName }}
</vwui-header>
<vwui-layout>
    <form [formGroup]="form" (ngSubmit)="saveOrganization()">
        <vwui-button label="Vorige" type="button" slot="action" class="mb-3" routerLink="/manage/organizations">
            Vorige
        </vwui-button>
        <vwui-card>
            <vwui-card-body layout="half">
                <vwui-form-group inputId="name" label="Naam">
                    <vwui-input inputId="name" type="text" formControlName="name"></vwui-input>
                    <div *ngIf="form.get('name').hasError('exists')" class="text-danger">
                        <small>Organization moet uniek zijn</small>
                    </div>
                </vwui-form-group>

                <vwui-form-group inputId="parent" label="Bovenliggende organisatie">
                    <app-organization-select
                        bindLabel="name"
                        formControlName="parent"
                        [excludeOrganizationId]="form.value.id"
                    ></app-organization-select>
                </vwui-form-group>

                <vwui-form-group inputId="associations" [label]="environment.appNameShort === 'InFrame' ? 'Kantoorlocaties' : 'Bedrijfsnamen'">
                    <app-association-select
                        bindLabel="name"
                        formControlName="associations"
                        [multiple]="true"
                    ></app-association-select>
                </vwui-form-group>
            </vwui-card-body>
        </vwui-card>
        <vwui-button class="is-primary mt-2" type="submit" [loading]="loading" [disabled]="loading || form.invalid">Opslaan</vwui-button>
    </form>
</vwui-layout>
