<div class="media-processing-sidebar">
    <ng-container *ngIf="hasMediaItems;else noMediaItems">
        <ng-container *ngIf="selectedMediaItems.length > 0;else noMediaItemsSelected">
            <div>
                <h6>Bestanddetails</h6>
                <p>Selecteer hieronder de gewenste bestanddetails.</p>
            </div>

            <form [formGroup]="form" *ngIf="form" class="media-processing__form"
                  [class.media-processing__form--visible]="mobileFormVisible">
                <ng-container *ngIf="!isApproval">
                    <div class="beel-form-group">
                        <label class="beel-label" for="projectList">Project</label>
                        <ng-select
                            bindLabel="name"
                            placeholder="Selecteer project"
                            id="projectList"
                            notFoundText="Geen resultaten"
                            labelForId="projectList"
                            formControlName="selectedProject"
                            [readonly]="externalUserService.isExternalUser()"
                            [items]="projects$ | async"
                            [typeahead]="onInput"
                            (scrollToEnd)="scrollToEnd()"
                            [clearable]="true"
                            [searchable]="true">
                        </ng-select>
                    </div>

                    <div class="beel-form-group mt-3">
                        <label class="beel-label" for="albums">Albums (optioneel)</label>
                        <ng-select
                            bindLabel="name"
                            placeholder="Selecteer album (optioneel)"
                            id="albums"
                            notFoundText="Geen resultaten"
                            formControlName="selectedAlbums"
                            [readonly]="externalUserService.isExternalUser()"
                            [items]="albums$ | async"
                            [typeahead]="onInputAlbum"
                            (scrollToEnd)="scrollToEndOfAlbums()"
                            [clearable]="true"
                            [searchable]="true"
                            [multiple]="true">
                        </ng-select>
                    </div>
                </ng-container>

                <div class="beel-form-group mt-3">
                    <label class="beel-label" for="tag">Tags</label>
                    <app-tags-form-control id="tag" formControlName="selectedTags">
                    </app-tags-form-control>
                </div>

                <div class="beel-form-group mt-3">
                    <label class="beel-label" for="tag">Document</label>
                    <app-file-item
                        *ngFor="let document of form.value.documents"
                        [fileName]="document.originalName"
                        [showRemoveButton]="true"
                        (removeClick)="removeDocument(document)"
                        class="mb-3"
                    ></app-file-item>
                    <app-file-item
                        *ngFor="let file of newFiles"
                        [fileName]="file.name"
                        [showRemoveButton]="true"
                        (removeClick)="removeFile(file)"
                        class="mb-3"
                    ></app-file-item>
                    <vwui-file-input [formControl]="addFileControl"></vwui-file-input>
                </div>

                <div class="app-alert u-mt-m" *ngIf="selectedMediaItems.length > 1">
                    Let op: Tags en PDF Document op geselecteerde documenten worden overschreven bij alle geselecteerde afbeeldingen.
                </div>

                <vwui-form-group label="Goedkeuring" *ngIf="isApproval">
                    <div class="choice-group">
                        <label class="choice-item radio">
                            <input type="radio" formControlName="approved" [value]="true">
                            <span class="choice-item__label">
                        Goedgekeurd
                    </span>
                        </label>
                        <label class="choice-item radio">
                            <input type="radio" formControlName="approved" [value]="false">
                            <span class="choice-item__label">
                        Afgekeurd
                    </span>
                        </label>
                    </div>
                </vwui-form-group>
            </form>

            <div class="beel-form-group mt-3 media-processing__sidebar-actions">
                <vwui-button class="is-primary" (click)="toggleMobileForm()">
                    {{mobileFormVisible ? 'Gereed' : 'Bestanddetails bewerken'}}
                </vwui-button>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #noMediaItems>
    <div>
        <h6>Geen media items</h6>
        <p *ngIf="!isApproval">Upload eerst media items om vervolgens de bestandsdetails te selecteren.</p>
        <p *ngIf="isApproval">Er zijn geen media items om goed te keuren.</p>
    </div>
</ng-template>

<ng-template #noMediaItemsSelected>
    <div>
        <h6>Bestanddetails</h6>
        <p>Selecteer eerst een media item om de bestanddetails aan te passen.</p>
    </div>
</ng-template>
