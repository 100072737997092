import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, defer, merge, Observable, of, Subscription} from 'rxjs';
import {Project} from '../../models/project';
import {Media} from '../../models/media';
import {ExternalUserService} from '../../services/external-user.service';
import {debounceTime, distinctUntilChanged, filter, finalize, map, switchMap} from 'rxjs/operators';
import {Tag} from '../../models/tag';
import {filteredInfiniteScrollObservable} from '../utils/pagination';
import {ProjectAlbum} from '../../models/project-album';
import {createEmptyPageResponse} from '../../models/page-response';
import {Document} from '../../models/document';
import {DocumentService} from '../../services/document.service';
import {environment} from '../../../../environments/environment';
import {ProjectAlbumService} from '../../services/project-album.service';

export interface MediaProcessingFormData {
    selectedProject: Project;
    selectedAlbums: ProjectAlbum[];
    selectedTags: Tag[];
    documents: Document[];
    files: { name: string; file: File }[];
    approved: boolean;
}

@Component({
    selector: 'app-media-processing-sidebar',
    templateUrl: './media-processing-sidebar.component.html'
})
export class MediaProcessingSidebarComponent implements OnInit, OnChanges, OnDestroy {
    private projectId$ = new BehaviorSubject<number>(null);

    @Input() hasMediaItems: boolean;
    @Input() selectedMediaItems: Media[] = [];
    @Input() isExternal = false;
    @Input() isApproval = false;
    @Input() project: Project;
    @Input() albums: ProjectAlbum[];

    @Output() update = new EventEmitter<MediaProcessingFormData>();

    mobileFormVisible = false;
    subscriptions: Subscription[] = [];

    form = new FormGroup({
        selectedProject: new FormControl(null, Validators.required),
        selectedAlbums: new FormControl([]),
        selectedTags: new FormControl([], control => {
            return control.value.every(tag => tag.name.length <= 255) ? {tagNameTooLong: true} : null;
        }),
        documents: new FormControl([]),
        approved: new FormControl(null)
    });
    addFileControl = new FormControl(null);
    previousSelectionEmpty = true;

    onInput = new BehaviorSubject<string>('');
    loadMoreSubject = new BehaviorSubject<void>(null);
    loading = false;
    projects$: Observable<Project[]> = this.externalUserService.isExternalUser()
        ? this.projectId$.pipe(
            filter(it => !!it),
            switchMap(id => this.projectService.getProjectDetail(+id)),
            map(it => [it])
        )
        : filteredInfiniteScrollObservable(
            this.onInput.pipe(debounceTime(200)),
            this.loadMoreSubject,
            (page, query) => {
                this.loading = true;
                return this.projectService.getList(page, null, null, query).pipe(
                    finalize(() => this.loading = false)
                );
            }
        );

    onInputAlbum = new BehaviorSubject<string>('');
    loadMoreAlbumSubject = new BehaviorSubject<void>(null);
    loadingAlbum = false;
    albums$: Observable<ProjectAlbum[]> = filteredInfiniteScrollObservable(
        combineLatest([
            this.onInputAlbum.pipe(debounceTime(200)),
            merge(
                defer(() => of(this.form.value.selectedProject)),
                this.form.get('selectedProject').valueChanges
            )
        ]),
        this.loadMoreSubject,
        (page, [query, project]) => {
            this.loadingAlbum = true;
            return project ? this.projectService.getAlbums(project.id.toString(), query, page).pipe(
                finalize(() => this.loadingAlbum = false)
            ) : of(createEmptyPageResponse());
        }
    );

    newFiles: { name: string, file: File }[] = [];

    scrollToEnd = () => this.loadMoreSubject.next(null);
    scrollToEndOfAlbums = () => this.loadMoreAlbumSubject.next(null);
    toggleMobileForm = () => this.mobileFormVisible = !this.mobileFormVisible;

    constructor(
        public externalUserService: ExternalUserService,
        private projectService: ProjectService,
        private documentService: DocumentService,
        private toast: ToastrService
    ) {
    }

    ngOnInit() {
        this.onFormValueChange(this.form.value, true);
        this.subscriptions.push(
            this.form.valueChanges.subscribe(value => this.onFormValueChange(value)),
            this.form.get('selectedProject').valueChanges.subscribe(value => {
                const albumsControl = this.form.get('selectedAlbums') as FormControl;
                if (value) {
                    albumsControl.enable({emitEvent: false});
                    const selectedAlbums: ProjectAlbum[] = albumsControl.value;
                    albumsControl.setValue(selectedAlbums?.filter(it => it.project.id === value.id), {emitEvent: false});
                } else {
                    albumsControl.disable({emitEvent: false});
                    albumsControl.setValue([], {emitEvent: false});
                }
            }),
            this.addFileControl.valueChanges.pipe(
                distinctUntilChanged()
            ).subscribe(file => {
                if (file) {
                    this.addFileControl.setValue(null, {emitEvent: false});
                    if (this.newFiles.length < 10) {
                        if ((file.file as File).size <= environment.maxDocumentSizeMB * 1024 * 1024) {
                            this.newFiles.push(file);
                        } else {
                            this.toast.error(`Het bestand mag niet groter zijn dan ${environment.maxDocumentSizeMB} MB.`);
                        }
                    } else {
                        this.toast.error('Er kunnen maximaal 10 bestanden toegevoegd worden.');
                    }
                }
            })
        );
        this.form.get('selectedAlbums').setValue(this.albums ?? []);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedMediaItems) {
            const selectedMediaItems = changes.selectedMediaItems;
            if (selectedMediaItems.currentValue.length === 0) {
                this.previousSelectionEmpty = true;
            } else if (selectedMediaItems.currentValue.length >= 1 && this.previousSelectionEmpty) {
                this.setInitialFormValue(selectedMediaItems.currentValue[0]);
                this.previousSelectionEmpty = false;
            }
        }
        if (changes.projectId) {
            this.projectId$.next(changes.projectId.currentValue);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    onFormValueChange(value: MediaProcessingFormData, initial: boolean = false) {
        this.update.emit({
            selectedProject: value.selectedProject,
            selectedAlbums: value.selectedAlbums,
            selectedTags: value.selectedTags,
            documents: value.documents,
            files: this.newFiles,
            approved: value.approved,
        });
    }

    setInitialFormValue(initialMediaItem: Media) {
        this.newFiles = [];
        this.addFileControl.setValue(null, {emitEvent: false});
        const formData: Omit<MediaProcessingFormData, 'files'> = {
            selectedProject: initialMediaItem.project ?? this.project ?? null,
            selectedAlbums: this.albums ?? [],
            selectedTags: initialMediaItem.tags ? initialMediaItem.tags : [],
            approved: initialMediaItem.approved ? initialMediaItem.approved : null,
            documents: initialMediaItem.documents
        };

        this.form.setValue(formData);
    }

    removeFile(file: { name: string, file: File }) {
        const index = this.newFiles.indexOf(file);
        if (index !== -1) {
            this.newFiles.splice(index, 1);
        }
    }

    removeDocument(document: Document) {
        const documents = this.form.value.documents
            .filter(it => it !== document);

        this.form.controls.documents.setValue(documents);
    }
}
