import {
    MSAL_GUARD_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalService
} from '@azure/msal-angular';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {ExternalUserService} from '../services/external-user.service';
import {Location, PlatformLocation} from '@angular/common';
import {UserService} from '../services/user.service';
import {Observable, of} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';
import {InteractionStatus} from '@azure/msal-browser';

@Injectable()
export class BeeldbankMsalGuard extends MsalGuard implements CanActivate, CanActivateChild {

    constructor(
        @Inject(MSAL_GUARD_CONFIG) msalGuardConfig: MsalGuardConfiguration,
        private extendedMsalBroadcastService: MsalBroadcastService,
        activatedRoute: ActivatedRoute,
        location: Location,
        platformLocation: PlatformLocation,
        private appRouter: Router,
        private appAuthService: MsalService,
        private externalUserService: ExternalUserService,
        @Inject('UserService') private userService: UserService,
    ) {
        super(msalGuardConfig, extendedMsalBroadcastService, appAuthService, location, appRouter);
    }

    override canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return super.canActivate(route, state);
    }

    override canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (this.externalUserService.isExternalUser()) {
            this.externalUserService.clearExternalUserSession();
        }

        if (this.externalUserService.hasShareLinkKey()) {
            this.externalUserService.clearShareLinkKey();
        }

        return this.extendedMsalBroadcastService.inProgress$.pipe(
            filter((status) => status === InteractionStatus.None),
            switchMap(() => this.userService.isCommunicationOfficer()),
            take(1),
            switchMap(isCommunicationOfficer => {
                if (!isCommunicationOfficer) {
                    return of(this.appRouter.createUrlTree(['missing-roles']));
                }

                return this.canActivate(childRoute, state);
            })
        )
    }
}
