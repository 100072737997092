import {Component, Inject} from '@angular/core';
import {User} from "../../models/user";
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ProjectService} from "../../services/project.service";
import {Project} from "../../models/project";
import {ToastrService} from "ngx-toastr";

interface EditProjectManagerModalData {
    user: User;
    selectedProjects: Project[];
}

@Component({
    selector: 'app-edit-project-manager-modal',
    templateUrl: './edit-project-manager-modal.component.html'
})
export class EditProjectManagerModalComponent {
    user: User;
    selectedProjects: Project[];
    loading = false;
    hasMultipleProjectResponsible = false;

    form = new FormGroup({
        user: new FormControl(null, [Validators.required])
    })

    constructor(
        private modal: VwuiModalRef,
        private modalConfig: VwuiModalConfig<EditProjectManagerModalData>,
        @Inject('ProjectService') private projectService: ProjectService,
        private toast: ToastrService
    ) {
        this.user = this.modalConfig.data.user;
        this.selectedProjects = this.modalConfig.data.selectedProjects;
        this.hasMultipleProjectResponsible = this.selectedProjects
            .map(project => project.projectResponsible)
            .filter(responsible => responsible.length > 1).length > 0
    }

    async save() {
        if (!this.form.valid) {
            return;
        }

        this.loading = true
        const errors = [];

        for (const project of this.selectedProjects) {
            project.projectResponsible = project.projectResponsible.filter(responsible => responsible.user.id !== this.user.id)
            if (!project.projectResponsible.map(responsible => responsible.user.id).includes(this.form.controls.user.value.id)) {
                project.projectResponsible.push(this.form.value)
            }
            try {
                await this.projectService.updateProject(project).toPromise()
            } catch (e) {
                errors.push(project.name)
            }
        }

        this.loading = false;

        if (errors.length > 0) {
            this.toast.error('Voor de volgende projecten kon de projectverantwoordelijke niet gewijzigd worden:' + errors.join(', '))
        } else {
            this.toast.success('Projectverantwoordelijke gewijzigd')
        }

        this.modal.close(true)
    }

    close() {
        this.modal.close();
    }
}
