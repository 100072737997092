import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Media} from '../models/media';
import {environment} from '../../../environments/environment';
import {PrepareUploadRequest} from '../models/prepare-upload-request';
import {PrepareDocumentUploadResponse} from '../models/prepare-document-upload-response';
import {Document} from '../models/document';
import {mergeMap} from 'rxjs/operators';
import {FileUtil} from '../shared/file';
import {BlobStorageService} from './blob-storage.service';
import {jsDocComment} from '@angular/compiler';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    constructor(
        private client: HttpClient,
        private blobStorageService: BlobStorageService,
    ) {
    }

    prepareUpload(request: PrepareUploadRequest) {
        return this.client.post<PrepareDocumentUploadResponse>(`${environment.apiPrefix}/documents/prepare-upload`, request);
    }

    finishUpload(document: Document) {
        return this.client.post<Document>(`${environment.apiPrefix}/documents/${document.id}/finish-upload`, null);
    }

    fetchDocument(document: Document) {
        return this.client.get<{ url: string }>(`${environment.apiPrefix}/fileStorage/downloadDocument`, {
            params: {documentId: document.id.toString()}
        });
    }

    linkDocumentWithMedia(media: Media, document: Document) {
        return this.client.post<void>(`${environment.apiPrefix}/media/${media.id}/documents`, document);
    }

    deleteDocument(media: Media, document: Document) {
        return this.client.delete<void>(`${environment.apiPrefix}/media/${media.id}/documents/${document.id}`);
    }

    async uploadFile(file: File): Promise<Document> {
        const prepareResponse = await this.prepareUpload({
            fileName: file.name,
            fileType: file.type,
            fileExtension: FileUtil.getFileExtension(file.name)
        }).toPromise();

        await this.blobStorageService.uploadToBlobStorage(
            prepareResponse.accountName,
            prepareResponse.containerName,
            prepareResponse.document.blobName,
            prepareResponse.sasToken,
            file
        ).toPromise();

        return await this.finishUpload(prepareResponse.document).toPromise();
    }

}
