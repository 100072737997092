import { Component} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { VwuiModalConfig, VwuiModalRef } from '@recognizebv/vwui-angular';
import {Media} from '../../models/media';
import {MediaService} from '../../services/media.service';

@Component({
    selector: 'app-delete-media',
    templateUrl: './delete-media.component.html'
})
export class DeleteMediaComponent {
    loading = false;

    constructor(
        public modal: VwuiModalRef,
        private modalConfig: VwuiModalConfig<{ media: Media }>,
        private mediaService: MediaService,
        private toast: ToastrService) {}

    confirm() {
        this.loading = true;
        this.mediaService.delete(this.modalConfig.data.media).subscribe(() => {
            this.loading = false;
            this.toast.success(`Media is verwijderd`);
            this.modal.close({message: 'SUCCESS'});
        }, err => {
            this.loading = false;
            console.error(err + 'Error occurred while deleting project');
            this.toast.error('Verwijderen mislukt');
        });
    }
}
