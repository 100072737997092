import {Injectable} from '@angular/core';
import LRU from 'lru-cache';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    private permissionCache = new LRU<string, Promise<boolean>>({
        max: 100,
        ttl: 2000,
    });

    constructor(
        private http: HttpClient
    ) {
    }

    hasProjectPermission(projectId: number, permission: string): Promise<boolean> {
        return this.getCachedOrFetch(
            'project_' + projectId + '_' + permission,
            () => this.http.get<boolean>(`${environment.apiPrefix}/projects/${projectId}/permissions/${permission}`).toPromise()
        )
    }

    hasAlbumPermission(albumId: number, permission: string): Promise<boolean> {
        return this.getCachedOrFetch(
            'album_' + albumId + '_' + permission,
            () => this.http.get<boolean>(`${environment.apiPrefix}/project-albums/${albumId}/permissions/${permission}`).toPromise()
        )
    }

    private getCachedOrFetch(key: string, fetch: () => Promise<boolean>): Promise<boolean> {
        if (!this.permissionCache.has(key)) {
            this.permissionCache.set(
                key,
                fetch().catch(error => {
                    this.permissionCache.delete(key);
                    throw error;
                })
            );
        }

        return this.permissionCache.get(key);
    }
}
