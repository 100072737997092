export function parseJwtPayload(jwtString: string) {
    if (jwtString) {
        const parts = jwtString.split('.');
        if (parts.length === 3) {
            return JSON.parse(atob(parts[1]));
        }
    }

    return null;
}
