import {Component} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from "rxjs";
import {map, shareReplay, startWith} from "rxjs/operators";
import {PublishedDesign} from "../../models/vista/published-design";
import {Media} from "../../models/media";
import {BsModalService} from "ngx-bootstrap/modal";
import {VistaCreateCategory, VistaCreateService, VistaCreateTemplate} from "../../services/vista-create.service";
import {MediaProcessingComponent} from "../media-processing/media-processing.component";



@Component({
    selector: 'app-vista-create-list-menu',
    templateUrl: './vista-create-list-menu.component.html'
})
export class VistaCreateListMenuComponent {

    vistaCreateCategories: VistaCreateCategory[] = this.vistaCreateService.MARKETING_CATEGORIES;

    searchControl = new FormControl();
    filteredCategories$: Observable<VistaCreateCategory[]>;
    showUploadProgress = false;
    uploadProgress: number;
    uploadCurrentFilename: string;
    uploadTotalBytes: number;

    constructor(
        private vistaCreateService: VistaCreateService,

        private bsModalService: BsModalService
    ) {
        this.filteredCategories$ = this.searchControl.valueChanges.pipe(
            startWith(''),
            map(searchValue => this.filterCategories(searchValue)),
            shareReplay(1)
        );
    }

    private filterCategories(searchValue: string): VistaCreateCategory[] {
        if (!searchValue) {
            return this.vistaCreateCategories;
        }
        return this.vistaCreateCategories.map(category => ({
            title: category.title,
            templates: category.templates.filter(template =>
                template.title.toLowerCase().includes(searchValue.toLowerCase())
            )
        })).filter(category => category.templates.length > 0);
    }

    async startVista(template: VistaCreateTemplate) {
        try {
            this.vistaCreateService.startVista(
                template,
                null,
                async (data: PublishedDesign) => {
                    this.uploadProgress = 0;
                    this.showUploadProgress = true;
                    const imageData = await fetch(data.url);
                    const blob = await imageData.blob();
                    this.uploadCurrentFilename = data.id + '.' + data.extension;
                    this.uploadTotalBytes = blob.size

                    const media = {
                        fileName: this.uploadCurrentFilename,
                        url: data.url,
                        fileType: data.extension
                    } as Media;
                    const newMedia = await this.vistaCreateService.uploadVistaResult(
                        media,
                        blob,
                        this.uploadCurrentFilename,
                        ([prev, cur]) => {
                            this.uploadProgress = (cur / this.uploadTotalBytes) * 100;
                        });
                    this.showUploadProgress = false;
                    this.openMediaProcessingModal(newMedia);
                },
                () => {
                },
                []
            )
        } catch (error) {
            console.error(error);
        } finally {
            this.showUploadProgress = false;
        }
    }


    openMediaProcessingModal(media: Media) {
        this.bsModalService.show(MediaProcessingComponent, {
            class: 'fullscreen-modal',
            initialState: {
                type: 'upload',
                mediaItems: [media]
            }
        });
    }
}
