<vwui-header>
    Project verzoeken
</vwui-header>

<ng-container *ngIf="(pendingRequests$|async) as projectsReq">
    <ng-container *ngIf="projectsReq.length > 0; else emptyProjectRequests">
        <vwui-grid class="card-grid-items" *ngIf="(pendingRequests$|async) as projectsReq">
            <vwui-card *ngFor="let pr of projectsReq">
                <vwui-card-body>
                    <div class="card-project">
                        <p class="card-project__subtitle"><strong>{{pr.user.name}}</strong> wil toegang tot het project
                        </p>
                        <div class="card-project__title">{{pr.project.name}}</div>
                        <div class="card-project__button-group">
                            <vwui-button
                                [loading]="loading"
                                [disabled]="loading"
                                block
                                icon="vwui-check"
                                class="is-danger"
                                (click)="updateProjectPermissionRequest(pr, false)">
                                Weigeren
                            </vwui-button>
                            <vwui-button
                                [loading]="loading"
                                [disabled]="loading"
                                block
                                icon="vwui-check"
                                class="is-primary"
                                (click)="updateProjectPermissionRequest(pr, true)">
                                Accepteren
                            </vwui-button>
                        </div>
                    </div>
                </vwui-card-body>
            </vwui-card>
        </vwui-grid>
    </ng-container>
</ng-container>

<ng-template #emptyProjectRequests>
    <p>Er zijn geen project verzoeken op dit moment</p>
</ng-template>
