import { Component} from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { ToastrService } from 'ngx-toastr';
import { VwuiModalConfig, VwuiModalRef } from '@recognizebv/vwui-angular';
import {Organization} from '../../models/organization';
import {take} from 'rxjs/operators';
import {Project} from '../../models/project';

@Component({
    selector: 'app-delete-project',
    templateUrl: './delete-project.component.html'
})
export class DeleteProjectComponent {
    loading = false;
    project$ = this.projectService.getProjectDetail(this.modalConfig.data.projectId)
        .pipe(
            take(1)
        );

    constructor(
        public modal: VwuiModalRef,
        private modalConfig: VwuiModalConfig<{ organization?: Organization, projectId?: number }>,
        private projectService: ProjectService,
        private toast: ToastrService) {}

    deleteProject(project: Project) {
        this.loading = true;
        this.projectService.deleteProject(project).subscribe(() => {
            this.loading = false;
            this.toast.success(`Project is verwijderd`);
            this.modal.close({message: 'SUCCESS'});
        }, err => {
            this.loading = false;
            console.error(err + 'Error occured while deleting project');
            this.toast.error('Verwijderen mislukt');
        });
    }
}
