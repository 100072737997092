<ng-select
    bindLabel="name"
    labelForId="tag"
    id="tag"
    addTagText="Toevoegen"
    typeToSearchText="Typ om te zoeken"
    notFoundText="Geen resultaten"
    placeholder="Selecteer tag(s)"
    [formControl]="formControl"
    clearAllText="Leegmaken"
    (search)="search.next($event.term)"
    [addTag]="(allowAddTag$ | async) ? validateTag : false"
    [multiple]="true"
    [hideSelected]="true"
    [items]="tags$ | async"
    [maxSelectedItems]="30"
    [clearOnBackspace]="false"
    minTermLength="2"
    (add)="onAddTag()"
    (scrollToEnd)="loadMoreSubject.next(null)"
>
</ng-select>
