import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

type ChangeFn = (value: string) => void;

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    providers: [
        {
            multi: true,
            useExisting: forwardRef(() => SearchInputComponent),
            provide: NG_VALUE_ACCESSOR,
        }
    ]
})
export class SearchInputComponent implements ControlValueAccessor {
    @Input() public placeholder = '';
    @Input() public name = '';

    public control: FormControl;
    public onChange: ChangeFn = () => null;
    public onTouched: ChangeFn = () => null;

    constructor(private fb: FormBuilder) {
        this.createControl();
    }

    public registerOnChange(fn: ChangeFn): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: ChangeFn): void {
        this.onTouched = fn;
    }

    public writeValue(value: string): void {
        this.control.patchValue(value);
    }

    private createControl() {
        this.control = this.fb.control(null);

        this.control.valueChanges.subscribe(value => {
            this.onTouched(value);
            this.onChange(value);
        });
    }
}
