<ng-select [compareWith]="compare"
           [items]="items | async"
           [hideSelected]="true"
           [loading]="loading"
           [typeahead]="onInput"
           (scrollToEnd)="scrollToEnd()"
           [formControl]="formControl"
           [multiple]="multiple"
           [placeholder]="placeholder"
           [clearable]="true"
           [bindLabel]="bindLabel"
           appendTo="body"
>
</ng-select>
