import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {EMPTY, Subject, Subscription} from 'rxjs';
import {filteredInfiniteScrollObservable} from '../utils/pagination';
import {Project} from '../../models/project';
import {OrganizationService} from '../../services/organization.service';
import {ProjectService} from '../../services/project.service';
import { environment } from 'src/environments/environment';

export interface ListFilter {
    parentId?: number;
    searchValue?: string;
}

@Component({
    selector: 'app-display-all-projects-org',
    templateUrl: './display-all-projects-org.component.html'
})
export class DisplayAllProjectsOrgComponent implements OnInit, OnDestroy {
    public environment = environment;

    listFilter$ = new Subject<ListFilter>();
    loadMoreProjects$ = new Subject();
    showLoadMoreProjects: boolean;

    projectList$ = filteredInfiniteScrollObservable<Project, ListFilter>(
        this.listFilter$,
        this.loadMoreProjects$,
        (page, filter) => {
            if (filter.parentId !== undefined) {
                return this.projectService.getList(page, filter.parentId);
            } else {
                return EMPTY;
            }
        },
        (pageResponse) => {
            this.showLoadMoreProjects = !pageResponse.last;
        }
    );

    orgId: number;
    subscriptions: Subscription[] = [];

    projectList: Project[] = [];
    pageTitle = 'Organisatie';

    constructor(
        private route: ActivatedRoute,
        private organizationService: OrganizationService,
        private projectService: ProjectService
    ) { }

    ngOnInit() {
        const orgId = this.route.snapshot.params.orgId;

        this.loadOrganisation(orgId);

        this.subscriptions.push(
            this.projectList$.subscribe(result => {
                this.projectList = result;
            })
        );

        this.listFilter$.next({
            parentId: orgId
        });

        this.loadMoreProjects$.next();
    }

    async loadOrganisation(id: number) {
        try {
            const result = await this.organizationService.getOne(id).toPromise();

            this.pageTitle = result.name;
        } catch (e) {
            console.error('loadOrganisation error', e);
        }
    }

    // Clear the subscription on destroy
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }


}
