import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Media} from '../../models/media';
import {scaleResolution} from '../utils/scale-resolution';

export interface ImageDownloadThumbnailGroup {
    label: string;
    formats: ImageDownloadThumbnail[];
}

export interface ImageDownloadThumbnail {
    label: string;
    width: number;
    height: number;
}

@Component({
    selector: 'app-image-download-media',
    templateUrl: './image-download-media.component.html'
})
export class ImageDownloadMediaComponent {
    @Output() download = new EventEmitter();
    @Output() downloadThumbnail = new EventEmitter<{groupLabel: string; thumbnail: ImageDownloadThumbnail}>();
    @Output() downloadScaled = new EventEmitter<{scale: number; media: Media}>();
    @Input() hasThumbnails = false;
    @Input() disabled = false;
    @Input() media: Media;

    thumbnails: ImageDownloadThumbnailGroup[] = [
        {
            label: 'Facebook',
            formats: [
                {label: 'Profielfoto', width: 180, height: 180},
                {label: 'Omslagfoto', width: 820, height: 312},
                {label: 'Gedeelde foto', width: 1200, height: 630},
                {label: 'Gedeelde link', width: 1200, height: 628},
                {label: 'Hoogtepunten', width: 1200, height: 717},
                {label: 'Evenement', width: 1920, height: 1080}
            ]
        }, {
            label: 'Twitter',
            formats: [
                {label: 'Profielfoto', width: 400, height: 400},
                {label: 'Header foto', width: 1500, height: 500},
                {label: 'Foto bij tweet', width: 440, height: 220},
            ]
        }, {
            label: 'Instagram',
            formats: [
                {label: 'Profielfoto', width: 110, height: 110},
                {label: 'Gedeelde foto', width: 1080, height: 1080},
                {label: 'Stories', width: 1080, height: 1920},
            ]
        }, {
            label: 'LinkedIn',
            formats: [
                {label: 'Profielfoto', width: 400, height: 400},
                {label: 'Profiel-achtergrondfoto', width: 1584, height: 396},
                {label: 'Bedrijfslogo', width: 300, height: 300},
                {label: 'Bedrijf omslagfoto', width: 1536, height: 768},
                {label: 'Bedrijf banner afbeelding', width: 646, height: 220},
                {label: 'Hero afbeelding', width: 1128, height: 376},
                {label: 'Gedeelde afbeelding', width: 1104, height: 738}
            ]
        },
    ];

    getScaledMediaFilesize(media: Media, scalePercentage: number) {
        const projectedSize = (media.fileSize * scalePercentage / 100) * 0.90; // estimated with 90% quality
        return Math.round(projectedSize);
    }

    getScaledMediaResolution(media: Media, scalePercentage: number) {
        const { width, height } = scaleResolution({width: media.width, height: media.height}, scalePercentage);
        return `${width}x${height}`;
    }

    triggerDownloadScaled(media: Media, scalePercentage: number) {
        this.downloadScaled.emit({
            media: media,
            scale: scalePercentage,
        });
    }

    triggerDownloadThumbnail(groupLabel: string, thumbnail: ImageDownloadThumbnail) {
        this.downloadThumbnail.emit({
            groupLabel,
            thumbnail
        });
    }
}
