<!--Progress Bar-->
<div class="col align-self-end">
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    <div *ngIf="showProgressBar">
        <div class="progressbar">
            <div class="progress-bar__top">
                <div class="progress-bar__file-name" *ngIf="fileName">
                    {{fileName | ellipsis : '34'}}
                </div>

                <vwui-icon *ngIf="showCancelButton" iconName="close" class="progress-bar__close" (click)="cancelProgressImage()"></vwui-icon>
            </div>

            <div class="progress-bar__bottom">
                <div class="progress-bar__info">
                    {{ totalSize | fileSize }} <ng-container *ngIf="eta !== null">• {{ eta | timeLeft }}</ng-container>
                </div>
                <div class="progress-bar__progress">
                    <div class="tile-progressbar">
                        <span [ngStyle]="{'width':uploadProgress +'%'}"></span>
                    </div>

                    <div class="progress-bar__progress__text">
                        {{ uploadProgress }}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
