import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ChangeFn} from '../file-type-filter/file-type-filter.component';

@Component({
    selector: 'app-orientation-filter',
    templateUrl: './orientation-filter.component.html',
    providers: [
        {
            multi: true,
            useExisting: forwardRef(() => OrientationFilterComponent),
            provide: NG_VALUE_ACCESSOR,
        }
    ]
})
export class OrientationFilterComponent implements ControlValueAccessor {
    orientations = [
        {
            resolution: 'portrait',
            label: 'Portret'
        },
        {
            resolution: 'landscape',
            label: 'Landschap'
        }
    ];

    control = new FormControl(null);
    public onChange: ChangeFn = () => null;
    public onTouched: ChangeFn = () => null;

    constructor() {
        this.control.valueChanges.subscribe(value => {
            this.onTouched(value);
            this.onChange(value);
        });
    }

    public registerOnChange(fn: ChangeFn): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: ChangeFn): void {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        this.control.patchValue(value);
    }

}
