import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {MsalService} from '@azure/msal-angular';
import {environment} from '../../../../environments/environment';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-missing-roles',
  templateUrl: './missing-roles.component.html'
})
export class MissingRolesComponent implements OnInit {
    public environment = environment;

    constructor(
        private router: Router,
        @Inject('UserService') private userService: UserService,
        private authService: MsalService,
    ) {
    }

    async ngOnInit() {
        if (await this.userService.isCommunicationOfficer().pipe(take(1)).toPromise()) {
            await this.router.navigate(['/']);
        }
    }

    logout() {
        this.authService.logout();
    }
}
