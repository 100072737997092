import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-drag-drop-overlay',
    templateUrl: './drag-drop-overlay.component.html',
})
export class DragDropOverlayComponent {
    @Input() allowUpload = true;

    @Output() public filesDropped = new EventEmitter<FileList>();
    public active = false;

    public dragDidStart($event) {
        $event.preventDefault();
        $event.stopPropagation();

        if (this.allowUpload) {
            this.active = true;
        }
    }

    public dragDidLeave($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.active = false;
    }

    public onDrop($event) {
        $event.preventDefault();

        const files = $event.dataTransfer.files;

        if (files.length > 0) {
            this.filesDropped.next(files);
        }

        this.active = false;
    }
}
