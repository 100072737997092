<ng-container>
    <vwui-modal-header>
        <div class="create-project__top">
            {{modalData.title}}
        </div>
    </vwui-modal-header>
    <vwui-modal-body>
        <p>{{modalData.message}}</p>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modal.close(false)">{{modalData.noButtonText ?? 'Nee'}}</vwui-button>
        <vwui-button class="is-secondary" (click)="modal.close(true)">{{modalData.yesButtonText ?? 'Ja'}}</vwui-button>
    </vwui-modal-footer>
</ng-container>
