import {Component} from '@angular/core';
import {ProjectAlbum} from '../../models/project-album';
import {MediaService} from '../../services/media.service';
import {BehaviorSubject, combineLatest, defer, merge, Subject} from 'rxjs';
import {Media} from '../../models/media';
import {filteredInfiniteScrollObservable} from '../utils/pagination';
import {ProjectAlbumService} from '../../services/project-album.service';
import {map, shareReplay, take} from 'rxjs/operators';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-project-album-select-media-modal',
    templateUrl: './project-album-select-media-modal.component.html'
})
export class ProjectAlbumSelectMediaModalComponent {
    saving = false;
    readonly album: ProjectAlbum;

    private albumMediaIdsOverride = new Subject<number[]>();
    readonly albumMediaIds$ = merge(
        defer(() => this.albumService.getMediaIds(this.album)),
        this.albumMediaIdsOverride
    ).pipe(shareReplay(1));

    private refresh$ = new BehaviorSubject<void>(null);
    readonly loadMore$ = new BehaviorSubject<void>(null);

    projectMediaDetails$ = filteredInfiniteScrollObservable<Media, void>(
        this.refresh$,
        this.loadMore$,
        page => this.mediaDetailsService.getList(page, 25, {
            project: this.album.project.id.toString()
        })
    );

    mediaDetails$ = combineLatest([this.projectMediaDetails$, this.albumMediaIds$]).pipe(
        map(([media, selectedMediaIds]) => {
            media.forEach(item => item.isSelected = selectedMediaIds.includes(item.id));
            return media;
        })
    );

    constructor(
        private albumService: ProjectAlbumService,
        private mediaDetailsService: MediaService,
        public modalRef: BsModalRef
    ) {
    }

    async onMediaItemSelected(mediaDetails: Media) {
        const currentIds = await this.albumMediaIds$.pipe(take(1)).toPromise();
        const idIndex = currentIds.indexOf(mediaDetails.id);
        if (idIndex !== -1) {
            mediaDetails.isSelected = false;
            currentIds.splice(idIndex, 1);
        } else {
            mediaDetails.isSelected = true;
            currentIds.push(mediaDetails.id);
        }
        this.albumMediaIdsOverride.next(currentIds);
    }

    async save(mediaIds: number[]) {
        if (this.saving) {
            return;
        }
        try {
            this.saving = true;
            await this.albumService.updateMedia(this.album, mediaIds).toPromise();
            this.modalRef.hide();
        } finally {
            this.saving = false;
        }
    }
}
