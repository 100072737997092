import {Injectable} from '@angular/core';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {Project, ProjectVisibility} from "../../models/project";
import {wrapInPageResponse} from "../../models/page-response";
import {of} from "rxjs";
import {mockUsers} from "./user.service.mock";

export const mockProjects: Array<Project> = [
    {
        id: 1,
        createdAt: new Date(2023 - 1 - 25),
        deleted: false,
        uploadApprovalRequired: false,
        downloadApprovalRequired: false,
        visibility: ProjectVisibility.Private,
        organizations: [
            {
                projectOrgId: 2,
                organization: {
                    id: 2,
                    name: 'VolkerWessels Telecom',
                    createdAt: new Date(2023 - 1 - 25),
                    projectCount: 1
                },
                createdAt: new Date(2023 - 1 - 25)
            }
        ],
        name: 'Project 1',
        projectResponsible: [
            {
                projectResponsibleId: 1,
                user: mockUsers[0],
                createdAt: new Date(2023 - 1 - 25)
            }
        ],
        description: '',
        timeStamp: new Date(2023 - 1 - 25),
        mediaCount: 0,
        updatedBy: 'g.oudekotte@recognize.nl',
        thumbnailUrl: '',
    },
    {
        id: 2,
        createdAt: new Date(2023 - 1 - 25),
        deleted: false,
        uploadApprovalRequired: false,
        downloadApprovalRequired: false,
        visibility: ProjectVisibility.Private,
        organizations: [
            {
                projectOrgId: 2,
                organization: {
                    id: 2,
                    name: 'VolkerWessels Telecom',
                    createdAt: new Date(2023 - 1 - 25),
                    projectCount: 1
                },
                createdAt: new Date(2023 - 1 - 25)
            }
        ],
        name: 'Project 2',
        projectResponsible: [
            {
                projectResponsibleId: 1,
                user: mockUsers[0],
                createdAt: new Date(2023 - 1 - 25)
            },
            {
                projectResponsibleId: 2,
                user: mockUsers[2],
                createdAt: new Date(2023 - 1 - 25)
            },
        ],
        description: '',
        timeStamp: new Date(2023 - 1 - 25),
        mediaCount: 0,
        updatedBy: 'g.oudekotte@recognize.nl',
        thumbnailUrl: '',
    }
];

@Injectable()
export class ProjectServiceMock extends AbstractCrudServiceMock<Project> {

    constructor() {
        super(mockProjects);
    }

    updateProject(project: Project) {
        return super.put(project.id, project)
    }

    getProjectDetail(projectId: string) {
        return super.detail(parseInt(projectId));
    }

    getProjectsByUser(userId: string, page: number) {
        return of(
            wrapInPageResponse(
                mockProjects.filter(
                    project => project.projectResponsible
                        .map(responsible => responsible.user.id.toString())
                        .includes(userId)
                )
            )
        )
    }


}
