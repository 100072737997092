import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ProjectAlbum} from '../models/project-album';
import {Injectable} from '@angular/core';
import {ProjectAlbumService} from '../services/project-album.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectAlbumResolver implements Resolve<ProjectAlbum>{
    constructor(private albumService: ProjectAlbumService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.albumService.getAlbum(route.paramMap.get('albumId'));
    }
}
