<div
    *ngIf="item.mediaItem as mediaItem"
    class="media-items-grid-item"
    [class.media-items-grid-item--selected]="mediaItem.isSelected"
    [class.media-items-grid-item--loaded]="imageLoaded"
    (click)="mediaItemSelect.emit(mediaItem)"
>
    <img class="media-items-grid-item__image" [lazyLoad]="mediaItem.url" [alt]="mediaItem.fileName" (onStateChange)="onImageLoad($event)">
    <div class="media-items-grid-item__selected">
        <vwui-checkbox [checked]="mediaItem.isSelected"></vwui-checkbox>
    </div>
    <div class="media-items-grid-item__info" *ngIf="showMediaTags || showMediaInfo">
        <app-media-item-tags [item]="mediaItem"></app-media-item-tags>
        <div class="media-items-grid-item__info-description" *ngIf="showMediaInfo">
            <div class="media-items-grid-item__info-title">{{mediaItem.project?.name}}</div>
            <div class="media-items-grid-item__info-subtitle">{{mediaItem.createdAt | date:'d-M-yyyy'}}</div>
        </div>
    </div>
    <div class="media-items-grid-item__file" *ngIf="mediaItem.documents.length > 0">
        <vwui-icon class="media-grid-item__file-icon" iconName="file"></vwui-icon>
    </div>
    <div class="media-items-grid-item__video" *ngIf="mediaItem.video">
        <img class="media-items-grid-item__video-icon" src="assets/icons/video.svg" alt="">
    </div>

    <div class="media-items-grid-item__skeleton"></div>
</div>

<div class="media-items-grid-item" *ngIf="item.mediaAddButton as button" (click)="addMediaBtnClick.emit()">
    <label>
        <div class="media-items-add-btn">
            <div class="icon-circle">
                <app-icon iconName="plus"></app-icon>
            </div>
            <div class="media-items-add-btn__title">{{button.title}}</div>
            <div class="media-items-add-btn__description" *ngIf="button.description">{{button.description}}</div>

            <input
                *ngIf="button.btnType === 'upload'"
                type="file"
                multiple="multiple"
                (click)="resetFileInput($event)"
                (change)="onFileChange($event)"
            >
        </div>
    </label>
</div>
