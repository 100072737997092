<vwui-header>
    {{ (project$ | async)?.name }}
    <div slot="breadcrumbs">
        <vwui-breadcrumb>Projecten</vwui-breadcrumb>
        <vwui-breadcrumb>{{ (project$ | async)?.name }}</vwui-breadcrumb>
    </div>

    <div slot="action" class="header-action-buttons">
        <vwui-button *appHasProjectResponsibleAccess class="is-action vwui-button--large-icon" icon="album-add"
                     (click)="openCreateAlbumModal()"></vwui-button>
        <vwui-button *appHasProjectResponsibleAccess class="is-action" icon="share"
                     (click)="openInviteExternalUserModal()"></vwui-button>
        <vwui-button *appHasProjectResponsibleAccess class="is-action" icon="vwui-edit"
                     (click)="editProject()"></vwui-button>
        <vwui-button *appHasProjectResponsibleAccess class="is-action" icon="vwui-trash"
                     (click)="deleteProject()"></vwui-button>
    </div>
</vwui-header>
<div class="media-grid-filter" [formGroup]="filterForm">
    <app-search-input name="mediaSearch" placeholder="Zoeken.." formControlName="search"></app-search-input>
    <app-file-type-filter name="mediaFilter" formControlName="fileTypes"></app-file-type-filter>
    <app-orientation-filter formControlName="orientation"></app-orientation-filter>
    <app-date-range-picker placeholder="Foto gemaakt op" formControlName="exifCreatedBeteen"></app-date-range-picker>
</div>

<div class="media-grid" *ngIf="project$|async as project">
    <div class="d-flex">
        <h4>Albums</h4>
        <button *ngIf="albumsExpanded || albumGrid.scrollHeight > albumGrid.clientHeight"
                (click)="albumsExpanded = !albumsExpanded" type="button" class="ml-auto media-grid-album__expand-button"
                [class.media-grid-album__expand-button--expanded]="albumsExpanded">
            Toon meer
            <vwui-icon [iconName]="'chevrons-down'"></vwui-icon>
        </button>
    </div>
    <div class="media-grid-album" [class.media-grid-album--expanded]="albumsExpanded" #albumGrid>
        <a class="media-grid-album__item" *ngFor="let album of (albums$ | async)?.content"
           [routerLink]="['albums', album.id]">
            <img class="media-grid-album__item__thumbnail" alt="thumbnail"
                 [src]="album.thumbnailUrl || '/assets/icons/image-landscape-square.svg'">
            <div class="media-grid-album__item__text">
                <div class="media-grid-album__item__title">{{ album.name }}</div>
                <div class="media-grid-album__item__subtitle">{{ album.mediaCount }} Foto{{ album.mediaCount === 1 ? '' : 's' }}</div>
            </div>
            <vwui-menu *ngIf="album.sharedUrl" icon="link" class="media-grid-album__item__menu">
                <vwui-menu-option (click)="copySharedLink(album.id)">Link kopiëren</vwui-menu-option>
                <vwui-menu-option (click)="removeSharedLink(album.id)">Link opheffen</vwui-menu-option>
            </vwui-menu>
        </a>
    </div>

    <app-project-media-grid [mediaDetails$]="mediaDetails$"
                            [allowAddMedia]="canUpload$ | async"
                            [refresh$]="refresh$"
                            (addMedia)="openMediaProcessingModal()"></app-project-media-grid>
</div>
