import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Media } from '../../models/media';
import {Project} from '../../models/project';
import {Router} from '@angular/router';
import {Organization} from '../../models/organization';

@Component({
    selector: 'app-search-result-group',
    templateUrl: './search-result-group.component.html'
})
export class SearchResultGroupComponent {
    @Input() results: Array<any>;
    @Input() type: 'projects' | 'organizations';
    @Input() hasMoreItems: boolean;
    @Input() showAddMedia: boolean;

    @Output() loadMore = new EventEmitter<void>();
    amountOfPlaceholders = 12;

    constructor(
        private router: Router
    ) {}

    get loadingPlaceholders() {
        return (new Array(this.amountOfPlaceholders)).fill(0);
    }

    onProjectClick(project: Project) {
        this.router.navigate(['/projects/', project.id]);
    }

    onOrganizationClick(organization: Organization) {
        this.router.navigate(['/organizations/', organization.id]);
    }
}
