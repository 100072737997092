import { User } from './user';
import {Organization} from './organization';

export enum ProjectVisibility {
    Public = 'Public',
    Private = 'Private'
}

export interface Project {
    createdAt: Date;
    deleted: boolean;
    uploadApprovalRequired: boolean;
    downloadApprovalRequired: boolean;
    visibility: ProjectVisibility;
    organizations: ProjectOrganization[];
    description: string;
    id: number;
    name: string;
    projectResponsible: ProjectResponsible[];
    timeStamp: Date;
    mediaCount: number;
    updatedBy: string;
    thumbnailUrl: string;
}

export interface ProjectResponsible {
    projectResponsibleId: number;
    user: User;
    createdAt: Date;
}

export interface ProjectOrganization {
    projectOrgId: number;
    organization: Organization;
    createdAt: Date;
}
