<div class="app-login-overlay">
    <img *ngIf="environment.showLoginShape" src="./assets/login-shape.svg" class="app-login-overlay__shape" alt="login-shape" />
    <div class="app-login-overlay__background"></div>
    <div class="app-login-overlay__container">
        <h1 class="app-login-overlay__title">{{ environment.appName }}</h1>
        <div class="app-login-overlay__description mt-2">
            {{ environment.missingRoles }}
        </div>
        <button
            class="beel-button app-login-overlay__button mt-5"
            (click)="logout()">Terug naar inlogscherm
        </button>
    </div>
    <div class="app-login-overlay__backdrop"></div>
</div>
