<vwui-header *ngIf="album$ | async as album">
    {{ album.name }}
    <div slot="breadcrumbs" *appHasProjectReadAccess>
        <vwui-breadcrumb>Projecten</vwui-breadcrumb>
        <vwui-breadcrumb><a routerLink="../..">{{ album.project.name }}</a>
        </vwui-breadcrumb>
        <vwui-breadcrumb>{{ album.name }}</vwui-breadcrumb>
    </div>

    <div slot="action" class="header-action-buttons">
        <vwui-button *appHasProjectResponsibleAccess class="is-action" icon="share"
                     (click)="openInviteExternalUserModal(album)"></vwui-button>
        <vwui-button *appHasProjectResponsibleAccess class="is-action" icon="vwui-edit"
                     (click)="openUpdateAlbumModal(album)"></vwui-button>
        <vwui-button *appHasProjectResponsibleAccess class="is-action" icon="vwui-trash"
                     (click)="openDeleteAlbumModal(album)"></vwui-button>
    </div>
</vwui-header>

<div class="media-grid-filter" [formGroup]="filterForm">
    <app-search-input name="mediaSearch" placeholder="Zoeken.." formControlName="search"></app-search-input>
    <app-file-type-filter name="mediaFilter" formControlName="fileTypes"></app-file-type-filter>
    <app-orientation-filter formControlName="orientation"></app-orientation-filter>
    <app-date-range-picker placeholder="Foto gemaakt op" formControlName="exifCreatedBeteen"></app-date-range-picker>
</div>

<div class="media-grid">
    <app-project-media-grid *ngIf="album$|async as album"
                            [allowAddMedia]="canUpload$ | async"
                            [album]="album"
                            addMediaButtonText="Media beheren"
                            addMediaDescriptionText="Klik hier om media te beheren"
                            [mediaDetails$]="mediaDetails$" (addMedia)="openMediaProcessingModal(album)">
    </app-project-media-grid>
</div>
