<app-action-button iconName="file" [loading]="loadingFile" [popover]="popover"></app-action-button>

<ng-template #popover>
    <ng-template [appHasProjectResponsibleAccess] [appHasProjectResponsibleAccessParams]="{projectId: media.project?.id}">
        <div class="px-3 py-2">
            <div class="image-download-document__label mx-0 my-2">Uploaden</div>
            <vwui-file-input [formControl]="addFileControl" [hidden]="documents.length >= 10"></vwui-file-input>
            <div *ngIf="documents.length >= 10">Er kunnen niet meer dan 10 documenten toegevoegd worden.</div>
        </div>
    </ng-template>
    <div class="px-2 py-2 pb-3" *ngIf="documents.length > 0">
        <div class="image-download-document__label my-1">Downloaden</div>
        <app-file-item
            *ngFor="let document of documents"
            [fileName]="document.originalName"
            [borderless]="true"
            [showRemoveButton]="canEdit|async"
            (removeClick)="removeDocument(document)"
            (click)="downloadFile(document)"
        ></app-file-item>
        <app-file-item
            *ngFor="let file of pendingFiles"
            [fileName]="file.name"
            [borderless]="true"
            [showSpinner]="true"
        ></app-file-item>
    </div>
</ng-template>
