<vwui-modal-header>
    <div class="create-project__top">
        <span *ngIf="projectDetail$ && projectDetail$ | async as projectDetail; else newProjectModalTitle">
            {{projectDetail.name}}
        </span>
    </div>
</vwui-modal-header>
<vwui-modal-body>
    <form [formGroup]="createProjectForm" (ngSubmit)="saveProject()">
        <vwui-form-group label="Projectnaam" inputId="name">
            <vwui-input [autofocus]="true" id="name" name="name" type="text" formControlName="name"></vwui-input>
        </vwui-form-group>
        <vwui-form-group label="Zichtbaarheid project">
            <div class="choice-group">
                <label class="choice-item radio">
                    <input type="radio" formControlName="visibility" value="Private">
                    <span class="choice-item__label">Mijn organisatie</span>
                </label>
                <label class="choice-item radio">
                    <input type="radio" formControlName="visibility" value="Public">
                    <span class="choice-item__label">Alle organisaties</span>
                </label>
            </div>
        </vwui-form-group>
        <vwui-form-group label="Media uploaden">
            <div class="choice-group">
                <label class="choice-item radio">
                    <input type="radio" formControlName="uploadApprovalRequired" [value]="false">
                    <span class="choice-item__label">Altijd toestaan</span>
                </label>
                <label class="choice-item radio">
                    <input type="radio" formControlName="uploadApprovalRequired" [value]="true">
                    <span class="choice-item__label">Goedkeuring vereist</span>
                </label>
            </div>
        </vwui-form-group>
        <vwui-form-group label="Media downloaden">
            <div class="choice-group">
                <label class="choice-item radio">
                    <input type="radio" formControlName="downloadApprovalRequired" [value]="false">
                    <span class="choice-item__label">Altijd toestaan</span>
                </label>
                <label class="choice-item radio">
                    <input type="radio" formControlName="downloadApprovalRequired" [value]="true">
                    <span class="choice-item__label">Goedkeuring vereist</span>
                </label>
            </div>
        </vwui-form-group>
        <vwui-form-group label="Organisatie" inputId="org">
            <ng-select
                [items]="orgList$|async"
                [multiple]="true"
                [searchable]="true"
                [hideSelected]="true"
                appendTo="body"
                bindLabel="name"
                labelForId="org"
                id="org"
                addTagText="Toevoegen"
                typeToSearchText="Typ om te zoeken"
                notFoundText="Geen resultaten"
                placeholder="Kies een organisatie"
                formControlName="organizations"
                clearAllText="Leegmaken"
            >
            </ng-select>
        </vwui-form-group>
        <vwui-form-group label="Projectverantwoordelijke(n)" inputId="projectresponsible">
            <app-user-select [multiple]="true" bindLabel="name" id="projectresponsible"
                formControlName="projectResponsible">
            </app-user-select>
        </vwui-form-group>
    </form>
</vwui-modal-body>
<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modal.close()">Annuleren</vwui-button>
    <vwui-button class="is-primary" (click)="saveProject()" [loading]="loading">
        <ng-container *ngIf="projectDetail$; else newProjectButtonLabel">Opslaan</ng-container>
    </vwui-button>
</vwui-modal-footer>


<ng-template #newProjectModalTitle>Project toevoegen</ng-template>
<ng-template #newProjectButtonLabel>Toevoegen</ng-template>
