import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {map, switchMap} from 'rxjs/operators';
import {of, Subscription, timer} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {Association} from "../../models/association";
import {AssociationService} from "../../services/association.service";

@Component({
    selector: 'app-association-details',
    templateUrl: './association-details.component.html'
})
export class AssociationDetailsPageComponent implements OnInit, OnDestroy {
    public environment = environment;
    private subscriptions: Subscription[] = [];

    form: FormGroup = new FormGroup({
        id: new FormControl(null),
        name: new FormControl(
            null,
            [Validators.required, Validators.minLength(2), Validators.maxLength(255)],
            [this.validateNameNotTaken.bind(this)]
        ),
        parent: new FormControl(null),
    });

    loading: boolean;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private associationService: AssociationService,
        private route: ActivatedRoute,
        private toast: ToastrService
    ) {
    }

    ngOnInit() {
        this.subscriptions.push(
            this.route.params.pipe(
                switchMap(params => {
                    if (params.id === 'new') {
                        return of(null);
                    } else {
                        return this.associationService.getOne(+params.id);
                    }
                }),
            ).subscribe(association => {
                this.form.patchValue(association, {emitEvent: false});
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    async saveAssociation() {
        if (!this.form.valid) {
            return;
        }
        try {
            this.loading = true;

            let association: Association;
            if (this.form.value.id) {
                association = await this.associationService.update(this.form.value).toPromise();
            } else {
                association = await this.associationService.create(this.form.value).toPromise();
            }
            this.toast.success('Opgeslagen');

            this.router.navigate(['/manage/associations']);
        } catch (e) {
            this.toast.error('Opslaan mislukt');
        } finally {
            this.loading = false;
        }
    }

    private validateNameNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        return timer(300).pipe(
            switchMap(() => {
                if (!control.value) {
                    return of(null);
                }

                return this.associationService.exists(control.value, this.form.get('id').value);
            }),
            map(exists => exists ? {exists: true} : null)
        );
    }
}
