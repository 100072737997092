import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-image-modal-info-item',
    templateUrl: './image-modal-info-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageModalInfoItemComponent {
    @Input() label: string;
}
