import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {Media} from '../../models/media';
import Cropper from 'cropperjs';
import {MediaService} from '../../services/media.service';
import {ToastrService} from 'ngx-toastr';
import {FileUtil} from '../../shared/file';

interface ImageEditorData {
    image: Media;
    download?: {
        width: number;
        height: number;
        groupLabel: string;
        label: string
    };
}

@Component({
    selector: 'app-image-editor',
    templateUrl: './image-editor.component.html',
})
export class ImageEditorComponent implements AfterViewInit {
    @ViewChild('imageCanvas') private imageCanvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('size', {static: true}) private size: ElementRef<HTMLDivElement>;
    @ViewChild('content', {static: true}) private content: ElementRef<HTMLDivElement>;
    public image: Media;
    public scaledWidth: number;
    public scaledHeight: number;
    public scaleRatio: number;
    public translateCenterX = 0;
    public translateCenterY = 0;
    public translateX = 0;
    public translateY = 0;
    public currentWidth = -1;
    public currentHeight = -1;
    public ready = false;
    public loading = false;

    mode: 'edit' | 'download' = 'edit';
    downloadWidth: number;
    downloadHeight: number;
    downloadLabel: string;
    cropper: Cropper;

    imageEditorTitle: string;

    constructor(
        public modalRef: VwuiModalRef,
        modalConfig: VwuiModalConfig<ImageEditorData>,
        private mediaService: MediaService,
        private toastrService: ToastrService
    ) {
        const {download, image} = modalConfig.data;

        this.image = image;
        this.imageEditorTitle = download ? `${download.groupLabel} - ${download.label}` : 'Afbeelding kopieren';

        if (download) {
            this.mode = 'download';
            this.downloadWidth = download.width;
            this.downloadHeight = download.height;
            this.downloadLabel = `${download.groupLabel}-${download.label}`;
        }
    }

    public ngAfterViewInit(): void {
        setTimeout(() => this.determineScaledSize(), 300);
    }

    getSizeLabelTransform() {
        return `translate(${Math.round(this.translateCenterX)}px, ${Math.round(this.translateCenterY)}px)`;
    }

    async download() {
        if (!this.ready) {
            return;
        }

        const response = await this.mediaService.fetchCroppedThumbnail(
            this.image,
            this.translateX,
            this.translateY,
            this.currentWidth,
            this.currentHeight,
            this.downloadWidth / this.currentWidth
        ).toPromise();
        FileUtil.downloadBlobAsFile(
            response.body,
            FileUtil.getContentDispositionFilename(response.headers.get('Content-Disposition'))
        );

        this.modalRef.close();
    }

    public async save() {
        if (!this.ready) {
            return;
        }

        try {
            this.loading = true;

            await this.mediaService.createCroppedCopy(
                this.image,
                this.translateX,
                this.translateY,
                this.currentWidth,
                this.currentHeight,
                1
            ).toPromise();
            this.toastrService.success('Kopie opgeslagen');

            this.modalRef.close('copied');
        } catch (error) {
            console.warn(error);
        } finally {
            this.loading = false;
        }
    }

    private determineScaledSize() {
        const spaceWidth = this.size.nativeElement.clientWidth;
        const spaceHeight = this.size.nativeElement.clientHeight;
        const originalWidth = this.image.width;
        const originalHeight = this.image.height;

        const scale = Math.min(spaceWidth / originalWidth, spaceHeight / originalHeight);
        const targetWidth = originalWidth * scale;
        const targetHeight = originalHeight * scale;

        this.scaledWidth = Math.ceil(targetWidth);
        this.scaledHeight = Math.ceil(targetHeight);

        this.scaleRatio = this.image.width / targetWidth;

        setTimeout(() => this.start(), 100);
    }

    private async start() {
        const imageUrl = await this.mediaService.loadScaledImage(
            this.image,
            Math.floor(this.scaledWidth),
            Math.floor(this.scaledHeight),
        );

        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            const context = this.imageCanvas.nativeElement.getContext('2d');
            context.drawImage(
                image,
                0, 0, image.naturalWidth, image.naturalHeight,
                0, 0, this.scaledWidth, this.scaledHeight
            );

            this.startCropper();

            this.ready = true;
        };
    }

    private startCropper() {
        let aspectRatio = null;

        if (this.mode === 'download') {
            aspectRatio = this.downloadWidth / this.downloadHeight;
        }

        this.cropper = new Cropper(this.imageCanvas.nativeElement, {
            crop: (event) => {
                this.translateCenterX = event.detail.x + event.detail.width / 2;
                this.translateCenterY = event.detail.y + event.detail.height / 2;
                this.translateX = Math.max(0, Math.round(this.scaleRatio * event.detail.x));
                this.translateY = Math.max(0, Math.round(this.scaleRatio * event.detail.y));

                const actualWidth = Math.ceil(this.scaleRatio * event.detail.width);
                const actualHeight = Math.ceil(this.scaleRatio * event.detail.height);
                const maxWidth = this.image.width - this.translateX;
                const maxHeight = this.image.height - this.translateY;

                this.currentWidth = Math.min(maxWidth, actualWidth);
                this.currentHeight = Math.min(maxHeight, actualHeight);
            },
            zoomable: false,
            movable: false,
            aspectRatio
        });
    }
}
