import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../models/user';
import {PageResponse} from '../models/page-response';
import {environment} from '../../../environments/environment';
import {map, shareReplay, take} from 'rxjs/operators';

export interface UserService {

    createUser(user: User): Observable<User>;

    getList(page: number, query?: string): Observable<PageResponse<User>>;

    getUser(userId: number): Observable<User>;

    updateUser(user: User): Observable<User>;

    isCommunicationOfficer(): Observable<boolean>;

    getCurrentUser(): Observable<User>;

    getEnabledRoles(): Observable<string[]>;
}

@Injectable()
export class UserServiceImpl implements UserService {
    private cachedRoles$ = this.getCurrentUser().pipe(
        map(it => it.roles),
        shareReplay({
            refCount: false,
            bufferSize: 1
        })
    );

    constructor(private http: HttpClient) {
        this.http = http;
    }

    isCommunicationOfficer(): Observable<boolean> {
        return this.cachedRoles$.pipe(map(result => {
            return result.includes('ROLE_COMMUNICATION_OFFICER')
        }));
    }

    getList(page: number, query?: string) {
        const params = {
            page: page,
            query: query || ''
        };
        return this.http.get<PageResponse<User>>(`${environment.apiPrefix}/users`, {params});
    }

    getCurrentUser() {
        return this.http.get<User>(`${environment.apiPrefix}/users/me`);
    }

    getUser(userId: number) {
        return this.http.get<User>(`${environment.apiPrefix}/users/${userId}`);
    }

    updateUser(user: User) {
        return this.http.put<User>(`${environment.apiPrefix}/users/${user.id}`, user);
    }

    createUser(user: User) {
        return this.http.post<User>(`${environment.apiPrefix}/users`, user);
    }

    getEnabledRoles(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.apiPrefix}/users/roles`);
    }
}
