<div class="beel-search__filter">
    <ng-select [items]="fileTypes" bindLabel="fileValue" labelForId="fileType" id="fileType" [multiple]="true"
               bindValue="fileType" typeToSearchText="Typ om te zoeken" notFoundText="Geen resultaten"
               placeholder="Kies bestandstype(s)" [formControl]="filterControl" [searchable]="true"
               clearAllText="Leegmaken">
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class='file-type-indicator' [ngStyle]="{'background': fileTypeColor(item.fileValue)}">
                {{fileExtension(item.fileValue)}}
            </span>
            <span class="ng-value-icon clear right" (click)="clear(item)" aria-hidden="true">×</span>
        </ng-template>
    </ng-select>
</div>
