<vwui-modal-header>
    <div class="create-project__top">
        <span>{{ form.value.id ? form.value.name : 'Album toevoegen' }}</span>
    </div>
</vwui-modal-header>
<vwui-modal-body>
    <form [formGroup]="form" (ngSubmit)="save()">
        <vwui-form-group label="Albumnaam" inputId="name">
            <vwui-input [autofocus]="true" id="name" name="name" type="text" formControlName="name"></vwui-input>
        </vwui-form-group>
        <vwui-form-group label="Zichtbaarheid album">
            <div class="choice-group">
                <label class="choice-item radio">
                    <input type="radio" formControlName="visibility" value="Private">
                    <span class="choice-item__label">Mijn organisatie</span>
                </label>
                <label class="choice-item radio">
                    <input type="radio" formControlName="visibility" value="Public">
                    <span class="choice-item__label">Alle organisaties</span>
                </label>
            </div>
        </vwui-form-group>
        <vwui-form-group label="Media downloaden">
            <div class="choice-group">
                <label class="choice-item radio">
                    <input type="radio" formControlName="downloadApprovalRequired" [value]="false">
                    <span class="choice-item__label">Altijd toestaan</span>
                </label>
                <label class="choice-item radio">
                    <input type="radio" formControlName="downloadApprovalRequired" [value]="true">
                    <span class="choice-item__label">Goedkeuring vereist</span>
                </label>
            </div>
        </vwui-form-group>
    </form>
</vwui-modal-body>
<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modal.close()">Annuleren</vwui-button>
    <vwui-button class="is-primary" (click)="save()">
        {{ form.value.id ? 'Opslaan' : 'Album toevoegen' }}
    </vwui-button>
</vwui-modal-footer>
