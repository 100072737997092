import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {fileTypeColors} from '../../models/file-type';

@Component({
    selector: 'app-file-item',
    templateUrl: './file-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileItemComponent  {
    @Input() fileName = '';
    @Input() borderless = false;
    @Input() showRemoveButton = false;
    @Input() showSpinner = false;
    @Output() removeClick = new EventEmitter<void>();

    fileTypeColor() {
        return fileTypeColors[this.fileExtension()] ?? fileTypeColors.default;
    }

    fileExtension() {
        if (this.fileName.includes('.')) {
            return this.fileName.slice(this.fileName.lastIndexOf('.') + 1).toLowerCase();
        } else {
            return '';
        }
    }
}
