export class FileType {
    fileType: string;
    fileValue: string;
}

export const fileTypeColors = {
    doc: '#0097d9',
    docx: '#0097d9',
    mp3: '#b576b9',
    wav: '#b576b9',
    flac: '#b576b9',
    pdf: '#fb3a30',
    jpg: '#00bb96',
    jpeg: '#00bb96',
    svg: '#f6c300',
    css: '#f47700',
    xls: '#00bb96',
    xlsx: '#00bb96',
    mov: '#609ec9',
    avi: '#609ec9',
    mp4: '#609ec9',
    mkv: '#609ec9',
    png: '#9843ca',
    webm: '#9843ca',
    pptx: '#ca4426',
    default: '#0097d9'
};
