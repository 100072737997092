export interface PageResponse<T> {
    content: T[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export function createEmptyPageResponse<T>(): PageResponse<T> {
    return {
        content: [],
        empty: true,
        first: true,
        last: true,
        number: 0,
        numberOfElements: 25,
        size: 0,
        totalElements: 0,
        totalPages: 1
    };
}

export function wrapInPageResponse<T>(content: T[]): PageResponse<T> {
    return {
        content,
        empty: false,
        first: true,
        last: true,
        number: 1, // eslint-disable-line id-blacklist
        numberOfElements: content.length,
        size: content.length,
        totalElements: content.length,
        totalPages: 1
    };
}
