import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {finalize, map} from 'rxjs/operators';
import {filteredInfiniteScrollObservable} from './pagination';

export class InfiniteScrollPaginator<T, F> {
    private loadMoreSubject = new BehaviorSubject<void>(null);
    private refreshSubject = new BehaviorSubject<void>(null);
    private requestInProgressCount$ = new BehaviorSubject(0);
    private canLoadMore = true;

    readonly loading$ = this.requestInProgressCount$.pipe(map(it => it > 0));
    readonly content$: Observable<T[]> = filteredInfiniteScrollObservable<T, F>(
        combineLatest([this.filter$, this.refreshSubject]).pipe(map(([filter]) => filter)),
        this.loadMoreSubject,
        (page, filter) => {
            this.requestInProgressCount$.next(this.requestInProgressCount$.value + 1);
            return this.loadFunction(page, filter).pipe(
                finalize(() => this.requestInProgressCount$.next(
                    Math.max(0, this.requestInProgressCount$.value - 1)
                ))
            );
        },
        (pageResponse) => {
            this.canLoadMore = !pageResponse.last;
        }
    );
    readonly isEmpty$ = this.content$.pipe(map(it => it.length === 0));

    constructor(
        private filter$: Observable<F>,
        private loadFunction: (page: number, filterParam: F) => Observable<PageResponse<T>>
    ) {
    }

    refresh() {
        this.refreshSubject.next();
    }

    loadMore() {
        this.loadMoreSubject.next();
    }

    hasMore() {
        return this.canLoadMore;
    }
}
