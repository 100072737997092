import {BrowserModule, Title} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {VwuiAngularModule, VwuiModalConfig} from '@recognizebv/vwui-angular';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DragDropDirective} from './core/directives/drag-drop.directive';
import {EllipsisPipe} from './core/shared/ellipsis.pipe';
import {NgxUiLoaderConfig, NgxUiLoaderModule} from 'ngx-ui-loader';
import {IconComponent} from './core/components/icon/icon.component';
import {CreateProjectComponent} from './core/components/create-project/create-project.component';
import {DeleteProjectComponent} from './core/components/delete-project/delete-project.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastNoAnimationModule, ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModalHeaderComponent} from './core/components/modal-header/modal-header.component';
import {ProgressBarComponent} from './core/components/progress-bar/progress-bar.component';
import {UserInfoComponent} from './core/components/user-info/user-info.component';
import {PlaceholderLabelComponent} from './core/components/placeholder-label/placeholder-label.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {EmptyMediaCardComponent} from './core/components/empty-media-card/empty-media-card.component';
import {DragDropOverlayComponent} from './core/components/drag-drop-overlay/drag-drop-overlay.component';
import {TimeLeftPipe} from './core/shared/time-left.pipe';
import {InfiniteScrollComponent} from './core/components/infinite-scroll/infinite-scroll.component';
import {ActionButtonComponent} from './core/components/action-button/action-button.component';
import {MsalInterceptor, MsalModule, MsalRedirectComponent} from '@azure/msal-angular';
import {BrowserCacheLocation, InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {AdLoginComponent} from './core/components/ad-login/ad-login.component';
import {environment} from '../environments/environment';
import {
    MediaGridProjectItemComponent
} from './core/components/media-grid-project-item/media-grid-project-item.component';
import {HasRoleDirective} from './core/directives/has-role-directive';
import {BeeldbankMsalGuard} from './core/guards/beeldbank-msal.guard';
import {UsersListComponent} from './core/pages/users-list/users-list.component';
import {UsersDetailsComponent} from './core/pages/users-details/users-details.component';
import {InviteExternalUserComponent} from './core/components/invite-external-user/invite-external-user.component';
import {ExternalUserGuard} from './core/guards/beeldbank-external-guard';
import {LayoutComponent} from './core/components/layout/layout.component';
import {SearchInputComponent} from './core/components/search-input/search-input.component';
import {ImageModalComponent} from './core/components/image-modal/image-modal.component';
import {UserSelectComponent} from './core/components/item-select/user-select.component';
import {FileTypeFilterComponent} from './core/components/file-type-filter/file-type-filter.component';
import {MediaGridOrgItemComponent} from './core/components/media-grid-org-item/media-grid-org-item.component';
import {SearchResultGroupComponent} from './core/components/search-result-group/search-result-group.component';
import {ProjectMediaGridComponent} from './core/components/project-media-grid/project-media-grid.component';
import {HasProjectResponsibleAccessDirective} from './core/directives/has-project-responsible-access.directive';
import {ProjectRequestComponent} from './core/components/project-request/project-request.component';
import {
    DisplayAllProjectsOrgComponent
} from './core/components/display-all-projects-org/display-all-projects-org.component';
import {ProjectListMenuComponent} from './core/components/project-list-menu/project-list-menu.component';
import {ProjectListItemComponent} from './core/components/project-list-item/project-list-item.component';
import {ProjectRequestButtonComponent} from './core/components/project-request-button/project-request-button.component';
import {MediaProcessingComponent} from './core/components/media-processing/media-processing.component';
import {MediaUploadButtonComponent} from './core/components/media-upload-button/media-upload-button.component';
import {
    MediaProcessingContentComponent
} from './core/components/media-processing-content/media-processing-content.component';
import {
    MediaProcessingSidebarComponent
} from './core/components/media-processing-sidebar/media-processing-sidebar.component';
import {
    MediaProcessingToolbarComponent
} from './core/components/media-processing-toolbar/media-processing-toolbar.component';
import {ExternalUserPageComponent} from './core/components/external-user-page/external-user-page.component';
import {TagsFormControlComponent} from './core/components/tags-form-control/tags-form-control.component';
import {ImageDownloadMediaComponent} from './core/components/image-download-media/image-download-media.component';
import {ImageDownloadRequestComponent} from './core/components/image-download-request/image-download-request.component';
import {ImageEditorComponent} from './core/components/image-editor/image-editor.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {DeleteMediaComponent} from './core/components/delete-media/delete-media.component';
import {MissingRolesComponent} from './core/components/missing-roles/missing-roles.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ExternalLayoutComponent} from './core/components/external-layout/external-layout.component';
import {MsalComponent} from './core/components/msal/msal.component';
import {ProjectAlbumEditComponent} from './core/components/project-album-edit/project-album-edit.component';
import {ProjectAlbumDetailComponent} from './core/components/project-album-detail/project-album-detail.component';
import {ProjectAlbumDeleteComponent} from './core/components/project-album-delete/project-album-delete.component';
import {OrientationFilterComponent} from './core/components/orientation-filter/orientation-filter.component';
import {
    ProjectAlbumSelectMediaModalComponent
} from './core/components/project-album-select-media-modal/project-album-select-media-modal.component';
import {ProjectDetailComponent} from './core/pages/project-detail/project-detail.component';
import {MediaItemsGridComponent} from './core/components/media-items-grid/media-items-grid.component';
import {MediaItemTagsComponent} from './core/components/media-item-tags/media-item-tags.component';
import {MediaItemComponent} from './core/components/media-item/media-item.component';
import {MediaInfoTagsComponent} from './core/components/media-info-tags/media-info-tags.component';
import {FileSizePipe} from './core/pipes/file-size.pipe';
import {ImageModalInfoItemComponent} from './core/components/image-modal-info-item/image-modal-info-item.component';
import {UserCreateModalComponent} from './core/components/user-create-modal/user-create-modal.component';
import {PublicDataPageComponent} from './core/pages/public-data-page/public-data-page.component';
import {HasProjectResponseReadAccessDirective} from './core/directives/has-project-read-access.directive';
import {MyProjectsPageComponent} from './core/pages/my-projects-page/my-projects-page.component';
import {
    ImageModalThumbnailCarouselComponent
} from './core/components/image-modal-thumbnail-carousel/image-modal-thumbnail-carousel.component';
import {TagCreateModalComponent} from './core/components/tag-create-modal/tag-create-modal.component';
import {TagsListComponent} from './core/pages/tags-list/tags-list.component';
import {TagsDetailsComponent} from './core/pages/tag-details/tags-details.component';
import {TagServiceMock} from './core/services/mock/tag.service.mock';
import {TagServiceImpl} from './core/services/tag.service';
import {UserServiceMock} from './core/services/mock/user.service.mock';
import {UserService, UserServiceImpl} from './core/services/user.service';
import {FileItemComponent} from './core/components/file-item/file-item.component';
import {
    ImageDownloadDocumentsComponent
} from './core/components/image-download-documents/image-download-documents.component';
import {LogLevel} from 'msal';
import {TokenInterceptor} from './core/components/http-interceptors/token.interceptor';
import {OrganizationListPageComponent} from './core/pages/organization-list/organization-list.component';
import {OrganizationDetailsPageComponent} from './core/pages/organization-details/organization-details.component';
import {MediaGridComponent} from './core/components/media-grid/media-grid.component';
import {OrganizationSelectComponent} from './core/components/item-select/organization-select.component';
import {ConfirmationModalComponent} from './core/components/confirmation-modal/confirmation-modal.component';
import {AssociationListPageComponent} from "./core/pages/association-list/association-list-page.component";
import {AssociationDetailsPageComponent} from "./core/pages/association-details/association-details.component";
import {AssociationSelectComponent} from "./core/components/item-select/association-select.component";
import {HasFeatureToggleDirective} from "./core/directives/has-feature-toggle.directive";
import {EditProjectManagerModalComponent} from './core/components/edit-project-manager-modal/edit-project-manager-modal.component';
import {ProjectService} from "./core/services/project.service";
import {ProjectServiceMock} from "./core/services/mock/project.service.mock";
import {DateRangePickerComponent} from './core/components/date-range-picker/date-range-picker.component';
import {FilterHiddenRolesPipe} from './core/pipes/filter-hidden-roles.pipe';
import {VistaCreateListMenuComponent} from "./core/components/vista-create-list-menu/vista-create-list-menu.component";
import { VistaCreateTemplateSelectComponent } from './core/components/vista-create-template-select/vista-create-template-select.component';
import {
    ProjectEmbedAlbumFileComponent
} from "./core/pages/embed/project-embed-album-file/project-embed-album-file.component";
import {EmbedLayoutComponent} from "./core/components/embed-layout/embed-layout.component";

export const applicationAuthScopes = [environment.applicationScope ?? `api://${environment.azureClientId}/Read`];
const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;


export function serviceWithMock(provide: string, serviceRef: any, mock: any): any {
    return {
        useClass: environment.useMock ? mock : serviceRef,
        provide
    };
}

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.debug(message);
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: '#0c4154',
    fgsColor: '#0c4154',
    pbColor: '#0c4154',
    text: 'Wacht a.u.b. terwijl uw upload wordt geannuleerd...',
    textColor: '#FFFFFF',
};

@NgModule({
    declarations: [
        ActionButtonComponent,
        AdLoginComponent,
        AppComponent,
        CreateProjectComponent,
        DeleteMediaComponent,
        DeleteProjectComponent,
        DisplayAllProjectsOrgComponent,
        DragDropDirective,
        DragDropOverlayComponent,
        EllipsisPipe,
        EmptyMediaCardComponent,
        ExternalLayoutComponent,
        ExternalUserPageComponent,
        FileItemComponent,
        FileSizePipe,
        FileTypeFilterComponent,
        HasProjectResponseReadAccessDirective,
        HasProjectResponsibleAccessDirective,
        HasRoleDirective,
        IconComponent,
        IconComponent,
        ImageDownloadDocumentsComponent,
        ImageDownloadMediaComponent,
        ImageDownloadRequestComponent,
        ImageEditorComponent,
        ImageModalComponent,
        ImageModalInfoItemComponent,
        ImageModalThumbnailCarouselComponent,
        InfiniteScrollComponent,
        InviteExternalUserComponent,
        LayoutComponent,
        MediaGridOrgItemComponent,
        MediaGridProjectItemComponent,
        MediaInfoTagsComponent,
        MediaItemComponent,
        MediaItemTagsComponent,
        MediaItemsGridComponent,
        MediaProcessingComponent,
        MediaProcessingContentComponent,
        MediaProcessingSidebarComponent,
        MediaProcessingToolbarComponent,
        MediaUploadButtonComponent,
        MissingRolesComponent,
        ModalHeaderComponent,
        ModalHeaderComponent,
        MsalComponent,
        MyProjectsPageComponent,
        OrganizationListPageComponent,
        OrientationFilterComponent,
        PlaceholderLabelComponent,
        ProgressBarComponent,
        ProgressBarComponent,
        ProjectAlbumDeleteComponent,
        ProjectAlbumDetailComponent,
        ProjectAlbumEditComponent,
        ProjectAlbumSelectMediaModalComponent,
        ProjectDetailComponent,
        ProjectListItemComponent,
        ProjectListMenuComponent,
        VistaCreateListMenuComponent,
        ProjectMediaGridComponent,
        ProjectRequestButtonComponent,
        ProjectRequestComponent,
        PublicDataPageComponent,
        SearchInputComponent,
        SearchResultGroupComponent,
        TagCreateModalComponent,
        TagsDetailsComponent,
        TagsFormControlComponent,
        TagsListComponent,
        TimeLeftPipe,
        UserCreateModalComponent,
        UserInfoComponent,
        UserSelectComponent,
        UsersDetailsComponent,
        UsersListComponent,
        MediaGridComponent,
        OrganizationDetailsPageComponent,
        OrganizationSelectComponent,
        ConfirmationModalComponent,
        ConfirmationModalComponent,
        AssociationListPageComponent,
        AssociationDetailsPageComponent,
        AssociationSelectComponent,
        HasFeatureToggleDirective,
        EditProjectManagerModalComponent,
        DateRangePickerComponent,
        FilterHiddenRolesPipe,
        VistaCreateTemplateSelectComponent,
        EmbedLayoutComponent,
        ProjectEmbedAlbumFileComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot({
            extendedTimeOut: 5000
        }), // ToastrModule added
        ToastNoAnimationModule.forRoot({
            extendedTimeOut: 5000
        }),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        ModalModule.forRoot(),
        VwuiAngularModule.forRoot(),
        CarouselModule,
        LazyLoadImageModule,
        PopoverModule.forRoot(),
        InfiniteScrollModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    authority: 'https://login.microsoftonline.com/common',
                    clientId: environment.azureClientId,
                    redirectUri: environment.azureRedirectUri,
                    postLogoutRedirectUri: environment.azureLogoutUri,
                    navigateToLoginRequestUrl: false
                },
                cache: {
                    storeAuthStateInCookie: isIE,
                    cacheLocation: BrowserCacheLocation.LocalStorage
                },
                system: {
                    loggerOptions: {
                        loggerCallback,
                        logLevel: LogLevel.Info,
                        piiLoggingEnabled: false
                    }
                }
            }),
            {
                interactionType: InteractionType.Redirect,
                loginFailedRoute: '/login'
            },
            {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new Map([
                    [`${window.origin}${environment.apiPrefix}`, applicationAuthScopes]
                ]),
            },
        ),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: TokenInterceptor
        },
        MsalInterceptor,
        ExternalUserGuard,
        BeeldbankMsalGuard,
        VwuiModalConfig,
        Title,
        serviceWithMock('TagService', TagServiceImpl, TagServiceMock),
        serviceWithMock('UserService', UserServiceImpl, UserServiceMock),
        serviceWithMock('ProjectService', ProjectService, ProjectServiceMock)
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
