<vwui-header>
    Mijn projecten
</vwui-header>

<div class="media-grid-filter">
    <app-search-input name="mediaSearch" placeholder="Zoeken..." [formControl]="searchControl"></app-search-input>
</div>

<div class="media-grid" infiniteScroll (scrolled)="projectPaginator.loadMore()">
    <div class="media-grid__empty-state" *ngIf="projectPaginator.isEmpty$|async">
        <img class="media-grid__empty-state__image" alt="" src="/assets/icons/image-landscape.svg">
        <p class="media-grid__empty-state__message">Geen projecten gevonden</p>
    </div>
    <div class="media-grid__holder">
        <app-media-grid-project-item *ngFor="let project of projectPaginator.content$|async" [item]="project"
                                     [routerLink]="['/projects', project.id]">
        </app-media-grid-project-item>
    </div>
</div>
