import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {map, mapTo, switchMap, take} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {UserService} from '../../services/user.service';
import {environment} from '../../../../environments/environment';
import {RolesList} from '../../models/roles-list';
import {NgVwuiTableConfig, VwuiModalService} from '@recognizebv/vwui-angular';
import {ProjectService} from '../../services/project.service';
import {Paginator} from '../../components/utils/paginator';
import {VWUITableHeaderSelectEvent, VWUITableRowSelectEvent} from '@recognizebv/vwui-core';
import {EditProjectManagerModalComponent} from '../../components/edit-project-manager-modal/edit-project-manager-modal.component';
import {User} from '../../models/user';
import {Project} from '../../models/project';

@Component({
    selector: 'app-users-details',
    templateUrl: './users-details.component.html'
})
export class UsersDetailsComponent implements OnInit, OnDestroy {
    public environment = environment;
    private subscriptions: Subscription[] = [];

    private refresh$ = new BehaviorSubject<void>(null);

    paginator$ = combineLatest([
        this.route.params,
        this.refresh$
    ]).pipe(
        switchMap(([params, _]) => {
            const paginator = new Paginator(page => {
                return this.projectService.getList(page, null, params.userId);
            });

            return paginator.content$.pipe(
                mapTo(paginator)
            );
        }))

    tableConfig: NgVwuiTableConfig = {
        title: 'Gekoppelde projecten',
        selectable: true,
        clickable: false,
        compactable: true,
        standalone: true,
        indexKey: 'id',
        columns: [
            {header: 'Project', itemKey: 'name'},
            {header: 'Organisatie', itemKey: 'organizations'},
            {header: 'Projectverantwoordelijke(n)', itemKey: 'projectResponsible'}
        ],
        menuOptions: []
    };

    form: FormGroup = new FormGroup({
        id: new FormControl(null, Validators.required),
        name: new FormControl(null, Validators.required),
        username: new FormControl(null, Validators.required),
        roles: new FormControl([])
    });

    user: User;

    availableRoles$ = combineLatest([
        this.userService.getCurrentUser(),
        this.userService.getEnabledRoles()
    ]).pipe(
        map(([user, roles]) => {
            const visibleRoles = roles.filter(it => it!== 'ROLE_USER_MANAGER');

            return visibleRoles.map(role => ({
                key: role,
                label: environment.roleLabels[role],
                disabled: !user.roles.includes('ROLE_SUPER_ADMIN')
                    && !(user.roles.includes('ROLE_USER_MANAGER') && role !== 'ROLE_SUPER_ADMIN')
            }));
        })
    );

    selectedProjects: Project[] = [];

    constructor(
        private fb: FormBuilder,
        private router: Router,
        @Inject('UserService') private userService: UserService,
        private route: ActivatedRoute,
        private toast: ToastrService,
        @Inject('ProjectService') private projectService: ProjectService,
        private modalService: VwuiModalService
    ) {
    }

    ngOnInit() {
        this.subscriptions.push(this.route.params.pipe(
            switchMap(params => this.userService.getUser(parseInt(params.userId))),
            map(user => ({
                ...user,
                roles: user.roles.filter(it => it !== 'ROLE_USER_MANAGER')
            })),
        ).subscribe(user => {
            this.user = user;
            this.form.patchValue(user, {emitEvent: false});
        }));
        this.subscriptions.push(this.form.valueChanges.subscribe(async value => {
            try {
                await this.userService.updateUser(value).toPromise();
                this.toast.success('Gebruiker opgeslagen');
            } catch (e) {
                this.toast.error('Opslaan mislukt');
                console.error('user update failed', e);
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    goBack() {
        this.router.navigateByUrl('/manage/users');
    }

    async rowSelected(event: CustomEvent<VWUITableRowSelectEvent>) {
        const {id, selected} = event.detail;
        const selectedProjectIds = this.selectedProjects.map(it => it.id.toString());
        if (!selectedProjectIds.includes(id) && selected) {
            this.selectedProjects.push(await this.projectService.getProjectDetail(+id).toPromise())
        } else if (selectedProjectIds.includes(id) && !selected) {
            this.selectedProjects = this.selectedProjects.filter(project => project.id.toString() !== id)
        }
    }

    allSelected(event: CustomEvent<VWUITableHeaderSelectEvent>, projects: Project[]) {
        if (event.detail.selected) {
            this.selectedProjects = projects;
        } else {
            this.selectedProjects = [];
        }
    }

    openEditProjectManagerModal() {
        const modal = this.modalService.open(EditProjectManagerModalComponent, {
            data: {
                user: this.user,
                selectedProjects: this.selectedProjects
            }
        })
        modal.afterClosed.pipe(take(1)).subscribe(value => {
            if (value) {
                this.refresh$.next();
            }
        })
    }
}
