import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html'
})
export class IconComponent {

  @Input() iconName: string;

  constructor() { }

  get icon() {
    return `/assets/beel/svg/icons.svg#icon-${this.iconName}`;
  }
}
