<app-action-button iconName="download" [popover]="popover"></app-action-button>

<ng-template #popover>
    <div class="popover-image-download">
        <div class="popover-image-download__message">
            <ng-container *ngIf="!isPending">Je hebt nog geen toestemming</ng-container>
            <ng-container *ngIf="isPending">Je aanvraag wordt verwerkt</ng-container>
        </div>
        <div class="popover-image-download__content">
            <ng-container *ngIf="!isPending">
                Je hebt nog geen toestemming om bestanden binnen dit project te kunnen downloaden. Vraag toestemming aan door op onderstaande knop te klikken.
            </ng-container>
            <ng-container *ngIf="isPending">
                Je aanvraag is succesvol binnengekomen en wordt zo snel mogelijk bekeken. Je ontvangt een bericht wanneer de aanvraag verwerkt is.
            </ng-container>
        </div>
        <div class="popover-image-download__action" *ngIf="!isPending">
            <vwui-button block class="is-primary" (click)="requestProject.emit()">Toestemming vragen</vwui-button>
        </div>
    </div>
</ng-template>
