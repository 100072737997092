import {Injectable} from '@angular/core';
import {TransferProgressEvent} from '@azure/core-http';
import {BlobServiceClient, BlockBlobClient, BlockBlobParallelUploadOptions} from '@azure/storage-blob';
import {Observable, Subscriber} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BlobStorageService {

    uploadToBlobStorage(
        accountName: string,
        containerName: string,
        blobName: string,
        sasToken: string,
        file: File,
        options: Partial<BlockBlobParallelUploadOptions> = {}
    ) {
        const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        return this.uploadFile(blockBlobClient, file, options);
    }

    private uploadFile(blockBlobClient: BlockBlobClient, file: File, options: Partial<BlockBlobParallelUploadOptions> = {}) {
        return new Observable<number>(observer => {
            blockBlobClient
                .uploadBrowserData(file, {
                    ...options,
                    onProgress: this.onProgress(observer),
                    blobHTTPHeaders: {
                        blobContentType: file.type
                    },
                })
                .then(
                    this.onUploadComplete(observer, file),
                    this.onUploadError(observer)
                );
        }).pipe(distinctUntilChanged());
    }

    private onUploadError(observer: Subscriber<number>) {
        return (error: any) => observer.error(error);
    }

    private onUploadComplete(observer: Subscriber<number>, file: File) {
        return () => {
            observer.next(file.size);
            observer.complete();
        };
    }

    private onProgress(observer: Subscriber<number>) {
        return (progress: TransferProgressEvent) =>
            observer.next(progress.loadedBytes);
    }
}
