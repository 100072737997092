import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment';

@Pipe({
    name: 'filterHiddenRoles'
})
export class FilterHiddenRolesPipe implements PipeTransform {

    transform(roles: string[]): string[] {
        return roles.filter(role => role !== 'ROLE_USER_MANAGER');
    }
}
