import {Component} from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {ProjectDownloadPermission} from '../../models/project-download-permission';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';
import {ProjectDownloadPermissionService} from '../../services/project-download-permission.service';

@Component({
    selector: 'app-project-request',
    templateUrl: './project-request.component.html'
})
export class ProjectRequestComponent {
    loading = false;
    refresh = new BehaviorSubject(true);
    pendingRequests$ = this.projectDownloadPermissionService.pendingRequests$;

    constructor(
        private projectService: ProjectService,
        private projectDownloadPermissionService: ProjectDownloadPermissionService,
        private toast: ToastrService
    ) {
    }

    async updateProjectPermissionRequest(projectRequest: ProjectDownloadPermission, approved: boolean) {
        this.loading = true;

        try {
            await this.projectDownloadPermissionService.updatePermissionRequest(
                projectRequest.id,
                approved ? 'approve' : 'deny'
            ).toPromise();
            this.toast.success('Opgeslagen');
        } catch (e) {
            console.error('updateProjectPermissionRequest error', e);

            this.toast.error('Opslaan mislukt');
        } finally {
            this.loading = false;
            this.refresh.next(true);
        }
    }

}
