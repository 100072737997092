import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Tag} from '../models/tag';
import {PageResponse} from '../models/page-response';

export interface TagService {
    createTag(username: string): Observable<Tag>;

    getTagsPage(page: number, query: string): Observable<PageResponse<Tag>>;

    getTag(tagId: number): Observable<Tag>;

    updateTag(tag: Tag): Observable<Tag>;

    deleteTag(tag: Tag): Observable<any>;

    exists(name: string, ignoreId?: number): Observable<boolean>;

    mergeTagsByName(tagId: number, destinationName: string): Observable<void>;
}

@Injectable()
export class TagServiceImpl implements TagService {

    constructor(private client: HttpClient) {
    }

    createTag(name: string) {
        return this.client.post<Tag>(`${environment.apiPrefix}/tags`, {name});
    }

    getTagsPage(page: number, query: string = ''): Observable<PageResponse<Tag>> {
        return this.client.get<PageResponse<Tag>>(`${environment.apiPrefix}/tags`, {
            params: {page: page.toString(), query}
        });
    }

    getTag(tagId: number) {
        return this.client.get<Tag>(`${environment.apiPrefix}/tags/${tagId}`);
    }

    updateTag(tag: Tag) {
        return this.client.put<Tag>(`${environment.apiPrefix}/tags/${tag.id}`, tag);
    }

    deleteTag(tag: Tag) {
        return this.client.delete(`${environment.apiPrefix}/tags/${tag.id}`);
    }

    mergeTagsByName(tagId: number, destinationName: string) {
        return this.client.post<void>(`${environment.apiPrefix}/tags/${tagId}/merge`, {destinationName});
    }

    exists(name: string, ignoreId?: number): Observable<boolean> {
        const params: { [key: string]: string } = {name};
        if (ignoreId) {
            params.ignoreId = ignoreId.toString();
        }

        return this.client.get<boolean>(`${environment.apiPrefix}/tags/exists`, {params});
    }
}
