<vwui-root>
    <vwui-nav>
        <vwui-logo slot="logo" logoSrc="/assets/logo.svg" class="menu__logo"></vwui-logo>
        <vwui-nav-item
            href="/" iconName="library"
            routerLink="/"
            [routerLinkActiveOptions]="{exact: true}"
            routerLinkActive #displayMediaRoute="routerLinkActive"
            [class.is-active]="displayMediaRoute.isActive && (projectsMenuOpen$|async) === false"
        >
            {{ environment.appNameShort || environment.appName }}
        </vwui-nav-item>
        <vwui-nav-item iconName="share" routerLink="/public"
                       routerLinkActive #publicRoute="routerLinkActive"
                       [class.is-active]="publicRoute.isActive && (projectsMenuOpen$|async) === false">
            Openbare items
        </vwui-nav-item>
        <vwui-nav-item iconName="projects" routerLink="/my-projects"
                       routerLinkActive #myProjectsRoute="routerLinkActive"
                       [class.is-active]="myProjectsRoute.isActive && (projectsMenuOpen$|async) === false">
            Mijn projecten
        </vwui-nav-item>
        <vwui-nav-item iconName="projects" (vwuiToggleNavMenu)="projectsMenuOpen$.next($event.detail)">
            Projecten
            <vwui-nav-item-menu>
                <app-project-list-menu></app-project-list-menu>
            </vwui-nav-item-menu>
        </vwui-nav-item>
        <vwui-nav-item iconName="vistacreate" *appHasRole="[ 'ROLE_ORGANIZATION_ADMIN','MARKETING']"
                       (vwuiToggleNavMenu)="projectsMenuOpen$.next($event.detail)">
            <span><b>Vista</b>create</span>
            <vwui-nav-item-menu>
                <app-vista-create-list-menu></app-vista-create-list-menu>
            </vwui-nav-item-menu>
        </vwui-nav-item>
        <div class="u-margin-b--md">
            <vwui-nav-header>
                Mijn taken
            </vwui-nav-header>
            <vwui-nav-item (click)="openMediaProcessingModal(false)" icon="upload" [badge]="stagedMediaCount$ | async">
                Media uploaden
            </vwui-nav-item>
            <vwui-nav-item (click)="openMediaProcessingModal(true)" icon="check" [badge]="pendingMediaCount$ | async">
                Media goedkeuren
            </vwui-nav-item>
            <vwui-nav-item href="project-request" icon="notification" [badge]="pendingRequestCount$ | async"
                           routerLink="/project-request"
                           routerLinkActive #projectRequestRoute="routerLinkActive"
                           [class.is-active]="projectRequestRoute.isActive && (projectsMenuOpen$|async) === false">
                Project verzoeken
            </vwui-nav-item>
        </div>
        <div class="u-margin-b--md" *appHasRole="['ROLE_ORGANIZATION_ADMIN', 'ROLE_USER_MANAGER']">
            <vwui-nav-header>
                Beheer
            </vwui-nav-header>
            <vwui-nav-item *appHasRole="['ROLE_SUPER_ADMIN']"
                           icon="organizations" routerLink="/manage/organizations"
                           routerLinkActive #organizationsRoute="routerLinkActive"
                           [class.is-active]="organizationsRoute.isActive">
                Organisaties
            </vwui-nav-item>
            <vwui-nav-item *appHasRole="['ROLE_SUPER_ADMIN']"
                           icon="map" routerLink="/manage/associations"
                           routerLinkActive #assosciationsRoute="routerLinkActive"
                           [class.is-active]="assosciationsRoute.isActive">
                {{environment.appNameShort === 'InFrame' ? 'Kantoorlocaties' : 'Bedrijfsnamen'}}
            </vwui-nav-item>
            <vwui-nav-item *appHasRole="['ROLE_USER_MANAGER']"
                           icon="user" routerLink="/manage/users"
                           routerLinkActive #usersRoute="routerLinkActive"
                           [class.is-active]="usersRoute.isActive">
                Gebruikers
            </vwui-nav-item>
            <vwui-nav-item *appHasRole="['ROLE_ORGANIZATION_ADMIN']"
                           icon="tags" routerLink="/manage/tags"
                           routerLinkActive #tagsActive="routerLinkActive"
                           [class.is-active]="tagsActive.isActive">
                Tags
            </vwui-nav-item>
        </div>
        <app-user-info slot="nav-footer" class="menu__user-info"></app-user-info>
    </vwui-nav>
    <vwui-main>
        <router-outlet></router-outlet>
    </vwui-main>
</vwui-root>
