import {BehaviorSubject, Observable} from 'rxjs';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {PageResponse} from '../../models/page-response';

export class Paginator<T> {
    private page$ = new BehaviorSubject(0);
    totalElements: number;
    totalPages: number;
    currentPage: number;
    isEmpty: boolean;
    content$: Observable<T[]> = this.page$.pipe(
        switchMap(page => this.loadFunction(page)),
        shareReplay(1),
        tap(response => {
            this.totalPages = response.totalPages;
            this.currentPage = response.number;
            this.isEmpty = response.empty;
            this.totalElements = response.totalElements;
        }),
        map(response => response.content)
    );

    constructor(private loadFunction: (page: number) => Observable<PageResponse<T>>) {
    }

    next() {
        this.page$.next(this.page$.value + 1);
    }

    previous() {
        this.page$.next(this.page$.value - 1);
    }

    refreshPage()  {
        this.page$.next(this.page$.value);
    }

    setPage(page: number) {
        this.page$.next(page);
    }
}
