import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../../models/project';
import {ProjectAlbum} from '../../models/project-album';

@Component({
    selector: 'app-media-grid-project-item',
    templateUrl: './media-grid-project-item.component.html'
})
export class MediaGridProjectItemComponent implements OnInit {
    @Input() item: Project | ProjectAlbum;

    labelPostfix: string;

    ngOnInit(): void {
        this.updateLabelTitle(this.item.mediaCount);
    }

    private updateLabelTitle(mediaCount: number) {
        this.labelPostfix = mediaCount && mediaCount === 1 ? 'bestand' : `bestanden`;
    }

    buildThumbnailUrl() {
        return this.item.thumbnailUrl ? `url("${this.item.thumbnailUrl}")` : null;
    }

    getOrganizationName() {
        return ('organizations' in this.item ? this.item.organizations : this.item.project.organizations)
            .map(it => it.organization.name).join(', ');
    }
}
