<div class="project-list-menu">
    <div class="project-list-menu__header">
        <div class="project-list-menu__search">
            <vwui-search placeholder="Zoeken..." [formControl]="searchControl"></vwui-search>
        </div>
        <div class="project-list-menu__toolbar">
            <div class="project-list-menu__title" [class.project-list-menu__title--show-back]="getContentMode() !== 'root'">
                <vwui-btn-icon (click)="onBack()" class="project-list-menu__back-btn" icon="back"></vwui-btn-icon>
                <span class="project-list-menu__title-label">{{ getListTitle() }}</span>
            </div>
            <div class="project-list-menu__create-btn" [class.project-list-menu__create-btn--visible]="getContentMode() === 'detail'">
                <vwui-tooltip position="left" label="Project toevoegen">
                    <vwui-button icon="vwui-add" class="is-action" (click)="openCreateProjectPage()"></vwui-button>
                </vwui-tooltip>
            </div>
        </div>
    </div>

    <div class="project-list-menu__content">
        <ng-container *ngIf="getContentMode() === 'root'; else detail">
            <div class="project-list">
                <app-project-list-item *ngFor="let item of (organizationList$|async)" [label]="item.name" (click)="pushParentId(item.id)"></app-project-list-item>
                <app-project-list-item label="Laad meer" [isShowMore]="true" *ngIf="showLoadMore" (click)="loadMore$.next()"></app-project-list-item>
            </div>
        </ng-container>

        <ng-template #detail>
            <div class="project-list" *ngIf="(organizationList$|async)?.length > 0">
                <div class="project-list-header">Organisaties</div>
                <app-project-list-item *ngFor="let item of (organizationList$|async)" [label]="item.name" (click)="pushParentId(item.id)"></app-project-list-item>
                <app-project-list-item label="Laad meer" [isShowMore]="true" *ngIf="showLoadMore" (click)="loadMore$.next()"></app-project-list-item>
            </div>
            <ng-container *ngIf="projectListPaginator.content$|async as projects">
                <div class="project-list" infiniteScroll
                     [infiniteScrollContainer]="'.project-list-menu__content'" [fromRoot]="true"
                     [infiniteScrollDistance]="2" (scrolled)="projectListPaginator.loadMore()">
                <div class="project-list-header">Projecten</div>
                    <app-project-list-item
                        *ngFor="let item of projects"
                        [label]="item.name"
                        routerLink="/projects/{{item.id}}"
                        routerLinkActive="active"
                    ></app-project-list-item>
                    <div class="project-list-spinner" *ngIf="projectListPaginator.loading$|async">
                        <vwui-spinner></vwui-spinner>
                    </div>
                    <p *ngIf="projects.length === 0">Geen projecten gevonden</p>
                </div>
            </ng-container>
        </ng-template>
    </div>
</div>
