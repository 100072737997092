import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-image-download-request',
    templateUrl: './image-download-request.component.html'
})
export class ImageDownloadRequestComponent {
    @Input() isPending = false;

    @Output() requestProject = new EventEmitter();
}
