<vwui-modal-header>
    Tag aanmaken
</vwui-modal-header>
<vwui-modal-body>
    <form [formGroup]="form" (ngSubmit)="saveTag()">
        <vwui-form-group label="Tag" inputId="tag">
            <vwui-input [autofocus]="true" id="name" name="Tag" formControlName="tag"></vwui-input>
            <div *ngIf="form.get('tag').hasError('exists')" class="text-danger">
                <small>Tag moet uniek zijn</small>
            </div>
        </vwui-form-group>
    </form>
</vwui-modal-body>
<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modal.close()">Annuleren</vwui-button>
    <vwui-button class="is-primary" type="submit" (click)="saveTag()" [loading]="loading" [disabled]="form.invalid">Opslaan</vwui-button>
</vwui-modal-footer>
