<div class="media-info-tags" *ngIf="media">
    <ng-container *ngIf="!showTagSelect">
        <div class="media-info-tags__list" *ngIf="tags.length > 0">
            <a class="media-info-tags__tag"
               *ngFor="let tagItem of tags"
               routerLink="/display-media"
               [queryParams]="{ 'query': tagItem.name }"
               (click)="tagClick.emit()"
            >
                {{tagItem.name}}
            </a>
        </div>
        <ng-container *appHasProjectResponsibleAccess="editBtn; params {projectId: media.project?.id}"></ng-container>
    </ng-container>
    <ng-container *ngIf="showTagSelect">
        <div class="media-info-tags__tag-select">
            <app-tags-form-control [formControl]="control"></app-tags-form-control>
        </div>
        <button class="media-info-tags__tag-btn media-info-tagsl__tag-btn--large" (click)="saveTags()" [disabled]="loading">
            <vwui-icon iconName="save" *ngIf="!loading"></vwui-icon>
            <vwui-spinner *ngIf="loading"></vwui-spinner>
            tags opslaan
        </button>
    </ng-container>
</div>


<ng-template #editBtn>
    <button
        class="media-info-tags__tag-btn"
        (click)="editTags()"
    >
        <vwui-icon iconName="edit-2"></vwui-icon>
        tags {{btnLabel}}
    </button>
</ng-template>
