import {Component, OnDestroy, OnInit} from '@angular/core';
import {MsalBroadcastService} from '@azure/msal-angular';
import {Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {NgSelectConfig} from '@ng-select/ng-select';
import {EventMessage, EventType} from "@azure/msal-browser";
import {filter, takeUntil} from "rxjs/operators";
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public environment = environment;

    private failureSubscription: Subscription;
    private successSubscription: Subscription;

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private config: NgSelectConfig,
        private broadcastService: MsalBroadcastService,
        private router: Router,
        private titleService: Title
    ) {}

    public ngOnInit() {
        this.titleService?.setTitle(environment.appName);

        this.broadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType == EventType.LOGIN_FAILURE),
                takeUntil(this._destroying$)
            ).subscribe((result) =>
                this.router.navigate(['login'])
            )
        this.broadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType == EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this._destroying$)
            ).subscribe((result) =>
                this.router.navigate(['login'])
            )
        this.broadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType == EventType.LOGIN_SUCCESS),
                takeUntil(this._destroying$)
            ).subscribe( result =>
                this.router.navigate['']
            )

        this.applyNgSelectConfig(this.config);
    }

    public ngOnDestroy(): void {
        this._destroying$.next(undefined)
        this._destroying$.complete()
    }

    // noinspection JSMethodCanBeStatic
    private applyNgSelectConfig(config: NgSelectConfig) {
        config.notFoundText = 'Geen resultaten';
        config.addTagText = 'Toevoegen';
        config.typeToSearchText = 'Type om te zoeken';
        config.loadingText = 'Laden...';
    }
}
