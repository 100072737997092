import {Directive, Inject, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';

import {UserRole} from '../models/role';
import {UserService} from '../services/user.service';
import {User} from '../models/user';

@Directive({
    selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit {
    currentUser: User;
    // the role the user must have
    @Input() appHasRole: Array<string>;

    isVisible = false;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        @Inject('UserService') private userService: UserService
    ) {
    }

    async ngOnInit() {
        try {
            const currentUser = await this.userService.getCurrentUser().toPromise();

            if (!currentUser) {
                this.viewContainerRef.clear();
            }
            this.currentUser = currentUser;

            // If the user has the role needed to
            // render this component we can add it
            if (this.currentUser.roles.some(role => this.appHasRole.includes(role))) {
                // If it is already visible (which can happen if
                // his roles changed) we do not need to add it a second time
                if (!this.isVisible) {
                    // We update the `isVisible` property and add the
                    // templateRef to the view using the
                    // 'createEmbeddedView' method of the viewContainerRef
                    this.isVisible = true;
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                }
            } else {
                // If the user does not have the role,
                // we update the `isVisible` property and clear
                // the contents of the viewContainerRef
                this.isVisible = false;
                this.viewContainerRef.clear();
            }
        } catch (error) {
            console.error(error);

            this.viewContainerRef.clear();
        }
    }
}
