<vwui-header>
    {{ environment.appNameShort }}
</vwui-header>

<div class="media-grid-filter" [formGroup]="form">
    <app-search-input name="mediaSearch" placeholder="Zoeken..." formControlName="search"></app-search-input>
    <app-file-type-filter name="mediaFilter" formControlName="fileTypes"></app-file-type-filter>
    <app-orientation-filter formControlName="orientation"></app-orientation-filter>
    <app-date-range-picker placeholder="Foto gemaakt op" formControlName="exifCreatedBeteen"></app-date-range-picker>
</div>

<ng-container *ngIf="(searchValue$|async)?.search">
    <div class="media-grid" *ngIf="(organizationPaginator.content$|async)?.length > 0">
        <app-search-result-group type="organizations"
                                 [results]="organizationPaginator.content$|async"
                                 [hasMoreItems]="organizationPaginator.hasMore()"
                                 (loadMore)="organizationPaginator.loadMore()">
            <span title>Organisaties</span>
        </app-search-result-group>
    </div>
    <div class="media-grid" *ngIf="(projectPaginator.content$|async)?.length > 0">
        <app-search-result-group type="projects"
            [results]="projectPaginator.content$|async"
            [hasMoreItems]="projectPaginator.hasMore()"
            (loadMore)="projectPaginator.loadMore()">
            <span title>Projecten</span>
        </app-search-result-group>
    </div>
    <div class="media-grid" *ngIf="(albumPaginator.content$|async)?.length > 0">
        <h4>Albums</h4>
        <div class="media-grid-album" [class.media-grid-album--expanded]="true">
            <a class="media-grid-album__item" *ngFor="let album of (albumPaginator.content$ | async)"
               [routerLink]="['projects', album.project.id, 'albums', album.id]">
                <img class="media-grid-album__item__thumbnail" alt="thumbnail"
                     [src]="album.thumbnailUrl || '/assets/icons/image-landscape-square.svg'">
                <div class="media-grid-album__item__text">
                    <div class="media-grid-album__item__title">{{ album.name }}</div>
                    <div class="media-grid-album__item__subtitle">{{ album.mediaCount }}
                        Foto{{ album.mediaCount === 1 ? '' : 's' }}</div>
                </div>
            </a>
        </div>
        <vwui-button *ngIf="albumPaginator.hasMore()" icon="chevrons-down" class="d-flex justify-content-center"
                     (click)="albumPaginator.loadMore()">Laad meer albums
        </vwui-button>
    </div>

</ng-container>

<div class="media-grid" *ngIf="(mediaPaginator.content$|async) as media">
    <app-media-items-grid *ngIf="media.length > 0"
                          [mediaItems]="media"
                          [showMediaInfo]="true"
                          [gridTitle]="(searchActive$|async) ? 'Media' : 'Laatste uploads'"
                          (mediaItemSelect)="openImagePreviewModal($event, media)"
                          [showLoadMore]="mediaPaginator.hasMore()"
                          (loadMore)="mediaPaginator.loadMore()">
    </app-media-items-grid>
    <app-empty-media-card *ngIf="media.length === 0 && !((searchValue$|async)?.search)">
        <p class="empty-media-card__description">Er zijn geen recente uploads.</p>
    </app-empty-media-card>
</div>
<p *ngIf="(searchValue$|async)?.search && (mediaPaginator.isEmpty$|async) && (albumPaginator.isEmpty$|async)
    && (projectPaginator.isEmpty$|async) && (organizationPaginator.isEmpty$|async)"
   class="d-flex justify-content-center">
    Geen resultaten gevonden.
</p>
<app-progress-bar
    [showProgressBar]="showUploadProgress"
    [imageProgressBarCount]="1"
    [pbTotalCount]="1"
    [fileName]="uploadCurrentFilename"
    [totalSize]="uploadTotalBytes"
    [showCancelButton]="false"
></app-progress-bar>
