import { Component } from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ToastrService} from 'ngx-toastr';
import {ProjectAlbum} from '../../models/project-album';
import {ProjectAlbumService} from '../../services/project-album.service';

@Component({
  selector: 'app-project-album-delete',
  templateUrl: './project-album-delete.component.html'
})
export class ProjectAlbumDeleteComponent {
    loading = false;

    constructor(
        public modal: VwuiModalRef,
        private modalConfig: VwuiModalConfig<{ album: ProjectAlbum }>,
        private projectAlbumService: ProjectAlbumService,
        private toast: ToastrService) {}

    confirm() {
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.projectAlbumService.deleteAlbum(this.modalConfig.data.album).subscribe(() => {
            this.loading = false;
            this.toast.success(`Album is verwijderd`);
            this.modal.close({message: 'SUCCESS'});
        }, err => {
            this.loading = false;
            console.error(err + 'Error occurred while deleting album');
            this.toast.error('Verwijderen mislukt');
        });
    }
}
