import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-project-list-item',
    templateUrl: './project-list-item.component.html'
})
export class ProjectListItemComponent implements OnInit {
    @Input() label: string;
    @Input() isShowMore = false;

    iconName: string;

    ngOnInit(): void {
        this.iconName = this.isShowMore ? 'load-more' : 'vwui-chevron-right';
    }
}
