<vwui-header>
    Openbare items
</vwui-header>

<div class="media-grid-filter">
    <app-search-input name="mediaSearch" placeholder="Zoeken..." [formControl]="searchControl"></app-search-input>
</div>

<div class="media-grid row">
    <div class="col-md-7" infiniteScroll (scrolled)="projectPaginator.loadMore()">
        <h4>Projecten</h4>

        <div class="media-grid__empty-state" *ngIf="projectPaginator.isEmpty$|async">
            <img class="media-grid__empty-state__image" alt="" src="/assets/icons/image-landscape.svg">
            <p class="media-grid__empty-state__message">Geen projecten gevonden</p>
        </div>
        <div class="media-grid__holder">
            <app-media-grid-project-item *ngFor="let project of projectPaginator.content$|async" [item]="project"
                                         [routerLink]="['/projects', project.id]">
            </app-media-grid-project-item>
        </div>
    </div>
    <div class="col-md-5" infiniteScroll (scrolled)="albumPaginator.loadMore()">
        <h4>Albums</h4>

        <div class="media-grid__empty-state" *ngIf="albumPaginator.isEmpty$|async">
            <img class="media-grid__empty-state__image" alt="" src="/assets/icons/image-landscape.svg">
            <p class="media-grid__empty-state__message">Geen albums gevonden</p>
        </div>
        <div class="media-grid__holder">
            <app-media-grid-project-item *ngFor="let album of albumPaginator.content$|async" [item]="album"
                                         [routerLink]="['/projects', album.project.id, 'albums', album.id]">
            </app-media-grid-project-item>
        </div>
    </div>
</div>
