<div class="user-info">
    <div class="user-info__initials">
        {{ initials }}
    </div>
    {{ name }}
    <vwui-btn-icon icon="signout"
                   class="user-info__logout"
                   (click)="logout()"
    ></vwui-btn-icon>
</div>
