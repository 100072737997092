import {Inject, Injectable} from '@angular/core';
import {Media} from '../models/media';
import {environment} from '../../../environments/environment';
import {FileUtil} from '../shared/file';
import {MediaService} from './media.service';
import {BlobStorageService} from './blob-storage.service';
import {PublishedDesign} from '../models/vista/published-design';
import {
    DesignEditorConfig,
    KnownSidebarTab, MeasureUnits,
    SidebarTabsConfig
} from '../models/vista/design-editor-config';
import {AuthService} from './auth.service';
import {map, pairwise, startWith, take, tap} from 'rxjs/operators';
import {UserService} from './user.service';
import {Observable} from 'rxjs/Observable';

declare global {
    interface Window {
        CrelloEditor: {
            init(options: DesignEditorConfig): Promise<any>;
        };
    }
}
export interface VistaCreateCategory {
    title: string;
    templates: VistaCreateTemplate[];
}

export interface VistaCreateTemplate {
    title: string;
    designType?: string;
    customDesignFormatId?: string;
    width?: number;
    height?: number;
    measureUnits?: MeasureUnits;
}

@Injectable({
    providedIn: 'root'
})
export class VistaCreateService {

    public readonly MARKETING_CATEGORIES: VistaCreateCategory[] = [{
        title: 'Posts & Stories',
        templates: [
            {
                title: 'Blog Post',
                designType: 'graphicBG',
                width: 600,
                height: 1200
            },
            {
                title: 'Facebook Post',
                designType: 'facebookSM',
                width: 940,
                height: 788
            },
            {
                title: 'Facebook Video Story',
                designType: 'facebookVideoStoryAN',
                width: 1080,
                height: 1920
            },
            {
                title: 'Instagram Highlight Cover',
                designType: 'instagramHighlightCoverSM',
                width: 1080,
                height: 1920
            },
            {
                title: 'Instagram Post',
                designType: 'instagramSM',
                width: 1080,
                height: 1080
            },
            {
                title: 'Instagram Story',
                designType: 'instagramStorySM',
                width: 1080,
                height: 1920
            },
            {
                title: 'Instagram Video Story',
                designType: 'instagramVideoStoryAN',
                width: 1080,
                height: 1920
            },
            {
                title: 'Square Graphic Post',
                designType: 'socialMediaSM',
                width: 800,
                height: 800
            }
        ]
    }, {
        title: 'Covers & Headers',
        templates: [
            {
                title: 'Facebook Cover',
                designType: 'facebookCoverHC',
                width: 851,
                height: 315
            },
            {
                title: 'Facebook Event Cover',
                designType: 'fbEventCoverHC',
                width: 1920,
                height: 1080
            },
            {
                title: 'IGTV Cover',
                designType: 'igtvCoverSM',
                width: 420,
                height: 654
            },
            {
                title: 'LinkedIn Cover',
                designType: 'linkedInCoverHC',
                width: 1128,
                height: 191
            },
            {
                title: 'YouTube Channel Art',
                designType: 'youtubeHC',
                width: 2560,
                height: 1440
            },
            {
                title: 'YouTube Thumbnail',
                designType: 'youtubeThumbnailsHC',
                width: 1280,
                height: 720
            }
        ]
    }, {
        title: 'Online Ads',
        templates: [
            {
                title: 'Facebook Ad',
                designType: 'facebookADSMA',
                width: 1200,
                height: 628
            },
            {
                title: 'Instagram Ad',
                designType: 'instagramADSMA',
                width: 1080,
                height: 1080
            },
            {
                title: 'Large Rectangle',
                designType: 'largeRectangleSMA',
                width: 336,
                height: 280
            },
            {
                title: 'Leaderboard',
                designType: 'leaderboardSMA',
                width: 728,
                height: 90
            },
            {
                title: 'Medium Rectangle',
                designType: 'mediumRectangleSMA',
                width: 300,
                height: 250
            }
        ]
    }, {
        title: 'Marketing',
        templates: [
            {
                title: 'Infographic',
                designType: 'infographicMM',
                width: 800,
                height: 2000
            }
        ]
    }, {
        title: 'Documents & Letters',
        templates: [
            {
                title: 'A4',
                designType: 'a4MM',
                width: 21,
                height: 29.7,
                measureUnits: 'cm'
            },
            {
                title: 'Brochure',
                designType: 'brochureMM',
                width: 29.7,
                height: 21,
                measureUnits: 'cm'
            }
        ]
    }, {
        title: 'Video & Animation',
        templates: [
            {
                title: 'Animated Logo',
                designType: 'animatedLogoAN',
                width: 500,
                height: 500
            },
            {
                title: 'Facebook Video Story',
                designType: 'facebookVideoStoryAN',
                width: 1080,
                height: 1920
            },
            {
                title: 'Instagram Video Story',
                designType: 'instagramVideoStoryAN',
                width: 1080,
                height: 1920
            },
            {
                title: 'Square Video Post',
                designType: 'animatedPostAN',
                width: 1080,
                height: 1080
            },
            {
                title: 'Video Full HD',
                designType: 'fullHDVideoAN',
                width: 1920,
                height: 1080
            }
        ],
    }, {
        title: 'Additional Custom Formats(s)',
        templates: [
            {
                title: 'Kader template Banner Nieuwsbrief',
                customDesignFormatId: '63b48d4189ed545f2b380c0f',
                width: 2265,
                height: 554
            },
            {
                title: 'Kader template afbeelding Nieuwsbrief',
                customDesignFormatId: '63b48d5989ed545f2b380c15',
                width: 300,
                height: 225
            },
            {
                title: 'Kader template website afbeelding',
                customDesignFormatId: '63b48d75b523b0081e9594e3',
                width: 1692,
                height: 966
            },
            {
                title: 'Kader template overzicht afbeelding Web',
                customDesignFormatId: '63b48d8c0876d063a01bccc7',
                width: 263,
                height: 174
            },
            {
                title: 'Kader template startdatum mailing',
                customDesignFormatId: '63b48da6f3db41e74ed99db4',
                width: 548,
                height: 313
            },
            {
                title: 'LinkedIn profile picture (not default?)',
                customDesignFormatId: '63b48dbf0876d063a01bccd4',
                width: 300,
                height: 300
            },
            {
                title: 'Remarketing Ads',
                customDesignFormatId: '65a1d23e859b99c390e7cbcb',
                width: 300,
                height: 250
            },
            {
                title: 'Remarketing Ads',
                customDesignFormatId: '65a1d260566dbc1b9008d7ce',
                width: 336,
                height: 280
            },
            {
                title: 'Remarketing Ads',
                customDesignFormatId: '65a1d2aaf6fbf42348e3f64d',
                width: 728,
                height: 90
            },
            {
                title: 'Remarketing Ads',
                customDesignFormatId: '65a1d2ca566dbc1b9008d9b2',
                width: 300,
                height: 600
            },
            {
                title: 'Remarketing Ads',
                customDesignFormatId: '65a1d2ea66b8ed8a9fbf5be6',
                width: 320,
                height: 100
            },
            {
                title: 'Intranet',
                customDesignFormatId: '65a1d31266b8ed8a9fbf5ca3',
                width: 1204,
                height: 677
            },
            {
                title: 'Nieuwsbrief',
                customDesignFormatId: '65a1d330859b99c390e7cffa',
                width: 300,
                height: 225
            },
            {
                title: 'Jaarkalender',
                customDesignFormatId: '65a1d381566dbc1b9008dcf1',
                width: 210,
                height: 600
            },
            {
                title: 'Actueel nieuws website',
                customDesignFormatId: '65a1d39e6fb8c9e93f00ec93',
                width: 720,
                height: 508
            },
            {
                title: 'Overzicht afbeeldingen website academy',
                customDesignFormatId: '65a1d3bf6fb8c9e93f00ed47',
                width: 263,
                height: 174
            },
            {
                title: 'Intranet personeel',
                customDesignFormatId: '65a1d3de566dbc1b9008de91',
                width: 600,
                height: 320
            },
            {
                title: 'Templates recruitee',
                customDesignFormatId: '65a1d3fa859b99c390e7d39b',
                width: 1200,
                height: 630
            },
            {
                title: 'Header Spotler',
                customDesignFormatId: '65a1d41ebb053a4977411049',
                width: 594,
                height: 508
            }
        ]
    }];

    public readonly USER_CATEGORIES: VistaCreateCategory[] = [{
        title: 'User categories',
        templates: [
            {
                title: 'Facebook Post',
                designType: 'facebookSM',
                width: 940,
                height: 788
            },
            {
                title: 'Facebook Video Story',
                designType: 'facebookVideoStoryAN',
                width: 1080,
                height: 1920
            },
            {
                title: 'Instagram Post',
                designType: 'instagramSM',
                width: 1080,
                height: 1080
            },
            {
                title: 'Instagram Story',
                designType: 'instagramStorySM',
                width: 1080,
                height: 1920
            },
            {
                title: 'A4',
                designType: 'a4MM',
                width: 21,
                height: 29.7
            },
        ]
    }];

    constructor(
        private mediaService: MediaService,
        private blobStorage: BlobStorageService,
        private authService: AuthService,
        @Inject('UserService') private userService: UserService,
    ) {
    }

    startVistaWithUserTabConfig(
        template: VistaCreateTemplate,
        media: Media,
        publishCallback: (data: PublishedDesign) => void,
        vistaClosedCallback: () => void,
        mediaToUpload: Media[],
    ) {
        this.vistaCreateUserTabConfig$().pipe(
            tap((tabConfig) => this.startVista(template, media, publishCallback, vistaClosedCallback, mediaToUpload, tabConfig)),
            take(1)
        ).subscribe();
    }

    startVista(
        template: VistaCreateTemplate,
        media: Media | null,
        publishCallback: (data: PublishedDesign) => void,
        vistaClosedCallback: () => void,
        mediaToUpload: Media[],
        tabConfig?: SidebarTabsConfig
    ) {
        window.CrelloEditor.init({
            apiKey: environment.vistaCreateApiKey,
            designType: template.designType,
            customDesignFormatId: template.customDesignFormatId,
            customDimensions: null,
            user: {
                // This make's it possible for the user to access the templates they have created in the past.
                externalUserId: this.authService.getUserName()
            },
            lang: 'nl',
            downloadFormat: media?.fileType === 'image/png' ? 'pngTransparent' : 'jpg',
            sidebarTabsConfig: tabConfig,
            onPublishAction(data) {
                publishCallback(data);
            },
            onCloseAction() {
                vistaClosedCallback();
            },
        }).then((editorApi) => {
            if (media) {
                editorApi.addBackgroundImage({
                    image: media.url,
                });
            }
            if (mediaToUpload.length > 0) {
                editorApi.addImages({
                    images: mediaToUpload.map(m => m.url),
                });
            }
        });
    }

    async uploadVistaResult(media: Media, blob: Blob, newFileName: string, uploadCallback?: (prevCur: [number, number]) => void): Promise<Media> {
        const response = await this.mediaService.prepareUpload({
            fileName: newFileName,
            fileType: media.fileType,
            fileExtension: FileUtil.getFileExtension(media.fileName)
        }).toPromise();

        await this.blobStorage.uploadToBlobStorage(
            response.accountName,
            response.containerName,
            response.media.blobName,
            response.sasToken,
            new File([blob], newFileName)
        ).pipe(
            startWith(0),
            pairwise(),
            tap((prevCur) => uploadCallback? uploadCallback(prevCur) : null),
        ).toPromise()

        await this.mediaService.finishUpload(response.media).toPromise();
        this.mediaService.mediaUpdated$.next();

        return await this.mediaService.get(response.media.id).toPromise();
    }

    public vistaCreateUserTabConfig$(): Observable<SidebarTabsConfig> {
        return this.userService.getCurrentUser().pipe(
            map(user => user.roles),
            map(roles => {
                let allowedTabs: KnownSidebarTab[] | undefined = undefined;
                if (!(roles.includes('ROLE_SUPER_ADMIN') || roles.includes('ROLE_MARKETING'))) {
                    allowedTabs = ['designs', 'text', 'library'];
                }
                return allowedTabs;
            }),
            map(allowedTabs => {
                if (!allowedTabs) {
                    return undefined;
                }
                const config: SidebarTabsConfig = {};
                for (const sidebarTab of allowedTabs) {
                    config[sidebarTab] = true;
                }
                return config;
            }),
        )
    }

    public vistaCreateUserEditCategories$(): Observable<VistaCreateCategory[]> {
        return this.userService.getCurrentUser().pipe(
            map(user => user.roles),
            map(roles =>
                roles.includes('ROLE_SUPER_ADMIN') || roles.includes('ROLE_MARKETING')
                    ? this.MARKETING_CATEGORIES
                    : this.USER_CATEGORIES
            ),
        )
    }
}
