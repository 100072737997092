// test server configuration

export const environment = {
    apiPrefix: '/api',
    appName: "VolkerWessels Beeldbank",
    appNameShort: "Beeldbank",
    azureClientId: `ed0eeb28-c2cc-47f3-93bd-d5a612e8a617`,
    azureLogoutUri: `https://beeldbank.test.recognize.hosting`,
    azureReadApi: `api://ed0eeb28-c2cc-47f3-93bd-d5a612e8a617/Read`,
    azureRedirectUri: `https://beeldbank.test.recognize.hosting`,
    loginDescription: "Klik hieronder op inloggen om in te loggen met uw VolkerWessels account.",
    maxDocumentSizeMB: 20,
    missingRoles: "U heeft nog geen toegang tot de Beeldbank. Neem contact op met uw beheerder.",
    production: true,
    showLoginShape: false,
    useMock: false,
    applicationScope: 'https://vwtelecom.nl/Read',
    featureToggles: [],
    vistaCreateApiKey: null,
    roleLabels: {
        'ROLE_COMMUNICATION_OFFICER': 'Communication officer',
        'ROLE_ORGANIZATION_ADMIN': 'Organisatie-admin',
        'ROLE_USER_MANAGER': 'Gebruikersbeheerder',
        'ROLE_MARKETING': 'Marketing',
        'ROLE_SUPER_ADMIN': 'IT-admin',
    }
};
