import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Media} from '../../models/media';
import createJustifiedLayout from 'justified-layout';

type JustifiedLayoutItem = number | { width: number; height: number };

export interface MediaGridItem {
    dimensions: {
        width: number;
        height: number;
        top: number;
        left: number;
    };
    mediaAddButton?: {
        title: string;
        description: string;
        btnType: 'button'|'upload';
    };
    mediaItem?: Media;
}

interface JustifiedGrid {
    containerHeight: number;
    boxes: {
        width: number;
        height: number;
        top: number;
        left: number;
    }[];
}

@Component({
    selector: 'app-media-items-grid',
    templateUrl: './media-items-grid.component.html'
})
export class MediaItemsGridComponent implements OnChanges, OnDestroy {
    @ViewChild('mediaGrid', {static: true}) mediaGridElement: ElementRef<HTMLElement>;

    @Input() gridTitle: string;
    @Input() mediaItems: Media[];
    @Input() showAddMedia = false;
    @Input() showMediaTags = true;
    @Input() showMediaInfo = false;
    @Input() showLoadMore = false;
    @Input() addMediaTitle: string;
    @Input() addMediaDescription: string;
    @Input() addMediaBtnType: 'button'|'upload' = 'button';

    @Output() mediaItemSelect = new EventEmitter<Media>();
    @Output() addMediaBtnClick = new EventEmitter();
    @Output() addMediaUpload = new EventEmitter<FileList>();
    @Output() loadMore = new EventEmitter();

    loading = false;
    items: MediaGridItem[] = [];
    subscriptions: Subscription[] = [];
    containerWidthSubject = new Subject<number>();

    calculateGridDebounced = new Subject();

    constructor(
        private elementRef: ElementRef<HTMLElement>
    ) {
        this.subscriptions.push(
            this.containerWidthSubject.asObservable().pipe(
                debounceTime(250),
                distinctUntilChanged()
            ).subscribe(containerWidth => this.calculateGrid(containerWidth))
        );
        this.subscriptions.push(
            this.calculateGridDebounced.pipe(debounceTime(200)).subscribe(() => this.calculateGrid)
        );
    }

    ngOnChanges() {
        this.calculateGrid(this.elementRef.nativeElement.getBoundingClientRect().width);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    onBrokenImage(event: MediaGridItem) {
        this.mediaItems = this.mediaItems.filter(mediaItem => mediaItem !== event.mediaItem);
        this.items = this.items.filter(item => item !== event);
        this.calculateGridDebounced.next();
    }

    @HostListener('window:resize', [])
    private onWindowResize() {
        this.containerWidthSubject.next(this.elementRef.nativeElement.getBoundingClientRect().width);
    }

    private async calculateGrid(containerWidth: number) {
        this.loading = true;

        let justifiedImageList = [];

        if (this.showAddMedia) {
            justifiedImageList.push({
                width: 150,
                height: 100
            });
        }

        if (this.mediaItems && this.mediaItems.length > 0) {
            const mediaItemDimensions = await Promise.all(this.mediaItems.map(item => {
                return this.getImageDimension(item);
            }));

            justifiedImageList = [].concat(justifiedImageList, mediaItemDimensions);
        }

        if (justifiedImageList.length > 0) {
            const justified = createJustifiedLayout(justifiedImageList, {
                containerWidth,
                containerPadding: 0
            });

            this.items = justified.boxes.map((item, index) => {
                if (this.showAddMedia) {
                    if (index === 0) {
                        return {
                            dimensions: item,
                            mediaAddButton: {
                                title: this.addMediaTitle,
                                description: this.addMediaDescription,
                                btnType: this.addMediaBtnType
                            }
                        };
                    } else {
                        return {
                            dimensions: item,
                            mediaItem: this.mediaItems[index - 1]
                        };
                    }
                } else {
                    return {
                        dimensions: item,
                        mediaItem: this.mediaItems[index]
                    };
                }
            });

            this.elementRef.nativeElement.style.setProperty('--grid-height', `${justified.containerHeight}px`);
        } else {
            this.items = [];
            this.elementRef.nativeElement.style.setProperty('--grid-height', '0');
        }

        this.loading = false;
    }

    private getImageDimension(item: Media): Promise<JustifiedLayoutItem> {
        return new Promise((resolve) => {
            if (item.width && item.height) {
                resolve({
                    width: item.width,
                    height: item.height
                });
            } else {
                console.warn('Image without width / height', item);
                resolve(1);
            }
        });
    }
}
