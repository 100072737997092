import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ProjectService} from '../../services/project.service';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, combineLatest, defer, merge, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ProjectAlbumService} from "../../services/project-album.service";
import {ProjectAlbum} from "../../models/project-album";
import {UserService} from "../../services/user.service";

@Component({
    selector: 'app-invite-external-user',
    templateUrl: './invite-external-user.component.html'
})
export class InviteExternalUserComponent {
    durationChoices: { value: number, label: string }[] = [
        {value: 60 * 60 * 24, label: '1 dag'},
        {value: 60 * 60 * 24 * 3, label: '3 dagen'},
        {value: 60 * 60 * 24 * 7, label: '1 week'},
        {value: 60 * 60 * 24 * 14, label: '2 weken'},
        {value: 60 * 60 * 24 * 21, label: '3 weken'},
        {value: 60 * 60 * 24 * 28, label: '4 weken'}
    ];

    inviteUserForm: FormGroup = new FormGroup({
        externalUserEmail: new FormControl(null, Validators.email),
        permissionToExternalUser: new FormControl('Download'),
        validSeconds: new FormControl(this.durationChoices[0].value),
        mailContent: new FormControl(''),
    });

    inviteExpireDate$ = merge<number, number>(
        this.inviteUserForm.get('validSeconds').valueChanges,
        defer(() => of(this.inviteUserForm.value.validSeconds))
    ).pipe(
        map(value => new Date(Date.now() + (value * 1000)))
    );

    refresh$ = new BehaviorSubject<void>(null);

    album$ = this.refresh$.pipe(
        switchMap(() => this.projectAlbumService.getAlbum(this.modalConfig.data.albumId.toString()))
    );

    canCreateShareLink$ = combineLatest([
        this.projectService.getPermissionDetails(this.modalConfig.data.projectId, this.modalConfig.data.albumId),
        this.userService.getEnabledRoles()
    ]).pipe(
        map(([permissionDetails, roles]) => {
            return permissionDetails.projectResponsible
                || roles.includes('ROLE_SUPER_ADMIN')
                || roles.includes('ROLE_ORGANIZATION_ADMIN');
        })
    )


    loading = false;
    creatingSharedLink = false;

    constructor(
        public modalConfig: VwuiModalConfig<{ projectId?: number, albumId?: number }>,
        public modal: VwuiModalRef,
        private projectService: ProjectService,
        private projectAlbumService: ProjectAlbumService,
        private toast: ToastrService,
        @Inject('UserService') private userService: UserService,
    ) {
    }

    inviteExternalUser() {
        if (!this.inviteUserForm.valid) {
            this.toast.error('Vul eerst alle velden in alvorens het formulier te versturen.');
            return;
        }

        this.loading = true;
        this.projectService.inviteUser({
            ...this.inviteUserForm.value, ...this.modalConfig.data
        }).subscribe(data => {
            this.loading = false;
            this.toast.success('Verstuurd');

            this.modal.close(data);
        }, err => {
            this.loading = false;

            console.error(err + 'Error occured while invite external user');
            this.toast.error('Versturen mislukt');
        });

    }

    async getShareLink(album: ProjectAlbum) {
        if (!this.modalConfig.data.albumId) {
            return;
        }

        try {
            this.creatingSharedLink = true;

            const sharedUrl = await this.projectAlbumService.getShareLink(album.id).toPromise();
            await navigator.clipboard.writeText(sharedUrl);

            this.creatingSharedLink = false;
            if (!album.sharedUrl) {
                this.refresh$.next();
            }

            this.toast.success('Link gegenereerd en gekopieerd naar klembord');
        } catch (e) {
            this.toast.error(`Fout bij het ${album.sharedUrl ? 'kopiëren' : 'genereren'} van de deelbare link`);
        }
    }

    async removeSharedLink() {
        if (!this.modalConfig.data.albumId) {
            return;
        }

        try {
            await this.projectAlbumService.removeSharedLink(this.modalConfig.data.albumId).toPromise();
            this.refresh$.next();
            this.toast.success('Link opgeheven');
        } catch (e) {
            this.toast.error('Fout bij het opheven van de link');
        }
    }

}
