import {Component, EventEmitter, Input, Output, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html'
})
export class ProgressBarComponent implements OnChanges{
  @Input() showProgressBar: boolean;
  @Input() imageProgressBarCount: number;
  @Input() pbTotalCount: number;
  @Input() fileName: string;
  @Input() totalSize: number;
  @Input() showCancelButton = true;
  @Output() cancel = new EventEmitter<string>();

  loaderId = Math.random().toString(36).substring(2, 15); // random string

  @Input() uploadProgress: number = 0;
  public eta: number = null;

  private lastReceivedProgress: number;
  private lastKnownUploadProgress: number;

  calculateETA(value: number) {
      if (this.lastKnownUploadProgress == null || Math.abs(value - this.lastKnownUploadProgress) > 0) {
          const difference = Math.abs(value - this.lastKnownUploadProgress);

          this.lastKnownUploadProgress = value;

          if (this.uploadProgress === 100 || this.uploadProgress === 0) {
              this.eta = null;
          } else if (this.lastReceivedProgress != null) {
              const elapsedTime = Date.now() - this.lastReceivedProgress;
              const timePerPercent = elapsedTime / difference;

              this.eta = ((100 - this.uploadProgress) * timePerPercent) / 1000;
          }

          this.lastReceivedProgress = Date.now();
      }
  }

  constructor() { }

  cancelProgressImage() {
    this.cancel.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes['uploadProgress'] !== undefined) {
          this.calculateETA(changes['uploadProgress'].currentValue);
      }
  }
}
