import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {environment} from "../../../environments/environment";
import {FeatureToggle} from "../models/feature-toggle";

@Directive({
    selector: '[appHasFeatureToggle]'
})
export class HasFeatureToggleDirective implements OnInit {
    @Input('appHasFeatureToggle') featureTogglename: FeatureToggle | FeatureToggle[];

    constructor(
        protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef
    ) {
    }

    ngOnInit(): void {
        let isEnabled = true;

        if (this.featureTogglename !== null && this.featureTogglename !== undefined) {
            if (!Array.isArray(this.featureTogglename)) {
                this.featureTogglename = [this.featureTogglename];
            }

            isEnabled = this.featureTogglename.some(featureToggle => {
                return environment.featureToggles.includes(featureToggle);
            });
        }

        if (isEnabled) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
