import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import {ExternalUserService} from '../services/external-user.service';

@Injectable()
export class ExternalUserGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private externalUserService: ExternalUserService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.params.key) {
            this.externalUserService.storeShareLinkKey(route.params.key);

            if (this.externalUserService.getExternalToken()) {
                this.externalUserService.clearExternalUserSession();
            }

            return true;
        } else {

            if (route.fragment) {
                this.externalUserService.storeExternalUserSession(route.fragment);

                if (this.externalUserService.hasShareLinkKey()) {
                    this.externalUserService.clearShareLinkKey();
                }
            }

            if (!this.externalUserService.isExternalUser()) {
                return false;
            } else {
                if (route.data.requiresDownloadPermission && !this.externalUserService.hasDownloadPermission()) {
                    return this.router.createUrlTree(['external', route.params.projectId, 'upload'], {
                        fragment: route.fragment
                    });
                }

                return true;
            }
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(childRoute, state);
    }
}
