import {Component, OnInit, OnDestroy} from '@angular/core';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {applicationAuthScopes} from "../../../app.module";
import {filterMsalAccounts} from "../../utils/filter-msal.accounts";

@Component({
    selector: 'app-ad-login',
    templateUrl: './ad-login.component.html'
})
export class AdLoginComponent implements OnInit, OnDestroy {
    public environment = environment;
    private subscription: Subscription;

    constructor(
        private msalBroadcastService: MsalBroadcastService,
        private authService: MsalService,
        private router: Router
    ) {
    }

    async ngOnInit() {
        if (filterMsalAccounts(this.authService.instance.getAllAccounts())[0]) {
            await this.router.navigate(['']);
        }
    }

    async login() {
        await this.authService.instance.loginRedirect({scopes: applicationAuthScopes, prompt: 'select_account'});
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
