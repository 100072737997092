import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MediaGridItem} from '../media-items-grid/media-items-grid.component';
import {Media} from '../../models/media';
import {StateChange} from 'ng-lazyload-image';
import {MediaService} from '../../services/media.service';

@Component({
    selector: 'app-media-item',
    templateUrl: './media-item.component.html'
})
export class MediaItemComponent implements OnInit {
    @Input() item: MediaGridItem;
    @Input() showMediaTags = true;
    @Input() showMediaInfo = false;

    @Output() addMediaUpload = new EventEmitter<FileList>();
    @Output() addMediaBtnClick = new EventEmitter();
    @Output() mediaItemSelect = new EventEmitter<Media>();
    @Output() imageBroken = new EventEmitter<MediaGridItem>();

    imageLoaded = false;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private cd: ChangeDetectorRef,
        private mediaService: MediaService
    ) {}

    ngOnInit(): void {
        if (this.item) {
            const {width, height, left, top} = this.item.dimensions;

            this.elementRef.nativeElement.style.setProperty('--width', `${width}px`);
            this.elementRef.nativeElement.style.setProperty('--height', `${height}px`);
            this.elementRef.nativeElement.style.setProperty('--translate-y', `${top}px`);
            this.elementRef.nativeElement.style.setProperty('--translate-x', `${left}px`);
        }
    }

    resetFileInput({target}: Event) {
        if (target) {
            (target as HTMLInputElement).value = null;
        }
    }

    onFileChange({target}: Event) {
        if (target) {
            const input = target as HTMLInputElement;
            this.addMediaUpload.emit(input.files);
        }
    }

    onImageLoad(event: StateChange) {
        if (event.reason === 'loading-succeeded') {
            this.imageLoaded = true;

            this.cd.detectChanges();
        } else if (event.reason === 'loading-failed') {
            console.log('load failed', event);
            this.mediaService.fixMissingThumbnail(this.item.mediaItem).toPromise().then(() => {
                this.item.mediaItem.url = this.item.mediaItem.url + '#invalidate';
            }, reason => {
                this.imageBroken.emit(this.item);
                console.error('Marked missing source image as Rejected', reason);
            });
        }
    }
}
