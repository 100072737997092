<div class="mb-3">
    <h4>
        <ng-content select="[title]"></ng-content>
    </h4>
    <div class="media-grid__holder" *ngIf="results === null">
        <div *ngFor="let i of loadingPlaceholders">
            <app-media-grid-item-placeholder></app-media-grid-item-placeholder>
        </div>
    </div>
    <div class="media-grid__holder" *ngIf="results !== null && results?.length > 0">
        <div *ngFor="let result of results">
            <ng-container [ngSwitch]="type">
                <app-media-grid-project-item *ngSwitchCase="'projects'" [item]="result" (click)="onProjectClick(result)">
                </app-media-grid-project-item>
                <app-media-grid-org-item *ngSwitchCase="'organizations'" [organization]="result" (click)="onOrganizationClick(result)">
                </app-media-grid-org-item>
            </ng-container>
        </div>
    </div>
    <vwui-button *ngIf="hasMoreItems" icon="chevrons-down" class="d-flex justify-content-center" (click)="loadMore.emit()">Laad meer {{ type === 'projects' ? 'projecten' : 'organisaties' }}</vwui-button>
</div>
