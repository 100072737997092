import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {defaultDateRangePickerOptions} from '../../utils/datepicker';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ChangeFn} from '../file-type-filter/file-type-filter.component';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            multi: true,
            useExisting: forwardRef(() => DateRangePickerComponent),
            provide: NG_VALUE_ACCESSOR,
        }]
})
export class DateRangePickerComponent implements ControlValueAccessor{
    @Input() placeholder: string = '';

    control = new FormControl('');
    dateRangeFLatpickerOptions = defaultDateRangePickerOptions;

    public onChange: ChangeFn = () => null;
    public onTouched: ChangeFn = () => null;


    constructor() {
        this.control.valueChanges.pipe(
            filter(value => value === '' || value.split(" - ").length === 2) // only when no or 2 dates chosen
        ).subscribe(value => {
                this.onTouched(value);
                this.onChange(value);
        });
    }

    public registerOnChange(fn: ChangeFn): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: ChangeFn): void {
        this.onTouched = fn;
    }
    writeValue(value: any): void {
        this.control.patchValue(value);
    }
}
