import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {ExternalUserService} from '../../services/external-user.service';
import {MsalInterceptor} from '@azure/msal-angular';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private externalUserService: ExternalUserService,
        private msal: MsalInterceptor) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.externalUserService.hasShareLinkKey()) {
            return next.handle(request.clone({
                setHeaders: {
                    Authorization: `UUID ${(this.externalUserService.getShareLinkKey())}`
                }
            }));
        } else if (this.externalUserService.isExternalUser()) {
            return next.handle(request.clone({
                setHeaders: {
                    Authorization: `Bearer ${(this.externalUserService.getExternalToken())}`
                }
            }));
        } else if (request.url.startsWith(environment.apiPrefix)) {
            return this.msal.intercept(request, next);
        } else {
            return next.handle(request);
        }
    }
}

