import {Component, forwardRef, Inject, Input} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormBuilder } from '@angular/forms';
import { EntitySelectComponent } from './item-select.component';
import { Observable, of } from 'rxjs';
import { PageResponse } from '../../models/page-response';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import {Association} from "../../models/association";
import {AssociationService} from "../../services/association.service";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-association-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AssociationSelectComponent),
            multi: true
        }
    ]
})
export class AssociationSelectComponent extends EntitySelectComponent<Association, {}> {
    constructor(
        private associationService: AssociationService,
        fb: FormBuilder
    ) {
        super(fb);
        this.placeholder = `Selecteer een ${environment.appNameShort === 'InFrame' ? 'kantoorlocatie' : 'bedrijfsnaam'}`;
    }
    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: Association): string {
        return item.name;
    }

    loadMore(page: number, filter: {term: string}): Observable<PageResponse<Association>> {
        return this.associationService.search(filter.term, page);
    }

}
