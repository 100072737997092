import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Project} from '../../models/project';
import {shareReplay, startWith, switchMap, take} from 'rxjs/operators';
import {ExternalUserService} from '../../services/external-user.service';
import {ProjectAlbumService} from '../../services/project-album.service';
import {MsalService} from '@azure/msal-angular';
import {ProjectService} from '../../services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {BsModalService} from 'ngx-bootstrap/modal';
import {CreateProjectComponent} from '../../components/create-project/create-project.component';
import {DeleteProjectComponent} from '../../components/delete-project/delete-project.component';
import {InviteExternalUserComponent} from '../../components/invite-external-user/invite-external-user.component';
import {ProjectAlbumEditComponent} from '../../components/project-album-edit/project-album-edit.component';
import {MediaProcessingComponent} from '../../components/media-processing/media-processing.component';
import {MediaService} from '../../services/media.service';
import { PermissionService } from '../../services/permission.service';
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-project-detail',
    templateUrl: './project-detail.component.html'
})
export class ProjectDetailComponent {
    filterForm = new FormGroup({
        search: new FormControl(''),
        fileTypes: new FormControl([]),
        orientation: new FormControl(null),
        exifCreatedBeteen: new FormControl('')
    });

    projectUpdated = new BehaviorSubject<void>(null);
    project$: Observable<Project> = combineLatest([this.route.params, this.projectUpdated]).pipe(
        switchMap(([params]) => this.projectService.getProjectDetail(params.projectId)),
        shareReplay(1)
    );
    canUpload$  = this.project$.pipe(
        switchMap(project => this.permissionService.hasProjectPermission(project.id, 'upload')),
    );
    refresh$ = new BehaviorSubject<void>(null);
    refreshAlbums$ = new BehaviorSubject<void>(null);
    mediaDetails$ = combineLatest([
        this.route.params,
        this.filterForm.valueChanges.pipe(startWith<any, any>(this.filterForm.value)),
        this.mediaDetailsService.mediaUpdated$,
        this.refresh$,
    ]).pipe(
        switchMap(([routeParams, filter]) => {
            // TODO: Implement pagination / infinite scroll
            return this.mediaDetailsService.getList(0, 1000, {
                tagName: filter.search,
                orientation: filter.orientation,
                fileName: filter.search,
                fileTypes: filter.fileTypes,
                project: routeParams.projectId,
                exifCreatedBeteen: filter.exifCreatedBeteen
            });
        }),
        shareReplay(1)
    );
    albums$ = combineLatest([
        this.route.params,
        this.filterForm.valueChanges.pipe(startWith<any, any>(this.filterForm.value)),
        this.mediaDetailsService.mediaUpdated$,
        this.refreshAlbums$
    ]).pipe(
        switchMap(([params, filter]) => this.projectService.getAlbums(params.projectId, filter.tagName, 0)),
        shareReplay(1)
    );

    albumsExpanded = false;

    constructor(
        private mediaDetailsService: MediaService,
        private externalUserService: ExternalUserService,
        private projectAlbumService: ProjectAlbumService,
        private msalService: MsalService,
        private projectService: ProjectService,
        private route: ActivatedRoute,
        private modalService: VwuiModalService,
        private bsModalService: BsModalService,
        private permissionService: PermissionService,
        private router: Router,
        private toast: ToastrService
    ) {
    }

    editProject() {
        const modalRef = this.modalService.open(CreateProjectComponent, {
            data: {
                projectId: this.route.snapshot.params.projectId
            }
        });

        modalRef.afterClosed.subscribe(result => {
            if (result?.message === 'SUCCESS') {
                this.projectUpdated.next();
                this.mediaDetailsService.mediaUpdated$.next();
            }
        });
    }

    deleteProject() {
        const modalRef = this.modalService.open(DeleteProjectComponent, {
            data: {
                projectId: this.route.snapshot.params.projectId
            }
        });

        modalRef.afterClosed.subscribe(result => {
            if (result.message === 'SUCCESS') {
                this.router.navigate(['/display-media']);
            }
        });
    }

    openInviteExternalUserModal() {
        this.modalService.open(InviteExternalUserComponent, {
            data: {
                projectId: this.route.snapshot.params.projectId
            },
            modalClass: 'preview-modal__modal'
        });
    }

    async openCreateAlbumModal() {
        const project = await this.project$.pipe(take(1)).toPromise();
        const modalRef = this.modalService.open(ProjectAlbumEditComponent, {data: {project}});
        modalRef.afterClosed.subscribe((result) => {
            if (result) {
                this.router.navigate(['albums', result.id], {relativeTo: this.route});
            }
        });
    }

    openMediaProcessingModal() {
        this.project$.pipe(take(1)).subscribe(project => {
            this.bsModalService.show(MediaProcessingComponent, {
                class: 'fullscreen-modal',
                initialState: {
                    type: 'upload',
                    project
                }
            });
        });
    }

    async copySharedLink(albumId: number) {
        try {
            const sharedUrl = await this.projectAlbumService.getShareLink(albumId).toPromise();
            await navigator.clipboard.writeText(sharedUrl);
            this.toast.success('Link gekopieerd naar klembord');
        } catch (e) {
            this.toast.error('Fout bij het kopiëren van de link');
        }
    }

    async removeSharedLink(albumId: number) {
        try {
            await this.projectAlbumService.removeSharedLink(albumId).toPromise();
            this.toast.success('Link opgeheven');
            this.refreshAlbums$.next()
        } catch (e) {
            this.toast.error('Fout bij het opheven van de link');
        }
    }
}
