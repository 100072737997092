import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'timeLeft'
})
export class TimeLeftPipe implements PipeTransform {
    transform(seconds: number): string {
        seconds = Math.floor(seconds);

        if (seconds >= 60 * 60) {
            return `${Math.floor(seconds / (60 * 60))} uur`;
        } else if (seconds >= 60 && seconds < 120) {
            return `1 minuut`;
        } else if (seconds >= 120) {
            return `${Math.floor(seconds / (60))} minuten`;
        } else if (seconds !== 1) {
            return `${seconds} seconden`;
        } else {
            return `1 seconde`;
        }
    }
}
