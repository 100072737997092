import {Directive, EmbeddedViewRef, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {ActivatedRoute} from '@angular/router';
import {of, Subscription, throwError} from 'rxjs';
import {ProjectService} from '../services/project.service';
import {NgIfContext} from '@angular/common';
import {AccountInfo} from '@azure/msal-browser';
import {filterMsalAccounts} from '../utils/filter-msal.accounts';
import {UserService} from '../services/user.service';
import {catchError, mapTo} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {PermissionService} from '../services/permission.service';

@Directive({
    selector: '[appHasProjectResponsibleAccess]'
})
export class HasProjectResponsibleAccessDirective implements OnInit, OnDestroy {
    user: AccountInfo = filterMsalAccounts(this.authService.instance.getAllAccounts())[0];
    sub: Subscription;

    @Input() appHasProjectResponsibleAccess: TemplateRef<any> = this.templateRef;
    @Input() appHasProjectResponsibleAccessElse: TemplateRef<any>;

    thenViewRef: EmbeddedViewRef<NgIfContext> | null = null;
    elseViewRef: EmbeddedViewRef<NgIfContext> | null = null;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private authService: MsalService,
        private route: ActivatedRoute,
        private projectService: ProjectService,
        private permissionService: PermissionService,
        @Inject('UserService') private userService: UserService
    ) {
    }

    @Input()
    set appHasProjectResponsibleAccessParams(params: { projectId: number }) {
        if (params && params.projectId) {
            this.renderViewWhenCurrentUserIsProjectResponsible(params.projectId);
        }
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            if (params.projectId) {
                this.renderViewWhenCurrentUserIsProjectResponsible(params.projectId);
            }
        });
    }

    async renderViewWhenCurrentUserIsProjectResponsible(projectId) {
        try {
            if (await this.permissionService.hasProjectPermission(projectId, 'projectResponsible')) {
                if (!this.thenViewRef) {
                    this.viewContainerRef.clear();
                    this.elseViewRef = null;
                    if (this.appHasProjectResponsibleAccess) {
                        this.thenViewRef = this.viewContainerRef.createEmbeddedView(this.appHasProjectResponsibleAccess);
                    }
                }
            } else {
                if (!this.elseViewRef) {
                    this.viewContainerRef.clear();
                    this.thenViewRef = null;
                    if (this.appHasProjectResponsibleAccessElse) {
                        this.elseViewRef = this.viewContainerRef.createEmbeddedView(this.appHasProjectResponsibleAccessElse);
                    }
                }
            }
        } catch (error) {
            console.error('Fail to get the records', error);
            this.viewContainerRef.clear();
        }
    }

    // Clear the subscription on destroy
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}

