import {Component, Inject} from '@angular/core';
import {NgVwuiTableConfig, VwuiModalService} from '@recognizebv/vwui-angular';
import {Router} from '@angular/router';
import {Tag} from '../../models/tag';
import {TagService} from '../../services/tag.service';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, combineLatest, defer, merge, of} from 'rxjs';
import {mapTo, switchMap, take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';
import {Paginator} from '../../components/utils/paginator';
import {TagCreateModalComponent} from '../../components/tag-create-modal/tag-create-modal.component';

@Component({
    selector: 'app-tags-list',
    templateUrl: './tags-list.component.html'
})
export class TagsListComponent {
    public environment = environment;

    tableConfig: NgVwuiTableConfig = {
        title: 'Tags',
        selectable: false,
        clickable: true,
        indexKey: 'id',
        compactable: true,
        columns: [
            {header: 'Tag', itemKey: 'name'},
            {header: 'Aangemaakt op', itemKey: 'createdAt'},
            {header: '', itemKey: 'delete'},
        ],
        menuOptions: []
    };

    searchControl = new FormControl('');
    refresh$ = new BehaviorSubject<void>(null);

    paginator$ = combineLatest([
        this.refresh$,
        merge(this.searchControl.valueChanges, defer(() => of(this.searchControl.value))),
    ]).pipe(
        switchMap(([_, search]) => {
            const paginator = new Paginator(page => {
                return this.tagService.getTagsPage(page, search);
            });

            return paginator.content$.pipe(
                mapTo(paginator)
            );
        })
    );

    constructor(
        private router: Router,
        @Inject('TagService') private tagService: TagService,
        private modalService: VwuiModalService,
        private toast: ToastrService
    ) {
    }

    onTagClick(tag: Tag) {
        this.router.navigate(['/manage/tags', tag.id]);
    }

    async openCreateTagModal() {
        const modalRef = this.modalService.open(TagCreateModalComponent);
        await modalRef.afterClosed.pipe(take(1)).toPromise();

        this.refresh$.next(null);
    }

    async deleteTag(tag: Tag) {
        try {
            await this.tagService.deleteTag(tag).toPromise();
        } catch (ex) {
            if (ex.error.message === 'Tag is still used by media!') {
                Object.keys(ex.error.errors).forEach(key => {
                    const fileName = key;
                    const projectName = ex.error.errors[key];
                    this.toast.error(`Tag is gekoppeld aan ${fileName} in project ${projectName}`, null, {
                        timeOut: 5000,
                        extendedTimeOut: 5000
                    });
                });
            } else {
                this.toast.error(`Onverwachte fout`);
                console.error('Error while deleting tag!', ex);
            }
        }
        this.refresh$.next(null);
    }
}
