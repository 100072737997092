<h4 *ngIf="gridTitle">{{gridTitle}}</h4>
<div class="media-items-grid" [class.media-items-grid--loading]="loading">
    <app-media-item
        *ngFor="let item of items"
        [item]="item"
        [showMediaTags]="showMediaTags"
        [showMediaInfo]="showMediaInfo"
        (mediaItemSelect)="mediaItemSelect.emit($event)"
        (addMediaUpload)="addMediaUpload.emit($event)"
        (addMediaBtnClick)="addMediaBtnClick.emit()"
        (imageBroken)="onBrokenImage($event)"
    ></app-media-item>
</div>

<div class="media-items-load-more" *ngIf="!loading">
    <vwui-button *ngIf="showLoadMore" icon="chevrons-down" (click)="loadMore.next()">Laad meer media</vwui-button>
</div>

<div class="media-items-spinner" [class.visible]="loading" [class.invisible]="!loading">
    <div class="media-items-spinner__content">
        <vwui-spinner></vwui-spinner>
    </div>
</div>
