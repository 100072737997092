<vwui-checkbox
    class="select-all-checkbox"
    (vwuiChecked)="selectAllMedia($event)"
    [checked]="allChecked"
    [indeterminate]="isIndeterminate"
>
    <span class="select-all-checkbox__text">Alle bestanden</span>
</vwui-checkbox>

<div class="image-modal-thumbnail-carousel">
    <owl-carousel-o #thumbnailCarousel
                    class="preview-modal__carousel"
                    [options]="carouselOptions"
    >
        <ng-container *ngFor="let image of media">
            <ng-template carouselSlide [width]="80" [dotContent]="image.id.toString()">
                <div class="preview-modal__content__bottom__thumbnail">
                    <div class="preview-modal__content__bottom__thumbnail__owl"
                         [class.preview-modal__content__bottom__thumbnail__owl--is-selected]="image.isSelected"
                         (click)="mediaClick.emit(image)">
                        <vwui-checkbox [checked]="image.isSelected" (change)="mediaSelect.emit(image)"
                                       class="preview-modal__content__bottom__thumbnail__checkbox beel-card__checkbox">
                        </vwui-checkbox>

                        <img class="preview-modal__content__bottom__thumbnail__image" [src]="image?.url" alt="">
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>

    <div class="thumbnail-carousel-nav" *ngIf="thumbnailCarousel">
        <button
            class="thumbnail-carousel-nav-btn thumbnail-carousel-nav-btn--prev"
            [disabled]="!prevBtnVisible"
            [class.thumbnail-carousel-nav-btn--visible]="prevBtnVisible"
            (click)="thumbnailCarousel.prev()"
        >
            <vwui-icon iconName="vwui-chevron-left"></vwui-icon>
        </button>
        <button
            class="thumbnail-carousel-nav-btn thumbnail-carousel-nav-btn--next"
            [disabled]="!nextBtnVisible"
            [class.thumbnail-carousel-nav-btn--visible]="nextBtnVisible"
            (click)="thumbnailCarousel.next()"
        >
            <vwui-icon iconName="vwui-chevron-right"></vwui-icon>
        </button>
    </div>
</div>
