<vwui-modal-header>
    Projectverantwoordelijke wijzigen
</vwui-modal-header>
<vwui-modal-body>
    <ng-container *ngIf="hasMultipleProjectResponsible">
        Enkele geselecteerde projecten hebben meerdere projectverantwoordelijken.
        In dit geval zal alleen <span class="text-primary font-weight-bold">{{ user.name }}</span> worden vervangen.
        De overige gebruikers blijven projectverantwoordelijke.
    </ng-container>
    <form [formGroup]="form">
        <vwui-form-group class="mt-3" label="Vervangen door">
            <app-user-select formControlName="user" bindLabel="name"></app-user-select>
        </vwui-form-group>
    </form>
</vwui-modal-body>
<vwui-modal-footer>
    <vwui-button class="is-light" (click)="close()">Annuleren</vwui-button>
    <vwui-button class="is-primary" [loading]="loading" (click)="save()">Opslaan</vwui-button>
</vwui-modal-footer>
