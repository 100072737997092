import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {VwuiModalRef} from '@recognizebv/vwui-angular';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
    selector: 'app-user-create-modal',
    templateUrl: './user-create-modal.component.html',
})
export class UserCreateModalComponent {
    loading = false;
    form = new FormGroup({
        username: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required)
    });

    constructor(
        public modal: VwuiModalRef,
        @Inject('UserService') private userService: UserService,
        private toastr: ToastrService,
        private router: Router
    ) {
    }

    async saveUser() {
        if (!this.form.valid) {
            return;
        }

        try {
            this.loading = true;
            const createdUser = await this.userService.createUser(this.form.value).toPromise();
            await this.router.navigate(['/manage/users', createdUser.id]);
            this.modal.close();
        } catch (e) {
            this.toastr.error('Gebruiker aanmaken mislukt');
            console.error('User create failed', e);
        } finally {
            this.loading = false;
        }
    }
}
