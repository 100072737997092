<vwui-modal-header>
    Gebruiker aanmaken
</vwui-modal-header>
<vwui-modal-body>
    <form [formGroup]="form" (ngSubmit)="saveUser()">
        <vwui-form-group label="E-mailadres" inputId="username">
            <vwui-input [autofocus]="true" id="username" name="name" type="email" formControlName="username"></vwui-input>
        </vwui-form-group>
        <vwui-form-group label="Naam" inputId="name">
            <vwui-input id="name" name="name" type="text" formControlName="name"></vwui-input>
        </vwui-form-group>
    </form>
</vwui-modal-body>
<vwui-modal-footer>
    <vwui-button class="is-light" (click)="modal.close()">Annuleren</vwui-button>
    <vwui-button class="is-primary" (click)="saveUser()" [loading]="loading">Opslaan</vwui-button>
</vwui-modal-footer>
