import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {shareReplay, switchMap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {MediaService} from "../../../services/media.service";

@Component({
    selector: 'app-project-embed-album-file',
    templateUrl: './project-embed-album-file.component.html'
})
export class ProjectEmbedAlbumFileComponent  {

    video$ = combineLatest([
        this.route.params,
    ]).pipe(
        switchMap(([routeParams]) => {
            return this.mediaDetailsService.fetchMedia(routeParams.fileId)
        }),
        shareReplay(1)
    );


    constructor(
        private mediaDetailsService: MediaService,
        private route: ActivatedRoute,
    ) {
    }
}
