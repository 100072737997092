import {Injectable} from '@angular/core';
import {ExternalUserService} from './external-user.service';
import {MsalService} from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private externalUserService: ExternalUserService,
        private msalService: MsalService
    ) {
    }

    getUserName(): string {
        if (this.externalUserService.isExternalUser()) {
            const jwt = this.externalUserService.getExternalToken();

            const decoded = JSON.parse(atob(jwt.split('.')[1]));
            return decoded.sub;
        } else {
            const account = this.msalService.instance.getAllAccounts()[0];
            if (!account) {
                throw new Error('No account found');
            }

            return account.username;
        }
    }
}
