import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Media} from '../../models/media';
import {Tag} from '../../models/tag';

@Component({
    selector: 'app-media-item-tags',
    templateUrl: './media-item-tags.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaItemTagsComponent implements OnInit {
    @Input() item: Media;

    tags: Tag[] = [];
    moreTagsLabel: string;

    constructor() {
    }

    ngOnInit(): void {
        const isPortrait = this.item.width < this.item.height;
        const totalTagsVisible = isPortrait ? 1 : 4;
        const tagCount = this.item.tags.length;

        if (tagCount > totalTagsVisible) {
            const moreTagsCount = this.item.tags.length - totalTagsVisible;

            this.moreTagsLabel = `+ ${moreTagsCount} ${moreTagsCount === 1 ? 'tag' : 'tags'}`;
            this.tags = [...this.item.tags.slice(0, totalTagsVisible)];
        } else {
            this.tags = [...this.item.tags];
        }
    }
}
