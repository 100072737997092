<ng-container>
    <vwui-modal-header>
        <div class="create-project__top">
            Verwijder album
        </div>
    </vwui-modal-header>
    <vwui-modal-body>
        <p>Weet je zeker dat je dit album wilt verwijderen?</p>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modal.close()">Annuleren</vwui-button>
        <vwui-button class="is-secondary" (click)="confirm()" [loading]="loading">Verwijderen</vwui-button>
    </vwui-modal-footer>
</ng-container>
