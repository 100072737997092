<div class="media-processing__header">
    <div class="media-processing__title">
        <button class="media-processing__close-btn" (click)="modalRef.hide()">
            <vwui-icon iconName="back" class="media-processing__close-icon"></vwui-icon>
        </button>
        {{ album.project.name }} / {{ album.name }}
        <div class="media-processing__count" *ngIf="albumMediaIds$|async as mediaIds">{{mediaIds.length}}</div>
    </div>
    <div class="media-processing__actions">
        <vwui-button
            *ngIf="albumMediaIds$|async as mediaIds"
            [loading]="saving"
            (click)="save(mediaIds)"
            class="is-secondary"
        >Opslaan</vwui-button>
    </div>
</div>
<div class="media-processing__content" *ngIf="mediaDetails$|async as mediaDetails"
     infiniteScroll
     [infiniteScrollDistance]="2"
     [scrollWindow]="false"
     (scrolled)="loadMore$.next()">
    <app-media-processing-content
        [showProjectName]="false"
        [showUpload]="false"
        [mediaItems]="mediaDetails"
        (itemSelected)="onMediaItemSelected($event)"
    ></app-media-processing-content>
</div>
