import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-action-button',
    templateUrl: './action-button.component.html',
})
export class ActionButtonComponent {
    @Input() iconName: string;
    @Input() active = false;
    @Input() loading = false;
    @Output() tapped = new EventEmitter();

    public didPress() {
        this.tapped.next();
    }
}
