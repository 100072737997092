import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Organization, OrganizationListItem} from '../models/organization';
import {environment} from '../../../environments/environment';
import {PageResponse} from '../models/page-response';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    constructor(private http: HttpClient) {
        this.http = http;
    }

    getList(page: number) {
        const params = new HttpParams().set('page', '' + page);
        return this.http.get<PageResponse<Organization>>(`${environment.apiPrefix}/organizations`, {params});
    }

    getAllList() {
        return this.http.get<OrganizationListItem[]>(`${environment.apiPrefix}/organizations/all`);
    }

    getOne(id: number): Observable<Organization> {
        return this.http.get<Organization>(`${environment.apiPrefix}/organizations/${id}`);
    }

    getChildList(parentId: number, page: number) {
        const params = new HttpParams().set('page', '' + page).set('size', '10');
        return this.http.get<PageResponse<Organization>>(`${environment.apiPrefix}/organizations/${parentId}/children`, {params});
    }

    search(query: string, page: number, size: number = 25) {
        return this.http.get<PageResponse<Organization>>(`${environment.apiPrefix}/organizations/search`, {
            params: {query, page: page.toString(), size: size.toString()}
        });
    }

    searchAll(query: string, excludeOrganizationId: number | undefined, page: number, size: number = 25) {
        let params = new HttpParams({fromObject: {query, page: page.toString(), size: size.toString()}});
        if (excludeOrganizationId) {
            params = params.set('excludeOrganization', '' + excludeOrganizationId);
        }

        return this.http.get<PageResponse<Organization>>(`${environment.apiPrefix}/organizations/search-all`, {params});
    }

    create(organization: Organization) {
        return this.http.post<Organization>(`${environment.apiPrefix}/organizations`, organization);
    }

    update(organization: Organization) {
        return this.http.put<Organization>(`${environment.apiPrefix}/organizations/${organization.id}`, organization);
    }

    delete(organization: Organization) {
        return this.http.delete(`${environment.apiPrefix}/organizations/${organization.id}`);
    }

    exists(name: string, ignoreId: number) {
        const params: {[key: string]: string} = {name};
        if (ignoreId) {
            params.ignoreId = ignoreId.toString();
        }

        return this.http.get<boolean>(`${environment.apiPrefix}/organizations/exists`, {params});
    }
}
