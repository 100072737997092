import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectService} from '../../services/project.service';
import {ToastrService} from 'ngx-toastr';
import {shareReplay, take} from 'rxjs/operators';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {Organization} from '../../models/organization';
import {MsalService} from '@azure/msal-angular';
import {OrganizationService} from '../../services/organization.service';
import {UserService} from '../../services/user.service';
import {Project} from '../../models/project';
import {Observable} from 'rxjs';
import {AccountInfo} from "@azure/msal-browser";
import {filterMsalAccounts} from "../../utils/filter-msal.accounts";


@Component({
    selector: 'app-create-project',
    templateUrl: './create-project.component.html'
})
export class CreateProjectComponent implements OnInit {
    public user: AccountInfo = filterMsalAccounts(this.authService.instance.getAllAccounts())[0];
    createProjectForm: FormGroup;
    projectResponse: Project;
    loading = false;
    orgList$ = this.orgService.getAllList().pipe(shareReplay(1));
    orgDetail$ = this.orgService.getOne(this.modalConfig.data.organization as any);
    projectDetail$: Observable<Project>;

    constructor(
        public modal: VwuiModalRef,
        private modalConfig: VwuiModalConfig<{ organization?: Organization, projectId?: number }>,
        public fb: FormBuilder,
        private projectService: ProjectService,
        private toast: ToastrService,
        private authService: MsalService,
        private orgService: OrganizationService,
        @Inject('UserService') private userService: UserService
    ) {}

    ngOnInit() {
        if (this.modalConfig.data.projectId) {
            this.projectDetail$ = this.projectService.getProjectDetail(
                this.modalConfig.data.projectId
            ).pipe(
                take(1)
            );
        }

        this.createForm();
    }

    private createForm() {
        const fb = this.fb;
        this.createProjectForm = fb.group({
            id: fb.control(null),
            name: fb.control(null, Validators.required),
            visibility: fb.control('Private', Validators.required),
            uploadApprovalRequired: fb.control(false, Validators.required),
            downloadApprovalRequired: fb.control(false, Validators.required),
            organizations: fb.control(null, Validators.required),
            projectResponsible: fb.control(null, Validators.required),
        });
        if (this.modalConfig.data.organization) {
            // Create project
            this.orgDetail$
                .pipe(
                    take(1)
                )
                .subscribe((orgDetails) => {
                    this.createProjectForm.get('organizations').patchValue([
                        orgDetails
                    ]);
                });

            this.userService.getList(0, this.user.username).subscribe(response => {
                const currentUser = response.content.find(item => item.username?.toLowerCase() === this.user.username?.toLowerCase());
                if (currentUser) {
                    this.createProjectForm.patchValue({projectResponsible: [currentUser]});
                }
            });
        } else if (this.modalConfig.data.projectId) {
            // Update project
            this.projectDetail$.pipe(
                take(1)
            ).subscribe(projectDetails => {
                this.projectResponse = projectDetails;
                const OrgList = projectDetails.organizations.map(v => v.organization);
                const userList = projectDetails.projectResponsible.map(v => v.user);
                this.createProjectForm.patchValue({
                    ...projectDetails,
                    organizations: OrgList,
                    projectResponsible: userList,
                });
            });
        }
    }

    saveProject() {
        this.loading = true;
        const formData = {...this.createProjectForm.getRawValue()};
        if (this.modalConfig.data.projectId) {
            const reqOrgList = [];
            const requserList = [];
            // for organization..
            for (const formOrganization of formData.organizations) {
                let isNotAvailable = true;
                for (const responseOrganization of this.projectResponse.organizations) {
                    if (formOrganization.orgId === responseOrganization.organization.id) {
                        isNotAvailable = false;
                        reqOrgList.push(responseOrganization);
                        break;
                    }
                }
                if (isNotAvailable) {
                    const req = {
                        projectOrgId: 0,
                        organization: formOrganization
                    };

                    reqOrgList.push(req);
                }
            }
            // for users...
            for (const formItem of formData.projectResponsible) {
                let isNotAvailable = true;
                for (const responseItem of this.projectResponse.projectResponsible) {
                    if (formItem.id === responseItem.user.id) {
                        isNotAvailable = false;
                        requserList.push(responseItem);
                        break;
                    }
                }
                if (isNotAvailable) {
                    const req = {
                        projectResponsibleId: 0,
                        user: formItem
                    };

                    requserList.push(req);
                }
            }

            formData.organizations = reqOrgList;
            formData.projectResponsible = requserList;
        } else {
            formData.organizations = formData.organizations.map(organization => ({ organization }));
            formData.projectResponsible = formData.projectResponsible.map(user => ({ user }));
        }

        (formData.id === null
            ? this.projectService.createProject(formData)
            : this.projectService.updateProject(formData)
        ).subscribe(data => {
            this.loading = false;
            this.toast.success('Opgeslagen');

            this.modal.close({
                message: 'SUCCESS',
                projectId: data.id
            });
        }, err => {
            this.loading = false;

            console.error(err + 'Error occured while creating project');
            this.toast.error('Opslaan mislukt');
        });
    }
}
