<div class="drag-drop-overlay" [class.drag-drop-overlay--active]="active">
    <div class="drag-drop-overlay__content-holder">
        <div class="drag-drop-overlay__button-wrapper">
            <div class="drag-drop-overlay__button">
                <vwui-icon iconName="arrow-up" class="drag-drop-overlay__button__icon"></vwui-icon>
            </div>

            <div class="drag-drop-overlay__particles">
                <div class="drag-drop-overlay__particle" [style.left.%]="30" [style.animation-delay.ms]="1000"></div>
                <div class="drag-drop-overlay__particle" [style.left.%]="32" [style.animation-delay.ms]="300"></div>
                <div class="drag-drop-overlay__particle" [style.left.%]="38" [style.animation-delay.ms]="450"></div>
                <div class="drag-drop-overlay__particle" [style.left.%]="48" [style.animation-delay.ms]="600"></div>
                <div class="drag-drop-overlay__particle" [style.left.%]="52" [style.animation-delay.ms]="300"></div>
                <div class="drag-drop-overlay__particle" [style.left.%]="60" [style.animation-delay.ms]="800"></div>
                <div class="drag-drop-overlay__particle" [style.left.%]="64" [style.animation-delay.ms]="0"></div>
                <div class="drag-drop-overlay__particle" [style.left.%]="70" [style.animation-delay.ms]="700"></div>
            </div>
        </div>

        <div class="drag-drop-overlay__texts">
            <span class="drag-drop-overlay__texts__title">Drop je bestanden</span>
            <span class="drag-drop-overlay__texts__sub-title">om te uploaden</span>
        </div>
    </div>

    <div class="drag-drop-overlay__zone" (drop)="onDrop($event)" (dragleave)="dragDidLeave($event)" (dragover)="dragDidStart($event)" (dragend)="dragDidLeave($event)">

    </div>
</div>
