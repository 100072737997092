import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, map, shareReplay, startWith, take} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {filteredInfiniteScrollObservable} from '../utils/pagination';
import {Organization} from '../../models/organization';
import {OrganizationService} from '../../services/organization.service';
import {Project} from '../../models/project';
import {CreateProjectComponent} from '../create-project/create-project.component';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {Router} from '@angular/router';
import {ProjectService} from '../../services/project.service';
import {InfiniteScrollPaginator} from '../utils/infinite-scroll-paginator';

export type ContentMode = 'root' | 'search' | 'detail';

export interface ListFilter {
    parentId: number;
    searchValue: string;
}


@Component({
    selector: 'app-project-list-menu',
    templateUrl: './project-list-menu.component.html'
})
export class ProjectListMenuComponent implements OnInit, OnDestroy {
    contentMode: ContentMode = 'root';
    organizationStack: Organization[] = [];

    showLoadMore: boolean;
    showLoadMoreProjects: boolean;

    searchControl = new FormControl();
    subscriptions: Subscription[] = [];

    loadMore$ = new BehaviorSubject<void>(null);

    searchValue$ = this.searchControl.valueChanges.pipe(
        startWith<string, string>(this.searchControl.value),
        debounceTime(500)
    );
    parentId$ = new BehaviorSubject<number>(null);
    listFilter$: Observable<ListFilter> = combineLatest([this.parentId$, this.searchValue$]).pipe(
        map(([parentId, searchValue]) => ({parentId, searchValue}))
    );

    organizationList$ = filteredInfiniteScrollObservable<Organization, ListFilter>(
        this.listFilter$,
        this.loadMore$,
        (page, listFilter) => {
            if (listFilter.searchValue) {
                return this.organizationService.search(listFilter.searchValue, page);
            } else if (listFilter.parentId) {
                return this.organizationService.getChildList(listFilter.parentId, page);
            } else {
                return this.organizationService.getList(page);
            }
        },
        (pageResponse) => {
            this.showLoadMore = !pageResponse.last;
        }
    ).pipe(shareReplay(1));

    projectListPaginator = new InfiniteScrollPaginator<Project, ListFilter>(
        this.listFilter$,
        (page, listFilter) => {
            return this.projectService.getList(page, listFilter.parentId, null, listFilter.searchValue || '');
        },
    );

    constructor(
        private organizationService: OrganizationService,
        private projectService: ProjectService,
        private modalService: VwuiModalService,
        private route: Router,
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.projectService.projectUpdated$.subscribe(() => {
                this.projectListPaginator.refresh();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    onBack() {
        if (this.getContentMode() === 'search') {
            this.searchControl.patchValue('');
        } else {
            this.organizationStack.pop();

            if (this.organizationStack.length === 0) {
                this.parentId$.next(null);
            } else {
                const organization = this.organizationStack[this.organizationStack.length - 1];
                this.parentId$.next(organization.id);
            }
        }
    }

    async openCreateProjectPage() {
        const organization = this.organizationStack[this.organizationStack.length - 1];

        const modalRef = this.modalService.open(CreateProjectComponent, {
            data: {
                organization: organization.id
            }
        });

        modalRef.afterClosed.pipe(take(1)).subscribe(result => {
            if (result.message === 'SUCCESS') {
                this.projectListPaginator.refresh();
                this.route.navigate(['/projects', result.projectId]);
                return;
            }
        });
    }

    async pushParentId(parentId: number) {
        try {
            const result = await this.organizationService.getOne(parentId).toPromise();

            this.organizationStack.push(result);
            this.parentId$.next(parentId);

            if (this.searchControl.value !== '') {
                this.searchControl.setValue('');
            }
        } catch (e) {
            console.error('pushParentId error', e);
        }
    }

    getListTitle() {
        const topOrgName = this.organizationStack.length > 0
            ? this.organizationStack[this.organizationStack.length - 1].name
            : null;
        if (this.searchControl.value) {
            return topOrgName ? `Zoeken binnen ${topOrgName}` : 'Zoeken';
        } else if (topOrgName) {
            return topOrgName;
        } else {
            return 'Overzicht organisaties';
        }
    }

    getContentMode(): ContentMode {
        if (this.searchControl.value) {
            return 'search';
        } else if (this.organizationStack.length > 0) {
            return 'detail';
        } else {
            return 'root';
        }
    }
}
