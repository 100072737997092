import {Component, Inject} from '@angular/core';
import {NgVwuiTableConfig, VwuiModalService} from '@recognizebv/vwui-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../models/user';
import {UserService} from '../../services/user.service';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, combineLatest, defer, merge, of} from 'rxjs';
import {mapTo, switchMap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {Paginator} from '../../components/utils/paginator';
import {UserCreateModalComponent} from '../../components/user-create-modal/user-create-modal.component';
import {RolesList} from '../../models/roles-list';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html'
})
export class UsersListComponent {
    public environment = environment;
    searchControl = new FormControl('');
    tableConfig: NgVwuiTableConfig = {
        title: 'Gebruikers',
        selectable: false,
        clickable: true,
        compactable: true,
        indexKey: 'username',
        columns: [
            { header: 'Name', itemKey: 'username' },
            { header: 'Rollen', itemKey: 'roles'}
        ],
        menuOptions: []
    };

    refresh$ = new BehaviorSubject<void>(null);
    paginator$ = combineLatest([
        this.refresh$,
        merge(this.searchControl.valueChanges, defer(() => of(this.searchControl.value))),
    ]).pipe(
        switchMap(([_, search]) => {
            const paginator = new Paginator(page => {
                return this.userService.getList(page, search);
            });

            return paginator.content$.pipe(
                mapTo(paginator)
            );
        })
    );

    roleLabels = Object.fromEntries(RolesList.map(it => [it.key, environment.roleLabels[it.key]]));

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Inject('UserService') private userService: UserService,
        private modalService: VwuiModalService
    ) { }

    onUserClick(user: User) {
        this.router.navigate(['/manage/users', user.id]);
    }

    openCreateUserModal() {
        this.modalService.open(UserCreateModalComponent);
    }
}
