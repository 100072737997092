import {Component, Inject} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {VwuiModalRef} from '@recognizebv/vwui-angular';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {TagService, TagServiceImpl} from '../../services/tag.service';
import {Observable} from 'rxjs/Observable';
import {of, timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-tag-create-modal',
    templateUrl: './tag-create-modal.component.html',
})
export class TagCreateModalComponent {
    loading = false;
    form = new FormGroup({
        tag: new FormControl(
            '',
            [Validators.required, Validators.minLength(2), Validators.maxLength(40)],
            [this.validateNameNotTaken.bind(this)]
        ),
    });


    constructor(
        public modal: VwuiModalRef,
        @Inject('TagService') private tagService: TagService,
        private toastr: ToastrService,
        private router: Router
    ) {
    }

    async saveTag() {
        if (!this.form.valid) {
            return;
        }

        try {
            this.loading = true;
            await this.tagService.createTag(this.form.value.tag).toPromise();
            this.modal.close();
        } catch (e) {
            this.toastr.error('Tag aanmaken mislukt');
            console.error('Tag create failed', e);
        } finally {
            this.loading = false;
        }
    }

    private validateNameNotTaken(control: AbstractControl): Observable<ValidationErrors> {
        return timer(300).pipe(
            switchMap(() => {
                if (!control.value) {
                    return of(null);
                }

                return this.tagService.exists(control.value);
            }),
            map(exists => exists ? {exists: true} : null)
        );
    }
}
