<vwui-header>
    {{ user?.name }}
</vwui-header>
<vwui-layout>
    <form *ngIf="form" [formGroup]="form">
        <vwui-button label="Vorige" type="button" slot="action" class="mb-3" (click)="goBack()">
            Vorige
        </vwui-button>
        <vwui-card class="mb-5">
            <vwui-card-body layout="half">
                <vwui-form-group inputId="email" label="E-mailadres">
                    <vwui-input inputId="email" type="email" formControlName="username" readonly></vwui-input>
                </vwui-form-group>
                <vwui-form-group inputId="name" label="Naam">
                    <vwui-input inputId="name" type="text" formControlName="name" readonly></vwui-input>
                </vwui-form-group>
                <vwui-form-group label="Rollen">
                    <ng-select [items]="availableRoles$ | async"
                               [multiple]="true"
                               [hideSelected]="true"
                               bindLabel="label"
                               bindValue="key"
                               appendTo="body"
                               formControlName="roles"></ng-select>
                </vwui-form-group>
            </vwui-card-body>
        </vwui-card>
    </form>
    <ng-container *ngIf="paginator$|async as paginator">
        <ng-container *ngIf="(paginator.content$|async) as projects">
            <ng-container *ngIf="projects.length > 0">
                <div class="d-flex justify-content-between align-items-center mb-2">
                    <h4 class="my-3">Gekoppelde projecten</h4>
                    <vwui-button class="is-light-primary" *ngIf="selectedProjects.length > 0"
                                 (click)="openEditProjectManagerModal()">Projectverantwoordelijke wijzigen
                    </vwui-button>
                </div>
                <ng-vwui-table [config]="tableConfig" [data]="paginator.content$|async"
                               (tableRowSelect)="rowSelected($event)"
                               (tableAllSelect)="allSelected($event, projects)">
                    <ng-template table-item="organizations" let-item>
                        <span *ngFor="let organization of item.organizations; let isLast = last">
                            {{organization.organization.name}}<span *ngIf="!isLast">, </span>
                        </span>
                    </ng-template>
                    <ng-template table-item="projectResponsible" let-item>
                        <span *ngFor="let projectResponsible of item.projectResponsible; let isLast = last">
                            {{projectResponsible.user.name}}<span *ngIf="!isLast">, </span>
                        </span>
                    </ng-template>
                </ng-vwui-table>
            </ng-container>
        </ng-container>
    </ng-container>
</vwui-layout>
