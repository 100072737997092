import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {ProjectAlbumService} from '../../services/project-album.service';
import {ProjectAlbumEditComponent} from '../project-album-edit/project-album-edit.component';
import {ProjectAlbum} from '../../models/project-album';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {ProjectAlbumDeleteComponent} from '../project-album-delete/project-album-delete.component';
import {FormControl, FormGroup} from '@angular/forms';
import {combineLatest, merge, Subject} from 'rxjs';
import {MediaService} from '../../services/media.service';
import {ExternalUserService} from '../../services/external-user.service';
import {MsalService} from '@azure/msal-angular';
import {
    ProjectAlbumSelectMediaModalComponent
} from '../project-album-select-media-modal/project-album-select-media-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {InviteExternalUserComponent} from '../invite-external-user/invite-external-user.component';
import {ProjectService} from "../../services/project.service";
import {filterMsalAccounts} from '../../utils/filter-msal.accounts';
import {PermissionService} from '../../services/permission.service';
import {ExternalUserPageComponent} from "../external-user-page/external-user-page.component";
import {MediaProcessingComponent} from "../media-processing/media-processing.component";

@Component({
    selector: 'app-project-album-detail',
    templateUrl: './project-album-detail.component.html'
})
export class ProjectAlbumDetailComponent {
    filterForm = new FormGroup({
        search: new FormControl(''),
        fileTypes: new FormControl([]),
        orientation: new FormControl(null),
        exifCreatedBeteen: new FormControl('')
    });

    albumOverride$ = new Subject<ProjectAlbum>();
    album$ = merge(
        this.route.data.pipe(
            map(it => it.album as ProjectAlbum),
        ), this.albumOverride$
    ).pipe(
        shareReplay(1)
    );
    canUpload$ = this.album$.pipe(
        switchMap(album => this.permissionService.hasAlbumPermission(album.id, 'upload'))
    )

    mediaDetails$ = combineLatest([
        this.route.params,
        this.filterForm.valueChanges.pipe(startWith<any, any>(this.filterForm.value)),
        this.mediaDetailsService.mediaUpdated$
    ]).pipe(
        switchMap(([routeParams, filter]) => {
            // TODO: Implement pagination / infinite scroll
            return this.mediaDetailsService.getList(0, 1000, {
                orientation: filter.orientation,
                tagName: filter.search,
                fileName: filter.search,
                exifCreatedBeteen: filter.exifCreatedBeteen,
                album: routeParams.albumId,
            });
        }),
        shareReplay(1)
    );

    constructor(
        private mediaDetailsService: MediaService,
        private externalUserService: ExternalUserService,
        private msalService: MsalService,
        private route: ActivatedRoute,
        private albumService: ProjectAlbumService,
        private modalService: VwuiModalService,
        private bsModalService: BsModalService,
        private router: Router,
        private permissionService: PermissionService
    ) {
    }

    openInviteExternalUserModal(album: ProjectAlbum) {
        this.modalService.open(InviteExternalUserComponent, {
            data: {
                albumId: album.id,
                projectId: album.project.id
            },
            modalClass: 'preview-modal__modal'
        });
    }

    openUpdateAlbumModal(album: ProjectAlbum) {
        const modal = this.modalService.open(ProjectAlbumEditComponent, {data: {album}});
        modal.afterClosed.subscribe(updatedAlbum => {
            if (updatedAlbum) {
                this.albumOverride$.next(updatedAlbum);
            }
        });
    }

    openDeleteAlbumModal(album: ProjectAlbum) {
        const modal = this.modalService.open(ProjectAlbumDeleteComponent, {data: {album}});
        modal.afterClosed.subscribe(result => {
            if (result.message === 'SUCCESS') {
                this.router.navigate(['../..'], {relativeTo: this.route});
            }
        });
    }

    openMediaProcessingModal(album: ProjectAlbum) {
        const modal = this.bsModalService.show(ProjectAlbumSelectMediaModalComponent, {
            class: 'fullscreen-modal',
            initialState: {album}
        });
    }

    externalUserUploadModal(album: ProjectAlbum) {
        this.bsModalService.show(MediaProcessingComponent, {
            class: 'fullscreen-modal',
            initialState: {
                type: 'external',
                project: album.project,
                albums: [album]
            }
        });
    }
}
