import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Tag} from '../../models/tag';
import {TagService} from '../tag.service';
import {PageResponse} from '../../models/page-response';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {HttpParams} from '@angular/common/http';

export const mockTags: Array<Tag> = [

    {
        id: 1,
        name: 'Foto',
        createdAt: new Date(2020 - 20 - 10)
    },
    {
        id: 2,
        name: 'Video',
        createdAt: new Date(2020 - 20 - 10)
    },
];

@Injectable()
export class TagServiceMock extends AbstractCrudServiceMock<Tag> implements TagService {

    constructor() {
        super(mockTags);
    }

    exists(name: string, ignoreId: number): Observable<boolean> {
        return of(
            mockTags.some(tag => {
                const allowId = ignoreId ? tag.id !== ignoreId : true;
                return allowId && tag.name === name;
            })
        );
    }

    getTagsPage(page: number, search: string): Observable<PageResponse<Tag>> {
        return this.list(page, new HttpParams().set('search', search));
    }

    createTag(name: string) {
        return this.post({name, createdAt: new Date()});
    }

    getTag(tagId: number): Observable<Tag> {
        return this.detail(tagId);
    }

    updateTag(tag: Tag): Observable<Tag> {
        return this.put(tag.id, tag);
    }

    deleteTag(tag: Tag): Observable<any> {
        return this.delete(tag.id);
    }

    mergeTagsByName(tagId: number, destinationName: string): Observable<void> {
        return of(undefined);
    }
}
