<app-media-items-grid
    class="media-processing-grid"
    [mediaItems]="mediaItems"
    [showAddMedia]="showUpload"
    [showMediaInfo]="false"
    (mediaItemSelect)="onSelected($event)"
    (addMediaUpload)="upload.emit($event)"
    addMediaBtnType="upload"
    addMediaTitle="Media uploaden"
    addMediaDescription="Drag and drop of klik om te uploaden"
></app-media-items-grid>
