import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {Project} from '../models/project';
import {PageResponse} from '../models/page-response';
import {HasPermission} from '../models/project-download-permission';
import {environment} from '../../../environments/environment';
import {catchError, finalize, mapTo, shareReplay} from 'rxjs/operators';
import {ProjectAlbum} from '../models/project-album';
import LRU from 'lru-cache';
import {ProjectInvite} from '../models/project-invite';
import {filterMsalAccounts} from "../utils/filter-msal.accounts";
import {ExternalUserService} from "./external-user.service";
import {MsalService} from "@azure/msal-angular";
import {UserService} from './user.service';


@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    readonly projectUpdated$ = new BehaviorSubject<void>(null);

    constructor(
        private http: HttpClient,
        private externalUserService: ExternalUserService,
        private msalService: MsalService,
        @Inject('UserService') private userService: UserService
    ) {
        this.http = http;
    }

    createProject(project: Project) {
        return this.http.post<Project>(`${environment.apiPrefix}/projects`, project);
    }

    updateProject(project: Project) {
        return this.http.put<Project>(`${environment.apiPrefix}/projects/${project.id}`, project).pipe(
            finalize(() => {
                this.projectUpdated$.next();
            })
        );
    }

    deleteProject(project: Project) {
        return this.http.delete<Project>(`${environment.apiPrefix}/projects/${project.id}`).pipe(
            finalize(() => {
                this.projectUpdated$.next();
            })
        );
    }

    inviteUser(req: ProjectInvite): Observable<void> {
        return this.http.post<void>(`${environment.apiPrefix}/invite`, req);
    }

    getList(page: number, organization: number = null, projectResponsible: number = null, query: string = '', size: number = 25) {
        let params = new HttpParams({
            fromObject: {
                page: page.toString(),
                size: size.toString(),
                query
            }
        });
        if (organization) {
            params = params.set('organization', organization.toString());
        }
        if (projectResponsible) {
            params = params.set('projectResponsible', projectResponsible.toString());
        }
        return this.http.get<PageResponse<Project>>(`${environment.apiPrefix}/projects`, {params});
    }

    getPublicList(page: number, query: string = '', size: number = 25) {
        return this.http.get<PageResponse<Project>>(`${environment.apiPrefix}/projects/search/public`, {
            params: {
                page: page.toString(),
                size: size.toString(),
                query
            }
        });
    }

    getProjectResponsibleList(page: number, query: string = '', size: number = 25) {
        return this.http.get<PageResponse<Project>>(`${environment.apiPrefix}/projects/search/project-responsible`, {
            params: {
                page: page.toString(),
                size: size.toString(),
                query
            }
        });
    }

    getProjectDetail(projectId: number): Observable<Project> {
        return this.http.get<Project>(`${environment.apiPrefix}/projects/${projectId}`);
    }

    getPermissionDetails(projectId: number, albumId: number|null): Observable<HasPermission> {
        let params: {[key: string]: string} = {projectId: projectId.toString()};
        if (albumId) {
           params = {...params, albumId: albumId.toString()};
        }

        return this.http.get<HasPermission>(`${environment.apiPrefix}/fileStorage/hasPermission`, {
            params
        });
    }

    getAlbums(projectId: string, query: string, page: number) {
        return this.http.get<PageResponse<ProjectAlbum>>(`${environment.apiPrefix}/projects/${projectId}/albums`, {
            params: {
                query: query || '',
                page: page.toString()
            }
        });
    }

    getProjectsByUser(userId: string, page: number) {
        return this.http.get<PageResponse<Project>>(`${environment.apiPrefix}/projects/project-responsible/${userId}`, {
            params: {
                page: page.toString()
            }
        })
    }
}
