import {Component, NgZone} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {ProjectService} from '../../services/project.service';
import {map} from 'rxjs/operators';
import {MediaProcessingComponent} from '../media-processing/media-processing.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {MediaService} from '../../services/media.service';
import {ProjectDownloadPermissionService} from '../../services/project-download-permission.service';
import {environment} from '../../../../environments/environment';


@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
})
export class LayoutComponent {
    public environment = environment;

    pendingRequestCount$ = this.projectDownloadPermissionService.pendingRequests$.pipe(
        map(list => list.length) // TODO: Refactor this
    );
    pendingMediaCount$ = this.mediaService.pendingMediaCount$;
    stagedMediaCount$ = this.mediaService.stagedMediaCount$;

    projectsMenuOpen$ = new BehaviorSubject(false);

    constructor(
        private projectService: ProjectService,
        private projectDownloadPermissionService: ProjectDownloadPermissionService,
        private zone: NgZone,
        private bsModalService: BsModalService,
        private mediaService: MediaService
    ) {
    }

    openMediaProcessingModal(approve: boolean) {
        this.bsModalService.show(MediaProcessingComponent, {
            class: 'fullscreen-modal',
            initialState: {
                type: approve ? 'approve' : 'upload'
            }
        });
    }
}
