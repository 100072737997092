import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FeatureToggle} from '../../models/feature-toggle';
import {VistaCreateService, VistaCreateTemplate} from '../../services/vista-create.service';

@Component({
    selector: 'app-vista-create-template-select',
    templateUrl: './vista-create-template-select.component.html',
    styleUrls: []
})
export class VistaCreateTemplateSelectComponent {


    vistaToggle = FeatureToggle.VistaCreate;
    vistaCreateCategories$ = this.vistaService.vistaCreateUserEditCategories$();
    @Output() selectTemplate = new EventEmitter<VistaCreateTemplate>();

    constructor(
        private vistaService: VistaCreateService
    ) {
    }

    triggerSelectTemplate(template: VistaCreateTemplate) {
        this.selectTemplate.emit(template);
    }

}
