<ng-container *ngIf="vistaCreateCategories$ | async as vistaCategories">
    <ng-container *ngIf="vistaCategories.length === 1 && vistaCategories[0] as vistaCategory; else categoryListing">
        <app-action-button *appHasFeatureToggle="vistaToggle" [popover]="popover" iconName="vistacreate"></app-action-button>

        <ng-template #popover>
            <div class="popover-image-download">
                <ul class="popover-image-download__list">
                    <li *ngFor="let template of vistaCategory.templates" class="popover-image-download__list-item">
                        <button class="popover-image-download__list-button" (click)="triggerSelectTemplate(template)">{{template.title}} ({{template.width}}x{{template.height}})</button>
                    </li>
                </ul>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #categoryListing>
        <app-action-button *appHasFeatureToggle="vistaToggle" [popover]="popover" iconName="vistacreate"></app-action-button>

        <ng-template #popover>
            <div class="popover-image-download">
                <ul class="popover-image-download__list">
                    <ng-container *ngFor="let category of vistaCategories" class="popover-image-download__list-item">
                        <li class="popover-image-download__list-header">{{ category.title }}</li>
                        <li *ngFor="let template of category.templates">
                            <button class="popover-image-download__list-button" (click)="triggerSelectTemplate(template)">{{template.title}} ({{template.width}}x{{template.height}}{{template.measureUnits || 'px'}})</button>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </ng-template>
    </ng-template>
</ng-container>
