import {Component, forwardRef, Input} from '@angular/core';
import {FormBuilder, NG_VALUE_ACCESSOR} from '@angular/forms';
import {EntitySelectComponent} from './item-select.component';
import {Observable, of} from 'rxjs';
import {PageResponse} from '../../models/page-response';
import {Organization} from '../../models/organization';
import {OrganizationService} from '../../services/organization.service';

@Component({
    selector: 'app-organization-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrganizationSelectComponent),
            multi: true
        }
    ]
})
export class OrganizationSelectComponent extends EntitySelectComponent<Organization, {}> {
    @Input() excludeOrganizationId: number | undefined;
    constructor(
        private organizationService: OrganizationService,
        fb: FormBuilder
    ) {
        super(fb);
        this.placeholder = 'Selecteer een organisatie';
    }

    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: Organization): string {
        return item.name;
    }

    loadMore(page: number, filter: { term: string }): Observable<PageResponse<Organization>> {
        return this.organizationService.searchAll(filter.term, this.excludeOrganizationId, page);
    }

}
