<vwui-header>
    {{pageTitle}}

    <div slot="breadcrumbs">
        <vwui-breadcrumb>
            <a [routerLink]="'/display-media'">{{ environment.appName }}</a>
        </vwui-breadcrumb>
        <vwui-breadcrumb>{{pageTitle}}</vwui-breadcrumb>
    </div>
</vwui-header>
<vwui-layout>
    <div class="media-grid">
        <app-search-result-group
            type="projects"
            [results]="projectList"
            [hasMoreItems]="showLoadMoreProjects"
            (loadMore)="loadMoreProjects$.next(0)">
            <span title>Projecten</span>
        </app-search-result-group>
    </div>
</vwui-layout>
