import {Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ProjectService} from '../services/project.service';
import {NgIfContext} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {PermissionService} from '../services/permission.service';

@Directive({
    selector: '[appHasProjectReadAccess]'
})
export class HasProjectResponseReadAccessDirective implements OnInit, OnDestroy {
    sub: Subscription;

    @Input() appHasProjectReadAccess: TemplateRef<any> = this.templateRef;
    @Input() appHasProjectReadAccessElse: TemplateRef<any>;

    thenViewRef: EmbeddedViewRef<NgIfContext>|null = null;
    elseViewRef: EmbeddedViewRef<NgIfContext>|null = null;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private authService: MsalService,
        private route: ActivatedRoute,
        private permissionService: PermissionService,
    ) {}

    @Input()
    set appHasProjectReadAccessParams(params: {projectId: number}) {
        if (params && params.projectId) {
            this.renderViewWhenCurrentUserCanReadProject(params.projectId);
        }
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            if (params.projectId) {
                this.renderViewWhenCurrentUserCanReadProject(params.projectId);
            }
        });
    }

    async renderViewWhenCurrentUserCanReadProject(projectId) {
        try {
            const hasAccess = await this.permissionService.hasProjectPermission(projectId, 'read')
                .catch(() => false)

            if (hasAccess) {
                if (!this.thenViewRef) {
                    this.viewContainerRef.clear();
                    this.elseViewRef = null;
                    if (this.appHasProjectReadAccess) {
                        this.thenViewRef = this.viewContainerRef.createEmbeddedView(this.appHasProjectReadAccess);
                    }
                }
            } else {
                if (!this.elseViewRef) {
                    this.viewContainerRef.clear();
                    this.thenViewRef = null;
                    if (this.appHasProjectReadAccessElse) {
                        this.elseViewRef = this.viewContainerRef.createEmbeddedView(this.appHasProjectReadAccessElse);
                    }
                }
            }
        } catch (error) {
            console.error('Fail to get the records', error);
            this.viewContainerRef.clear();
        }
    }

    // Clear the subscription on destroy
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}

