import {Component} from '@angular/core';
import {NgVwuiTableConfig, VwuiModalService} from '@recognizebv/vwui-angular';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, combineLatest, defer, merge, of} from 'rxjs';
import {mapTo, switchMap, take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';
import {AssociationService} from '../../services/association.service';
import {Association} from '../../models/association';
import {Paginator} from '../../components/utils/paginator';
import {ConfirmationModalComponent} from '../../components/confirmation-modal/confirmation-modal.component';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-association-list',
    templateUrl: './association-list.component.html'
})
export class AssociationListPageComponent {
    public environment = environment;

    tableConfig: NgVwuiTableConfig = {
        title: environment.appNameShort === 'InFrame' ? 'Kantoorlocaties' : 'Bedrijfsnamen',
        selectable: false,
        clickable: true,
        indexKey: 'id',
        compactable: true,
        columns: [
            {header: 'Naam', itemKey: 'name'},
            {header: '', itemKey: 'delete'},
        ],
        menuOptions: []
    };

    searchControl = new FormControl('');
    refresh$ = new BehaviorSubject<void>(null);

    paginator$ = combineLatest([
        this.refresh$,
        merge(this.searchControl.valueChanges, defer(() => of(this.searchControl.value))),
    ]).pipe(
        switchMap(([_, search]) => {
            const paginator = new Paginator(page => {
                return this.associationService.search(search, page);
            });

            return paginator.content$.pipe(
                mapTo(paginator)
            );
        })
    );

    constructor(
        private router: Router,
        private associationService: AssociationService,
        private modalService: VwuiModalService,
        private toast: ToastrService
    ) {
    }

    onAssociationClick(association: Association) {
        this.router.navigate(['/manage/associations', association.id]);
    }

    async deleteAssociation(association: Association) {
        const modal = this.openDeleteConfirmationModal(association);
        const shouldDelete = await modal.afterClosed.pipe(take(1)).toPromise();
        if (!shouldDelete) {
            return;
        }

        try {
            await this.associationService.delete(association).toPromise();
            this.toast.success((environment.appNameShort === 'InFrame' ? 'Kantoorlocatie' : 'Bedrijfsnaam') + ' verwijderd');
            this.refresh$.next(null);
        } catch (error) {
            let message = `Kon ${environment.appNameShort === 'InFrame' ? 'kantoorlocatie' : 'bedrijfsnaam'} niet verwijderen.`;
            if (error instanceof HttpErrorResponse) {
                if (error.status === 409) {
                    message = `Kan ${environment.appNameShort === 'InFrame' ? 'kantoorlocatie' : 'bedrijfsnaam'} niet verwijderen omdat het nog gebruikt wordt door een organisatie.`;
                } else if (error.error?.message) {
                    message = error.error?.message
                }
            }

            this.toast.error(message);
        }
    }

    private openDeleteConfirmationModal(association: Association) {
        return this.modalService.open(ConfirmationModalComponent, {
            data: {
                title: `${environment.appNameShort === 'InFrame' ? 'Kantoorlocatie' : 'Bedrijfsnaam'} verwijderen`,
                message: `Weet je zeker dat je de ${environment.appNameShort === 'InFrame' ? 'kantoorlocatie' : 'bedrijfsnaam'} '${association.name}' wilt verwijderen?`,
                yesButtonText: 'Verwijderen',
                noButtonText: 'Annuleren',
            },
        });
    }
}
