import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Media} from '../../models/media';

@Component({
    selector: 'app-media-processing-content',
    templateUrl: './media-processing-content.component.html'
})
export class MediaProcessingContentComponent {
    @Input() showProjectName = false;
    @Input() showUpload = true;
    @Input() loading = false;
    @Input() mediaItems: Media[] = [];

    @Output() itemSelected = new EventEmitter<Media>();
    @Output() upload = new EventEmitter<FileList>();

    onSelected(mediaItem: Media) {
        this.itemSelected.emit(mediaItem);
    }
}
