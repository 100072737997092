<vwui-card>
    <vwui-card-body>
        <div class="empty-media-card">
            <img class="empty-media-card__image" [attr.src]="icon ? icon : '/assets/icons/empty.svg'">

            <h4 class="mt-4">
                <div #emptyMessage>
                    <ng-content></ng-content>
                </div>
                <span *ngIf="emptyMessage.children.length === 0">
                    Geen gevonden...
                </span>
            </h4>
        </div>
    </vwui-card-body>
</vwui-card>
