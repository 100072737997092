import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {Media} from '../../models/media';
import {CarouselComponent, OwlOptions} from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-image-modal-thumbnail-carousel',
    templateUrl: './image-modal-thumbnail-carousel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageModalThumbnailCarouselComponent implements AfterViewInit {
    @Input() media: Media[] = [];

    @Output() mediaClick = new EventEmitter<Media>();
    @Output() mediaSelect = new EventEmitter<Media>();

    @ViewChild('thumbnailCarousel') thumbnailCarousel: CarouselComponent;

    carouselOptions: OwlOptions = {
        nav: true,
        autoWidth: true,
        dots: false,
        items: 1
    };

    constructor(
        private cd: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this.cd.detectChanges();
    }

    selectAllMedia(event) {
        event.detail.checked
            ? this.media.map(it => it.isSelected = true)
            : this.media.map(it => it.isSelected = false)
    }

    get allChecked() {
        return this.selectedMedia === this.media.length;
    }

    get isIndeterminate() {
        return this.selectedMedia > 0 && this. selectedMedia < this.media.length;
    }

    get selectedMedia() {
        return this.media.filter(it => it.isSelected).length;
    }

    get prevBtnVisible() {
        return this.thumbnailCarousel && this.thumbnailCarousel.navData && !this.thumbnailCarousel.navData.prev.disabled;
    }

    get nextBtnVisible() {
        return this.thumbnailCarousel && this.thumbnailCarousel.navData && !this.thumbnailCarousel.navData.next.disabled;
    }
}
