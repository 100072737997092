<ng-container *ngIf="mediaDetails$|async as mediaDetails">
    <app-media-items-grid
        *ngIf="mediaDetails.content.length > 0 else mediaEmpty"
        gridTitle="Media items"
        [mediaItems]="mediaDetails.content"
        [showAddMedia]="allowAddMedia"
        [addMediaTitle]="addMediaButtonText"
        [addMediaDescription]="addMediaDescriptionText"
        (mediaItemSelect)="openImagePreviewModal($event, mediaDetails.content)"
        (addMediaBtnClick)="addMedia.emit()"
    ></app-media-items-grid>
</ng-container>

<ng-template #mediaEmpty>
    <vwui-empty-state *ngIf="allowAddMedia else mediaEmptyReadOnly" [image]="'/assets/icons/image-landscape.svg'"
                      messageTitle="Nog geen media toegevoegd"
                      message="Klik op de onderstaande knop om media toe te voegen">
        <vwui-button slot="button" (click)="addMedia.emit()">{{ addMediaButtonText }}</vwui-button>
    </vwui-empty-state>
</ng-template>

<ng-template #mediaEmptyReadOnly>
    <vwui-empty-state [image]="'/assets/icons/image-landscape.svg'"
                      messageTitle="Nog geen media toegevoegd"
                      message="Keer op een later moment terug">
    </vwui-empty-state>
</ng-template>

<app-progress-bar
    [showProgressBar]="showUploadProgress"
    [imageProgressBarCount]="uploadProgressCounter"
    [pbTotalCount]="uploadProgressTotalCount"
    [fileName]="uploadCurrentFilename"
    [totalSize]="uploadTotalBytes"
    [uploadProgress]="uploadProgress"
    [showCancelButton]="false"
></app-progress-bar>
