<vwui-modal-header>
    <div class="create-project__top">
        Uitnodigen
    </div>
</vwui-modal-header>
<vwui-modal-body>
    <form [formGroup]="inviteUserForm" (ngSubmit)="inviteExternalUser()">
        <vwui-form-group label="Versturen aan" inputId="externalUserEmail">
            <vwui-input [autofocus]="true" id="externalUserEmail" name="externalUserEmail" type="text"
                        formControlName="externalUserEmail">
            </vwui-input>
        </vwui-form-group>
        <vwui-form-group label="Uitnodigen voor" *ngIf="modalConfig.data.projectId">
            <div class="choice-group">
                <label class="choice-item radio" *ngIf="modalConfig.data.projectId">
                    <input type="radio" formControlName="permissionToExternalUser" value="Upload">
                    <span class="choice-item__label">
                        Uploaden
                    </span>
                </label>
                <label class="choice-item radio">
                    <input type="radio" formControlName="permissionToExternalUser" value="Download">
                    <span class="choice-item__label">
                        Downloaden
                    </span>
                </label>
                <label class="choice-item radio" *ngIf="modalConfig.data.projectId">
                    <input type="radio" formControlName="permissionToExternalUser" value="Both">
                    <span class="choice-item__label">
                        Beide
                    </span>
                </label>
            </div>
        </vwui-form-group>
        <div class="d-flex flex-gap-2">
            <vwui-form-group label="Geldigheidsduur uitnodiging" class="flex-fill">
                <ng-select [items]="durationChoices" bindValue="value" appendTo="body"
                           formControlName="validSeconds"></ng-select>
            </vwui-form-group>
            <vwui-form-group label="Verloopdatum uitnodiging" class="flex-fill">
                <vwui-input [style.--vwui-input--height]="'50px'" [readonly]="true"
                            [value]="inviteExpireDate$ | async | date:'dd-MM-yyyy HH:mm'">
                </vwui-input>
            </vwui-form-group>
        </div>
        <vwui-form-group label="Bericht">
            <vwui-textarea formControlName="mailContent"></vwui-textarea>
        </vwui-form-group>
    </form>
</vwui-modal-body>
<vwui-modal-footer [class.modal-footer__no-border]="modalConfig.data.albumId">
    <vwui-button class="is-light" (click)="modal.close()">Annuleren</vwui-button>
    <vwui-button class="is-secondary" (click)="inviteExternalUser()" [loading]="loading">Uitnodiging versturen
    </vwui-button>
</vwui-modal-footer>
<ng-container *ngIf="modalConfig.data.albumId">
    <ng-container *ngIf="album$ | async as album">
        <vwui-modal-footer class="modal-footer__action-left" *ngIf="canCreateShareLink$ | async">
            <vwui-button icon="link" class="is-light-primary" [loading]="creatingSharedLink"
                         (click)="getShareLink(album)">
                Deelbare link {{!album.sharedUrl ? 'genereren' : 'kopiëren'}}
            </vwui-button>
            <vwui-button *ngIf="album.sharedUrl" class="is-light-primary" (click)="removeSharedLink()">
                Link opheffen
            </vwui-button>
        </vwui-modal-footer>
    </ng-container>
</ng-container>
