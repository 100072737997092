import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    value = value.substring(0, args[0]);

    if (value.length === args[0]) {
      return value + '...';
    } else {
      return value;
    }

  }
}
