import {Component} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';

interface ConfirmationModalData {
    title: string;
    message: string;
    yesButtonText?: string;
    noButtonText?: string;
}

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent {
    modalData: ConfirmationModalData;

    constructor(
        public modal: VwuiModalRef,
        private modalConfig: VwuiModalConfig<ConfirmationModalData>,
    ) {
        this.modalData = modalConfig.data;
    }

    confirm() {
        this.modal.close(true);
    }

    cancel() {
        this.modal.close(false);
    }
}
