import {Component, OnInit} from '@angular/core';
import {ExternalUserService} from '../../services/external-user.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {MediaProcessingComponent} from '../media-processing/media-processing.component';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../../services/project.service';
import {ProjectAlbumService} from '../../services/project-album.service';
import {combineLatest} from 'rxjs';

@Component({
    selector: 'app-external-user-page',
    templateUrl: './external-user-page.component.html'
})
export class ExternalUserPageComponent implements OnInit {

    constructor(
        private externalUserService: ExternalUserService,
        private projectService: ProjectService,
        private projectAlbumService: ProjectAlbumService,
        private bsModalService: BsModalService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        const albumId = this.route.snapshot.params.albumId;
        if (undefined == albumId) {
            this.subscribeProject(this.route.snapshot.params.projectId);
        }
        else {
            this.subscribeProjectWithAlbum(this.route.snapshot.params.projectId, albumId);
        }
    }

    subscribeProjectWithAlbum(projectId: number, albumId: string) {
        combineLatest([
            this.projectAlbumService.getAlbum(albumId),
            this.projectService.getProjectDetail(projectId)
        ]).subscribe(([album, project]) => {
            this.bsModalService.show(MediaProcessingComponent, {
                class: 'fullscreen-modal',
                initialState: {
                    type: 'external',
                    project,
                    albums: [album]
                }
            });
        });
    }

    subscribeProject(projectId: number) {
        this.projectService.getProjectDetail(projectId).subscribe(project => {
            this.bsModalService.show(MediaProcessingComponent, {
                class: 'fullscreen-modal',
                initialState: {
                    type: 'external',
                    project
                }
            });
        });
    }
}
