<div class="preview-modal__wrapper image-editor image-editor__wrapper">
    <div class="image-editor__top-bar">
        <h3 class="mb-0">{{imageEditorTitle}}</h3>

        <vwui-button *ngIf="mode === 'edit'" class="ml-auto mr-2 is-primary" (click)="save()" [loading]="loading">Kopie opslaan</vwui-button>
        <vwui-button *ngIf="mode === 'download'" class="ml-auto mr-2 is-primary" (click)="download()">Downloaden</vwui-button>
        <vwui-button (click)="modalRef.close()">Annuleren</vwui-button>
    </div>

    <div class="image-editor__content" #content>
        <div class="image-editor__size" #size>
            <div class="image-editor__canvas-wrapper" *ngIf="scaledWidth && scaledHeight" [style.width.px]="scaledWidth" [style.height.px]="scaledHeight">
                <div class="image-editor__canvas__loader">
                    <vwui-spinner></vwui-spinner>
                </div>
                <canvas class="image-editor__canvas" [width]="scaledWidth" [height]="scaledHeight" #imageCanvas></canvas>

                <div class="image-editor__canvas__size-label__container" *ngIf="mode === 'edit' && currentHeight >= 0 && currentWidth >= 0" [style.width.px]="scaledWidth" [style.height.px]="scaledHeight">
                    <div class="image-editor__canvas__size-label" [style.transform]="getSizeLabelTransform()">{{ currentWidth }}x{{ currentHeight }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
