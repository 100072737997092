<div class="media-grid-generic-item" [class.media-grid-generic-item--has-thumbnail]="item.thumbnailUrl"
     [style.--thumbnail]="buildThumbnailUrl()">
    <div class="media-grid-generic-item__icon-container" *ngIf="!item.thumbnailUrl">
        <vwui-icon iconName="folder"></vwui-icon>
    </div>
    <div class="media-grid-generic-item__title-container">
        <div class="media-grid-generic-item__title">{{item.name}}</div>
        <div class="d-flex align-items-center justify-content-between">
            <div class="media-grid-generic-item__subtitle flex-shrink-1">{{ getOrganizationName() }}</div>
            <div class="media-grid-generic-item__badge">{{item.mediaCount}} {{labelPostfix}}</div>
        </div>
    </div>
</div>
