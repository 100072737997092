<vwui-header>
    {{ environment.appName }}
</vwui-header>
<vwui-layout>
    <form [formGroup]="form" (ngSubmit)="saveAssociation()">
        <vwui-button label="Vorige" type="button" slot="action" class="mb-3" routerLink="/manage/associations">
            Vorige
        </vwui-button>
        <vwui-card>
            <vwui-card-body layout="half">
                <vwui-form-group inputId="name" label="Naam">
                    <vwui-input inputId="name" type="text" formControlName="name"></vwui-input>
                    <div *ngIf="form.get('name').hasError('exists')" class="text-danger">
                        <small>Naam moet uniek zijn</small>
                    </div>
                </vwui-form-group>
            </vwui-card-body>
        </vwui-card>
        <vwui-button type="submit" class="is-primary mt-2" [loading]="loading" [disabled]="loading || form.invalid">Opslaan</vwui-button>
    </form>
</vwui-layout>
