import {AfterViewInit, Component, Input} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {map} from 'rxjs/operators';
import {ProjectService} from '../../services/project.service';
import {BehaviorSubject, combineLatest, defer, merge, Observable, of} from 'rxjs';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {ImageModalComponent} from '../image-modal/image-modal.component';
import {Media} from '../../models/media';
import {Project} from '../../models/project';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaListFilter, MediaService} from '../../services/media.service';
import {OrganizationService} from '../../services/organization.service';
import {ProjectAlbum} from '../../models/project-album';
import {Organization} from '../../models/organization';
import {InfiniteScrollPaginator} from '../utils/infinite-scroll-paginator';
import {ProjectAlbumService} from '../../services/project-album.service';
import {createEmptyPageResponse} from '../../models/page-response';
import {environment} from '../../../../environments/environment';
import {PublishedDesign} from '../../models/vista/published-design';
import {ToastrService} from 'ngx-toastr';
import {VistaCreateService} from '../../services/vista-create.service';
import {MediaProcessingComponent} from "../media-processing/media-processing.component";
import {BsModalService} from "ngx-bootstrap/modal";

interface FilterFormData {
    search: string;
    fileTypes: string[];
    orientation: 'portrait' | 'landscape';
    exifCreatedBeteen: string;
}

// TODO: Add filter / search to url
const SEARCH_PAGE_SIZE = 10;

@Component({
    selector: 'app-media-grid',
    templateUrl: './media-grid.component.html'
})
export class MediaGridComponent implements AfterViewInit {
    @Input() organizationId: number = null;

    public environment = environment;

    showUploadProgress = false;
    form = new FormGroup({
        search: new FormControl(''),
        fileTypes: new FormControl([]),
        orientation: new FormControl(null),
        exifCreatedBeteen: new FormControl('')
    });

    searchValue$: Observable<FilterFormData> = merge(this.form.valueChanges, defer(() => of(this.form.value)));
    searchActive$ = this.searchValue$.pipe(
        map(value => value.search !== '' || value.fileTypes.length > 0 || value.orientation !== null)
    );

    refresh$ = new BehaviorSubject<void>(null);
    mediaPaginator = new InfiniteScrollPaginator<Media, FilterFormData>(
        combineLatest([
            this.searchValue$, this.mediaService.mediaUpdated$, this.refresh$
        ]).pipe(map(([searchValue]) => searchValue)),
        (page, filter) => {
            const searchActive = filter.search !== ''
                || filter.fileTypes.length > 0
                || filter.orientation !== null
                || filter.exifCreatedBeteen !== '';
            const mediaFilter: MediaListFilter = {};
            if (filter.search) {
                mediaFilter.tagName = filter.search;
                mediaFilter.fileName = filter.search;
            }
            if (filter.fileTypes.length > 0) {
                mediaFilter.fileTypes = filter.fileTypes;
            }
            if (filter.orientation) {
                mediaFilter.orientation = filter.orientation;
            }
            if (filter.exifCreatedBeteen) {
                mediaFilter.exifCreatedBeteen = filter.exifCreatedBeteen;
            }

            return searchActive
                ? this.mediaService.getList(page, SEARCH_PAGE_SIZE, mediaFilter)
                : this.mediaService.getLatestList(page);
        }
    );
    albumPaginator = new InfiniteScrollPaginator<ProjectAlbum, FilterFormData>(
        this.searchValue$,
        (page, filter) => {
            return filter.search !== ''
                ? this.projectAlbumService.getList(page, null, filter.search, SEARCH_PAGE_SIZE)
                : of(createEmptyPageResponse());
        }
    );
    projectPaginator = new InfiniteScrollPaginator<Project, FilterFormData>(
        this.searchValue$,
        (page, filter) =>
            filter.search !== ''
                ? this.projectService.getList(page, null, null, filter.search, SEARCH_PAGE_SIZE)
                : of(createEmptyPageResponse())
    );
    organizationPaginator = new InfiniteScrollPaginator<Organization, FilterFormData>(
        this.searchValue$,
        (page, filter) =>
            filter.search !== ''
                ? this.organizationService.search(filter.search, page, SEARCH_PAGE_SIZE)
                : of(createEmptyPageResponse())
    );
    uploadCurrentFilename: string;
    uploadTotalBytes: number;

    constructor(private fb: FormBuilder,
                private projectService: ProjectService,
                private organizationService: OrganizationService,
                private projectAlbumService: ProjectAlbumService,
                private modalService: VwuiModalService,
                private mediaService: MediaService,
                private router: Router,
                private vistaCreateService: VistaCreateService,
                private toast: ToastrService,
                private bsModalService: BsModalService,
                private activatedRoute: ActivatedRoute) {
        this.form.patchValue(this.activatedRoute.snapshot.queryParams);

    }

    ngAfterViewInit() {
        this.form.valueChanges.subscribe(value => {
            this.router.navigate(['.'], { queryParams: value});
        });
    }

    openImagePreviewModal(media: Media, otherMedia: Media[]) {
        this.modalService.open(ImageModalComponent, {
            data: {
                selected: media,
                canMoveMedia: false,
                inMemoryImages: JSON.parse(JSON.stringify(otherMedia)),
                vistaPublished: async (data: PublishedDesign, media: Media) => {
                    try {
                        this.showUploadProgress = true;

                        const imageData = await fetch(data.url);
                        const blob = await imageData.blob();

                        this.uploadCurrentFilename = media.fileName.split('.').slice(0, -1).join('.') + '_VC.' + data.extension;
                        this.uploadTotalBytes = blob.size

                        const newMedia = await this.vistaCreateService.uploadVistaResult(media, blob, this.uploadCurrentFilename);

                        this.openMediaProcessingModal(newMedia);
                        this.refresh$.next();
                    } catch (exception) {
                        this.toast.error(exception.message);
                    } finally {
                        this.showUploadProgress = false;
                        this.refresh$.next();
                    }
                },
                vistaClosed: (media: Media) => {
                    this.refresh$.next();
                    this.openImagePreviewModal(media, otherMedia);
                }
            },
            modalClass: 'preview-modal__modal'
        });
    }

    openMediaProcessingModal(media: Media) {
        this.bsModalService.show(MediaProcessingComponent, {
            class: 'fullscreen-modal',
            initialState: {
                type: 'upload',
                mediaItems: [media]
            }
        });
    }
}
