export class FileUtil {

    static downloadBlobAsFile(blob: Blob, filename: string) {
        // Blob download link
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a') as any;

        a.href = url;
        a.download = filename;

        // Add link to DOM as firefox wont allow clicks on elements not in the DOM.
        a.style.display = 'none';
        document.body.appendChild(a);

        a.click();

        setTimeout(() => {
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }, 100);
    }

    static getContentDispositionFilename(contentDisposition: string) {
        const matches = /filename="([^"]+)"/.exec(contentDisposition);
        return matches && matches[1];
    }

    static getFileExtension(fileName: string) {
        const lastIndex = fileName.lastIndexOf('.') + 1;
        return fileName.substring(lastIndex, fileName.length).toUpperCase();
    }

}
