import { Component } from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {ProjectAlbum} from '../../models/project-album';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Project, ProjectVisibility} from '../../models/project';
import {ProjectAlbumService} from '../../services/project-album.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-project-album-edit',
  templateUrl: './project-album-edit.component.html'
})
export class ProjectAlbumEditComponent {
    loading = false;
    form = new FormGroup({
        id: new FormControl(null),
        name: new FormControl('', Validators.required),
        visibility: new FormControl(ProjectVisibility.Private),
        downloadApprovalRequired: new FormControl(false),
        project: new FormControl(null)
    });

    constructor(
        private toast: ToastrService,
        private projectAlbumService: ProjectAlbumService,
        private modalConfig: VwuiModalConfig<{ project?: Project, album?: ProjectAlbum }>,
        public modal: VwuiModalRef,
    ) {
        if (modalConfig.data.album) {
            this.form.patchValue(modalConfig.data.album);
        } else if (modalConfig.data.project) {
            this.form.patchValue({
                project: modalConfig.data.project,
                visibility: modalConfig.data.project.visibility,
                downloadApprovalRequired: modalConfig.data.project.downloadApprovalRequired,
            });
        } else {
            console.error('Invalid modalConfig passed to CreateProjectAlbumComponent');
        }
    }

    async save() {
        if (this.loading || !this.form.valid) {
            return;
        }

        try {
            this.loading = true;
            const album = this.form.value.id
                ? await this.projectAlbumService.updateAlbum(this.form.value).toPromise()
                : await this.projectAlbumService.createAlbum(this.form.value).toPromise();
            this.modal.close(album);
        } catch (e) {
            this.toast.error('Album opslaan mislukt');
        } finally {
            this.loading = false;
        }
    }
}
