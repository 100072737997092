import {Injectable} from '@angular/core';
import {parseJwtPayload} from '../components/utils/jwt';

export const EXTERNAL_JWT_TOKEN = 'beeldbank-external-token';
export const SHARE_LINK_KEY = 'beeldbank-share-link-key';

@Injectable({
    providedIn: 'root'
})
export class ExternalUserService {
    storeExternalUserSession(token: string) {
        sessionStorage.setItem(EXTERNAL_JWT_TOKEN, token);
    }

    clearExternalUserSession() {
        sessionStorage.removeItem(EXTERNAL_JWT_TOKEN);
    }

    isExternalUser(): boolean {
        return !!this.getExternalToken() || !!this.getShareLinkKey();
    }

    hasDownloadPermission(): boolean {
        const payload = parseJwtPayload(this.getExternalToken());
        const permissions = ['Download', 'Both'];
        return payload && payload.projectPermission && permissions.includes(payload.projectPermission);
    }

    hasUploadPermission(): boolean {
        const payload = parseJwtPayload(this.getExternalToken());
        const permissions = ['Upload', 'Both'];
        return payload && payload.projectPermission && permissions.includes(payload.projectPermission);
    }

    getExternalToken(): string {
        return sessionStorage.getItem(EXTERNAL_JWT_TOKEN);
    }

    storeShareLinkKey(key: string) {
        sessionStorage.setItem(SHARE_LINK_KEY, key)
    }

    getShareLinkKey(): string {
        return sessionStorage.getItem(SHARE_LINK_KEY);
    }

    hasShareLinkKey(): boolean {
        return !!sessionStorage.getItem(SHARE_LINK_KEY);
    }

    clearShareLinkKey() {
        sessionStorage.removeItem(SHARE_LINK_KEY)
    }
}
