<div class="project-list-menu">
    <div class="project-list-menu__search">
        <vwui-search placeholder="Zoeken..." [formControl]="searchControl"></vwui-search>
    </div>
    <div class="vista-create-list" *ngFor="let category of (filteredCategories$ | async)">
        <div class="vista-create-list-header">{{category.title}}</div>
        <app-project-list-item *ngFor="let item of (category.templates)"
                               [label]="item.title + ' (' + item.width + 'x' + item.height + (item.measureUnits || 'px') + ')'"
                               (click)="startVista(item)"></app-project-list-item>
    </div>
</div>

<app-progress-bar
    [showProgressBar]="showUploadProgress"
    [imageProgressBarCount]="1"
    [pbTotalCount]="1"
    [fileName]="uploadCurrentFilename"
    [totalSize]="uploadTotalBytes"
    [uploadProgress]="uploadProgress"
    [showCancelButton]="false"
></app-progress-bar>
