<vwui-header>
    {{ environment.appName }}
</vwui-header>
<div class="media-grid-filter">
    <app-search-input name="mediaSearch" placeholder="Zoeken..." [formControl]="searchControl"></app-search-input>
</div>
<vwui-layout *ngIf="paginator$ | async as paginator" class="mt-5">
    <ng-vwui-table [config]="tableConfig" [data]="paginator.content$|async" (rowClick)="onUserClick($event)">
        <div slot="table-actions">
            <vwui-button class="is-action mr-2" icon="vwui-add" (click)="openCreateUserModal()">
                Gebruiker aanmaken
            </vwui-button>
        </div>
        <vwui-pagination slot="table-pagination"
                         [totalPages]="paginator.totalPages"
                         [currentPage]="paginator.currentPage + 1"
                         (vwuiPaginationChange)="paginator.setPage($event.detail - 1)">
        </vwui-pagination>
        <ng-template table-item="roles" let-item>
            <vwui-badge *ngFor="let role of item.roles | filterHiddenRoles" variant="secondary" class="mr-2">
                {{roleLabels[role]}}
            </vwui-badge>
        </ng-template>
    </ng-vwui-table>
</vwui-layout>
