import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ProjectService} from '../../services/project.service';
import {ProjectAlbumService} from '../../services/project-album.service';
import {defer, merge, Observable, of, Subscription} from 'rxjs';
import {InfiniteScrollPaginator} from '../../components/utils/infinite-scroll-paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-public-data-page',
    templateUrl: './public-data-page.component.html'
})
export class PublicDataPageComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    searchControl = new FormControl('');
    searchValue$: Observable<string> = merge(this.searchControl.valueChanges, defer(() => of(this.searchControl.value)));

    projectPaginator = new InfiniteScrollPaginator(
        this.searchValue$,
        (page, query) => this.projectService.getPublicList(page, query)
    );

    albumPaginator = new InfiniteScrollPaginator(
        this.searchValue$,
        (page, query) => this.albumService.getPublicList(page, query)
    );

    constructor(
        private projectService: ProjectService,
        private albumService: ProjectAlbumService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParams.query) {
            this.searchControl.setValue(this.route.snapshot.queryParams.query);
        }
        this.subscriptions.push(this.searchControl.valueChanges.pipe(
            debounceTime(250)
        ).subscribe(query => this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {query}
        })));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

}
