import { Component, OnInit, Input } from '@angular/core';
import {Organization} from '../../models/organization';

@Component({
    selector: 'app-media-grid-org-item',
    templateUrl: './media-grid-org-item.component.html'
})
export class MediaGridOrgItemComponent implements OnInit {
    @Input() organization: Organization;

    labelPostfix: string;

    ngOnInit(): void {
        this.updateLabelTitle(this.organization.projectCount);
    }

    private updateLabelTitle(projectCount: number) {
        this.labelPostfix = projectCount && projectCount === 1 ? 'project' : 'projecten';
    }

}
