import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable, of, Subscription} from 'rxjs';
import {map, shareReplay, take} from 'rxjs/operators';
import {User} from '../../models/user';
import {UserService} from '../user.service';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {RolesList} from '../../models/roles-list';

export const mockUsers: Array<User> = [

    {
        id: 1,
        name: 'Kwasi Kattah',
        username: 'k.kattah@recognize.nl',
        roles: ['ROLE_ORGANIZATION_ADMIN', 'ROLE_COMMUNICATION_OFFICER']
    },
    {
        id: 2,
        name: 'Juul Hobert',
        username: 'j.hobert@recognize.nl',
        roles: ['ROLE_ORGANIZATION_ADMIN', 'ROLE_COMMUNICATION_OFFICER']
    },
    {
        id: 3,
        name: 'Guus oude Kotte',
        username: 'g.oudekotte@recognize.nl',
        roles: ['ROLE_ORGANIZATION_ADMIN', 'ROLE_COMMUNICATION_OFFICER']
    },
];

@Injectable()
export class UserServiceMock extends AbstractCrudServiceMock<User> implements UserService {
    private cachedRoles$ = this.getCurrentUser().pipe(
        map(it => it.roles),
        shareReplay({
            refCount: false,
            bufferSize: 1
        })
    );

    constructor() {
        super(mockUsers);
    }

    isCommunicationOfficer(): Observable<boolean> {
        return this.cachedRoles$.pipe(map(result => {
            return result.includes('ROLE_COMMUNICATION_OFFICER')
        }))
    }

    getList(page: number, query?: string) {
        let params = new HttpParams();
        if (query !== undefined) {
            params = params.set('search', query);
        }
        return this.list(page, params);
    }

    getCurrentUser() {
        return of(mockUsers[0]);
    }

    getUser(userId: number): Observable<User> {
        return this.detail(userId);
    }

    updateUser(user: User): Observable<User> {
        return this.put(user.id, user);
    }

    createUser(user: User): Observable<User> {
        return this.post(user);
    }

    getEnabledRoles(): Observable<string[]> {
        return of(RolesList.map(it => it.key));
    }
}
