import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Media} from '../models/media';
import {PageResponse} from '../models/page-response';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {finalize, map, shareReplay, switchMap} from 'rxjs/operators';
import {ProjectService} from './project.service';
import {TagRequest} from '../models/tag-request';
import {TagResponse} from '../models/tag-response';
import {PrepareUploadRequest} from '../models/prepare-upload-request';
import {PrepareUploadResponse} from '../models/prepare-upload-response';
import {FileType} from '../models/file-type';


export interface MediaListFilter {
    project?: string;
    album?: string;
    fileTypes?: string[];
    orientation?: 'portrait' | 'landscape';
    tagName?: string | string[];
    fileName?: string;
    exifCreatedBeteen?: string;
}

@Injectable({
    providedIn: 'root'
})
export class MediaService {
    readonly mediaUpdated$ = new BehaviorSubject<void>(null);
    readonly pendingMediaCount$ = this.mediaUpdated$.pipe(
        switchMap(() => this.getPendingList(0, 1).pipe(map(it => it.totalElements))),
        shareReplay(1)
    );
    readonly stagedMediaCount$ = this.mediaUpdated$.pipe(
        switchMap(() => this.getStagedList(0, 1).pipe(map(it => it.totalElements))),
        shareReplay(1)
    );

    constructor(
        private client: HttpClient,
        @Inject('ProjectService') private projectService: ProjectService
    ) {
    }

    getList(
        page: number,
        size: number,
        mediaListFilter: MediaListFilter
    ) {
        return this.client.get<PageResponse<Media>>(`${environment.apiPrefix}/media`, {
            params: {page: page.toString(), size: size.toString(), ...mediaListFilter}
        });
    }

    getPendingList(
        page: number,
        size: number
    ) {
        return this.client.get<PageResponse<Media>>(`${environment.apiPrefix}/media/search/pending`, {
            params: {page: page.toString(), size: size.toString()}
        });
    }

    getStagedList(
        page: number,
        size: number
    ) {
        return this.client.get<PageResponse<Media>>(`${environment.apiPrefix}/media/search/staged`, {
            params: {page: page.toString(), size: size.toString()}
        });
    }

    getLatestList(
        page: number,
        size: number = 10
    ) {
        return this.client.get<PageResponse<Media>>(`${environment.apiPrefix}/media/search/latest`, {
            params: {page: page.toString(), size: size.toString()}
        });
    }

    get(id: number) {
        return this.client.get<Media>(`${environment.apiPrefix}/media/${id}`);
    }

    postTagRequest(req: TagRequest): Observable<TagResponse> {
        return this.client.post<TagResponse>(`${environment.apiPrefix}/media/save-form`, req).pipe(
            finalize(() => {
                this.projectService.projectUpdated$.next();
            })
        );
    }

    patchTags(mediaId: number, tagIds: number[]) {
        return this.client.patch<Media>(`${environment.apiPrefix}/media/${mediaId}/tags`, {tagIds});
    }

    delete(media: Media) {
        return this.client.delete<void>(`${environment.apiPrefix}/media/${media.id}`);
    }

    prepareUpload(request: PrepareUploadRequest) {
        return this.client.post<PrepareUploadResponse>(`${environment.apiPrefix}/media/prepare-upload`, request);
    }

    finishUpload(media: Media) {
        return this.client.post<Media>(`${environment.apiPrefix}/media/${media.id}/finish-upload`, null);
    }

    fixMissingThumbnail(media: Media) {
        return this.client.post<void>(`${environment.apiPrefix}/media/${media.id}/fix-missing-thumbnail`, null);
    }

    getAllFileTypes(): Observable<FileType[]> {
        return this.client.get<FileType[]>(`${environment.apiPrefix}/media/file-types`);
    }

    fetchMedia(mediaId: number) {
        return this.client.get<{ url: string }>(`${environment.apiPrefix}/fileStorage/downloadMedia`, {
            params: {mediaId: mediaId.toString()}
        });
    }

    downloadMultiple(mediaIds: number[]) {
        return this.client.post(
            `${environment.apiPrefix}/fileStorage/multipleDownload`,
            {media: mediaIds},
            {responseType: 'blob'}
        ).toPromise();
    }

    public fetchCroppedThumbnail(media: Media, x: number, y: number, width: number, height: number, scale: number) {
        return this.client.get(`${environment.apiPrefix}/media/${media.id}/cropped-thumbnail`, {
            responseType: 'blob',
            observe: 'response',
            params: {
                x: x.toString(),
                y: y.toString(),
                width: width.toString(),
                height: height.toString(),
                scale: scale.toString()
            }
        });
    }

    createCroppedCopy(media: Media, x: number, y: number, width: number, height: number, scale: number) {
        return this.client.post<Media>(`${environment.apiPrefix}/media/${media.id}/cropped-copy`, null, {
            params: {
                x: x.toString(),
                y: y.toString(),
                width: width.toString(),
                height: height.toString(),
                scale: scale.toString()
            }
        });
    }

    public loadScaledImage(image: Media, width: number, height: number) {
        return this.client.get(`${environment.apiPrefix}/fileStorage/downloadThumbnail`, {
            params: {
                mediaId: image.id.toString(),
                height: height.toString(),
                width: width.toString(),
            },
            responseType: 'blob'
        }).pipe(map((response: Blob) => URL.createObjectURL(response))).toPromise();
    }

    public fetchScaledImage(image: Media, width: number, height: number) {
        return this.client.get(`${environment.apiPrefix}/fileStorage/downloadThumbnail`, {
            params: {
                mediaId: image.id.toString(),
                height: height.toString(),
                width: width.toString(),
            },
            responseType: 'blob'
        });
    }
}
