<vwui-header>
    {{ environment.appName }}
</vwui-header>
<div class="media-grid-filter">
    <app-search-input name="mediaSearch" placeholder="Zoeken..." [formControl]="searchControl"></app-search-input>
</div>
<ng-container *ngIf="paginator$ | async as paginator">
    <vwui-layout class="mt-5">
        <ng-vwui-table [config]="tableConfig" [data]="paginator.content$|async" (rowClick)="onTagClick($event)">
            <div slot="table-actions">
                <vwui-button class="is-action mr-2" icon="vwui-add" (click)="openCreateTagModal()">
                    Tag aanmaken
                </vwui-button>
            </div>
            <vwui-pagination slot="table-pagination"
                             [totalItems]="paginator.totalElements"
                             [totalPages]="paginator.totalPages"
                             [currentPage]="paginator.currentPage + 1"
                             [firstItem]="paginator.firstItem"
                             [lastItem]="paginator.lastItem"
                             (vwuiPaginationChange)="paginator.setPage($event.detail - 1)">
            </vwui-pagination>
            <ng-template table-item="delete" let-item><img class="delete-icon"  src="assets/icons/trash.svg" (click)="deleteTag(item); $event.stopPropagation()"></ng-template>
        </ng-vwui-table>
    </vwui-layout>
</ng-container>
