import {VWUIFlatpickrOptions} from '@recognizebv/vwui-core';

export const defaultDateRangePickerOptions: VWUIFlatpickrOptions = {
    mode: 'range',
    dateFormat: 'Y-m-d',
    altFormat: 'd-m-Y',
    ariaDateFormat: 'd-m-Y',
    locale: {
        rangeSeparator: ' - '
    },
    allowInput: true
};
