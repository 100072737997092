import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProjectDownloadPermission} from '../models/project-download-permission';
import {finalize, shareReplay, switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProjectDownloadPermissionService {
    private pendingProjectRequestsRefresh$ = new BehaviorSubject<void>(null);
    public pendingRequests$ = this.pendingProjectRequestsRefresh$.pipe(
        switchMap(() => this.getPendingRequests()),
        shareReplay(1)
    );

    constructor(private client: HttpClient) {
    }

    createPermissionRequest(projectId: string): Observable<ProjectDownloadPermission> {
        return this.client.post<ProjectDownloadPermission>(`${environment.apiPrefix}/project-download-permissions`, null, {
            params: {projectId}
        });
    }

    updatePermissionRequest(permissionId: number, action: 'approve' | 'deny') {
        return this.client.post<void>(`${environment.apiPrefix}/project-download-permissions/${permissionId}/${action}`, null).pipe(
            finalize(() => this.pendingProjectRequestsRefresh$.next())
        );
    }

    getPendingRequests(): Observable<ProjectDownloadPermission[]> {
        return this.client.get<ProjectDownloadPermission[]>(`${environment.apiPrefix}/project-download-permissions/non-approved`);
    }
}
