import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Association} from '../models/association';
import {environment} from '../../../environments/environment';
import {PageResponse} from '../models/page-response';

@Injectable({
    providedIn: 'root'
})
export class AssociationService {
    constructor(private http: HttpClient) {
        this.http = http;
    }

    search(query: string, page: number, size: number = 25) {
        const params = new HttpParams({fromObject: {query, page: page.toString(), size: size.toString()}});

        return this.http.get<PageResponse<Association>>(`${environment.apiPrefix}/associations`, {params});
    }

    getOne(id: number) {
        return this.http.get<Association>(`${environment.apiPrefix}/associations/${id}`);
    }

    create(association: Association) {
        return this.http.post<Association>(`${environment.apiPrefix}/associations`, association);
    }

    update(association: Association) {
        return this.http.put<Association>(`${environment.apiPrefix}/associations/${association.id}`, association);
    }

    delete(association: Association) {
        return this.http.delete(`${environment.apiPrefix}/associations/${association.id}`);
    }

    exists(name: string, ignoreId: number = null) {
        const params: {[key: string]: string} = {name};
        if (ignoreId) {
            params.ignoreId = ignoreId.toString();
        }

        return this.http.get<boolean>(`${environment.apiPrefix}/associations/exists`, {params});
    }
}
