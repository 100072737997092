import {HttpParams} from '@angular/common/http';
import {Identifiable} from '../../models/identifiable';
import {EMPTY, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractCrudServiceInterface} from '../abstract-crud.service';
import {PageResponse, wrapInPageResponse} from '../../models/page-response';
import {Postable, Putable} from '../../models/rest-type-helpers';

export abstract class AbstractCrudServiceMock<T extends Identifiable> implements AbstractCrudServiceInterface<T> {
    protected constructor(protected data: T[]) {
    }

    list(page: number, params: HttpParams | undefined): Observable<PageResponse<T>> {
        return of(wrapInPageResponse(this.data));
    }

    detail(id: number): Observable<T> {
        return of(this.data.find(item => item.id === id));
    }

    post(resource: Postable<T>): Observable<T> {
        const item = {id: this.data.length + 1, ...resource as T};
        this.data = [...this.data, item];
        return of(item);
    }

    put(id: number, resource: Putable<T>): Observable<T> {
        return this.detail(id).pipe(
            map(item => Object.assign(item, resource))
        );
    }

    delete(id: number): Observable<void> {
        this.data = this.data.filter(it => it.id !== id);
        return EMPTY;
    }
}
