<div class="preview-modal__wrapper" [formGroup]="form">
    <div class="preview-modal__close" (click)="modalRef.close()">
        <vwui-icon iconName="close" class="preview-modal__close-icon"></vwui-icon>
    </div>

    <div class="preview-modal__body">
        <div class="preview-modal__content">
            <div class="preview-modal__top">
                <!-- TODO insert title when available -->
                {{ selectedMedia?.project?.name }}
                <div class="preview-modal__round-circle-holder">
                    <ng-container *ngIf="hasPermissionRes">
                        <app-action-button *ngIf="hasPermissionRes.projectResponsible && album !== null && album?.sharedUrl && selectedMedia.video === true" iconName="link" class="mr-2"
                                           [popover]="popover"></app-action-button>
                        <ng-template #popover>
                            <div class="popover-image-download">
                                <ul class="popover-image-download__list">
                                    <li class="popover-image-download__list-item">
                                        <button class="popover-image-download__list-button" (click)="getEmbedVideoText(album, selectedMedia)">
                                            Video insluiten
                                        </button>
                                        <button class="popover-image-download__list-button" (click)="getEmbedVideoLink(album, selectedMedia)">
                                            Video link
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                        <app-action-button *ngIf="hasPermissionRes.projectResponsible && this.canMoveMedia" iconName="move" class="mr-2"
                                           [popover]="moveMedia"></app-action-button>
                        <ng-template #moveMedia>
                            <div class="popover-image-download">
                                <ul class="popover-image-download__list">
                                    <li class="popover-image-download__list-item">
                                        <button class="popover-image-download__list-button" (click)="openMediaProcessingModal(selectedMedia)">
                                            {{ this.getMoveButtonLabel() }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                        <ng-container
                            *ngIf="hasPermissionRes.download && hasPermissionRes.upload && selectedMedia && canEdit && selectedMedia.width && selectedMedia.height">
                            <app-vista-create-template-select class="mr-2"
                                                              (selectTemplate)="startVista($event)"></app-vista-create-template-select>
                        </ng-container>

                        <app-image-download-documents [media]="selectedMedia" [documents]="selectedMedia.documents"
                                                      class="mr-2"></app-image-download-documents>

                        <ng-container *ngIf="hasPermissionRes.download; else requiredPermission">
                            <app-image-download-media
                                [disabled]="downloadLoading"
                                (download)="downloadMedia()"
                                [media]="selectedMedia"
                                (downloadThumbnail)="downloadThumbnail($event)"
                                (downloadScaled)="downloadScaled($event)"
                                [hasThumbnails]="canEdit && selectedMediaCount() < 2"
                            ></app-image-download-media>
                        </ng-container>

                        <ng-template #requiredPermission>
                            <app-image-download-request
                                [isPending]="hasPermissionRes.inProgress"
                                (requestProject)="requestPermission(selectedMedia?.project)"
                            ></app-image-download-request>
                        </ng-template>

                        <app-action-button *ngIf="hasPermissionRes.projectResponsible && canEdit" iconName="edit-2"
                                           class="ml-2" (click)="edit()"></app-action-button>

                        <app-action-button *ngIf="hasPermissionRes.projectResponsible" iconName="vwui-trash"
                                           class="ml-2" (click)="delete()"></app-action-button>
                    </ng-container>
                </div>
            </div>

            <div class="preview-modal__media" [class.preview-modal__media--video]="selectedMedia.video">
                <div class="preview-modal__image">
                    <img class="preview-modal__media-element" [src]="selectedMedia?.url" alt="">
                </div>

                <div class="preview-modal__video" [class.preview-modal__video--error]="!canPlayVideo">
                    <div class="preview-modal__video-holder">
                        <video
                            #videoPlayer
                            [src]="selectedMedia.videoUrl"
                            class="preview-modal__media-element"
                            width="900"
                            height="400"
                            (click)="toggleVideo()"
                            preload="metadata"
                            controls
                        >
                            Uw browser wordt niet ondersteund.
                        </video>
                    </div>

                    <div class="preview-modal__video-unavailable">
                        <span class="preview-modal__video-unavailable__text">Deze video kan niet in deze browser worden afgespeeld. Download de video, en speel deze op uw computer af.</span>
                    </div>
                </div>
            </div>
            <div class="preview-modal__bottom">
                <div class="preview-modal__bottom-spinner" *ngIf="loadingThumbnails">
                    <vwui-spinner></vwui-spinner>
                </div>

                <app-image-modal-thumbnail-carousel *ngIf="!loadingThumbnails"
                                                    [media]="displayAllMedia"
                                                    (mediaClick)="selectedThumb($event)"
                                                    (mediaSelect)="updateSelectedImage($event)"
                ></app-image-modal-thumbnail-carousel>
            </div>
        </div>

        <div class="preview-modal__sidebar">
            <div class="preview-modal__sidebar__title">
                Bestand details
            </div>

            <div class="preview-modal__sidebar-separator"></div>

            <div class="preview-modal__info-list">
                <app-image-modal-info-item label="Originele bestandsnaam">
                    {{ selectedMedia?.fileName }}
                </app-image-modal-info-item>
                <app-image-modal-info-item label="Door" *ngIf="!isExternalUser">
                    {{ selectedMedia?.createdBy }}
                </app-image-modal-info-item>
                <app-image-modal-info-item label="Datum">
                    {{ selectedMedia?.createdAt | date : 'dd-MM-yyyy' }}
                </app-image-modal-info-item>
                <app-image-modal-info-item label="Tags">
                    <ng-container *ngIf="!isExternalUser">
                        <app-media-info-tags
                            [media]="selectedMedia"
                            (tagClick)="modalRef.close()"
                            (tagsSaved)="onTagsSaved($event)"
                        ></app-media-info-tags>
                    </ng-container>
                    <ng-container *ngIf="isExternalUser">
                        <span *ngFor="let tag of selectedMedia?.tags">{{ tag.name }}</span>
                    </ng-container>
                </app-image-modal-info-item>
                <app-image-modal-info-item label="Bestandstype">
                    {{ selectedMedia?.fileType }}
                </app-image-modal-info-item>
                <app-image-modal-info-item label="Resolutie">
                    {{ selectedMedia?.width }} x {{ selectedMedia?.height }}
                </app-image-modal-info-item>
                <app-image-modal-info-item label="Bestandsgrootte">
                    {{ selectedMedia?.fileSize ? (selectedMedia?.fileSize | fileSize) : 'onbekend' }}
                </app-image-modal-info-item>
                <app-image-modal-info-item label="Foto gemaakt op">
                    {{ (selectedMedia?.exifCreatedAt | date : 'dd-MM-yyyy') || 'onbekend' }}
                </app-image-modal-info-item>
                <app-image-modal-info-item label="Project">
                    {{ selectedMedia?.project?.name }}
                </app-image-modal-info-item>
            </div>
        </div>
    </div>

</div>
