<ng-container *ngIf="project$ | async as project">
    <vwui-modal-header>
        <div class="create-project__top">
            Verwijder project
        </div>
    </vwui-modal-header>
    <vwui-modal-body>
        <p>Weet je zeker dat je project <strong>{{project.name}}</strong> wilt verwijderen?</p>
    </vwui-modal-body>
    <vwui-modal-footer>
        <vwui-button class="is-light" (click)="modal.close()">Annuleren</vwui-button>
        <vwui-button class="is-secondary" (click)="deleteProject(project)" [loading]="loading">Verwijderen</vwui-button>
    </vwui-modal-footer>
</ng-container>
