import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DocumentService} from '../../services/document.service';
import {Document} from '../../models/document';
import {FormControl} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';
import {Media} from '../../models/media';
import {ToastrService} from 'ngx-toastr';
import {MediaService} from '../../services/media.service';
import {environment} from '../../../../environments/environment';
import {ProjectService} from '../../services/project.service';
import {MsalService} from '@azure/msal-angular';
import {filterMsalAccounts} from "../../utils/filter-msal.accounts";
import {PermissionService} from '../../services/permission.service';

@Component({
    selector: 'app-image-download-documents',
    templateUrl: './image-download-documents.component.html'
})
export class ImageDownloadDocumentsComponent implements OnInit {
    @Input() media: Media;
    @Input() documents: Document[];
    @Output() download = new EventEmitter();

    pendingFiles: File[] = [];

    addFileControl = new FormControl(null);
    loadingFile = false;

    canEdit: Promise<boolean>|undefined;

    constructor(
        private authService: MsalService,
        private projectService: ProjectService,
        private documentService: DocumentService,
        private mediaService: MediaService,
        private toast: ToastrService,
        private permissionService: PermissionService,
    ) {
    }

    ngOnInit() {
        this.addFileControl.valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe(file => {
            if (file) {
                this.addFileControl.setValue(null, {emitEvent: false});
                this.uploadFile(file.file);
            }
        });

        this.canEdit = this.permissionService.hasProjectPermission(this.media?.project.id, 'projectResponsible');
    }

    async uploadFile(file: File) {
        if (file.size > environment.maxDocumentSizeMB * 1024 * 1024) {
            this.toast.error(`Het bestand mag niet groter zijn dan ${environment.maxDocumentSizeMB} MB.`);
            return;
        }
        this.pendingFiles.push(file);

        try {
            const document = await this.documentService.uploadFile(file);
            await this.documentService.linkDocumentWithMedia(this.media, document).toPromise();
            this.documents.push(document);
            this.mediaService.mediaUpdated$.next();
        } catch (error) {
            console.error(error);
            this.toast.error(`Het uploaden van bestand '${file.name}' is mislukt.`);
        } finally {
            this.pendingFiles = this.pendingFiles.filter(it => it !== file);
        }
    }

    async downloadFile(document: Document) {
        this.loadingFile = true;
        try {
            const {url} = await this.documentService.fetchDocument(document).toPromise();
            window.open(url, '_blank', 'noopener,noreferrer');
        } finally {
            this.loadingFile = false;
        }
    }

    async removeDocument(document: Document) {
        try {
            await this.documentService.deleteDocument(this.media, document).toPromise();

            this.documents = this.documents.filter(it => it !== document);
            this.mediaService.mediaUpdated$.next();
        } catch (error) {
            console.error(error);
            this.toast.error('Verwijderen mislukt');
        }
    }
}
