import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-empty-media-card',
    templateUrl: './empty-media-card.component.html',
})
export class EmptyMediaCardComponent {
    @Input() public search = false;
    @Input() public icon = null;
}
