import {Component, forwardRef, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {ToastrService} from 'ngx-toastr';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Tag} from '../../models/tag';
import {TagService} from '../../services/tag.service';
import {filteredInfiniteScrollObservable} from '../utils/pagination';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-tags-form-control',
    templateUrl: './tags-form-control.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagsFormControlComponent),
            multi: true
        }
    ]
})

export class TagsFormControlComponent implements OnInit, OnDestroy, ControlValueAccessor {

    refreshTags$ = new BehaviorSubject<void>(null);
    search = new BehaviorSubject<string>('');
    loadMoreSubject = new BehaviorSubject<void>(null);

    tags$ = filteredInfiniteScrollObservable(
        combineLatest([this.search, this.refreshTags$])
            .pipe(
                map(([filter, _]) => (filter))
            ),
        this.loadMoreSubject,
        (page, filter) => {
            this.loading = true;
            return this.tagService.getTagsPage(page, filter).pipe(
                tap(() => this.loading = false)
            );
        }
    );
    allowAddTag$ = this.userService.getCurrentUser().pipe(
        map(it => it !== null ? it.roles.includes('ROLE_ORGANIZATION_ADMIN') || it.roles.includes('ROLE_SUPER_ADMIN') : false)
    );

    maxTagLength = 40;
    formControl = new FormControl([]);
    onChange: (value: any) => any | undefined;
    onTouched: () => any | undefined;
    subscriptions: Subscription[] = [];
    loading: boolean;

    constructor(
        private projectService: ProjectService,
        @Inject('TagService') private tagService: TagService,
        @Inject('UserService') private userService: UserService,
        private toast: ToastrService,
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.formControl.valueChanges.subscribe((value) => {
                if (this.onTouched !== undefined) {
                    this.onTouched();
                }
                if (this.onChange !== undefined) {
                    this.onChange(value);
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    validateTag = (term): Partial<Tag> => {
        if (term.length <= this.maxTagLength) {
            return {name: term};
        } else if (term.length > this.maxTagLength) {
            this.toast.warning('Maximum aantal karakters van tag overschreden');
        }

        return null;
    }

    async onAddTag() {
        const newTags = this.formControl.value.filter((tag: Tag) => !tag.id);
        for (const tag of newTags) {
            try {
                const result = await this.tagService.createTag(tag.name).toPromise();
                this.formControl.value.forEach((item: Tag) => {
                    if (item.name === result.name) {
                        item.id = result.id;
                    }
                });

                this.formControl.patchValue(this.formControl.value);
            } catch (e) {
                console.error('onAddTag error', e);
                this.toast.error('Tag kon niet worden aangemaakt.');
            }
        }
    }

    registerOnChange(fn: any): void {
        if (typeof fn !== 'function') {
            console.warn('Ignoring onChange registered function for TagsFormControl');
            return;
        }

        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        if (typeof fn !== 'function') {
            console.warn('Ignoring onTouched registered function for TagsFormControl');
        }

        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        throw new Error('Disabled state has not been implemented for TagsFormControl');
    }

    writeValue(obj: any): void {
        this.formControl.setValue(obj, {emitEvent: false});
    }
}
