import {Component, forwardRef, Inject, Input} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormBuilder } from '@angular/forms';
import { EntitySelectComponent } from './item-select.component';
import { Observable, of } from 'rxjs';
import { PageResponse } from '../../models/page-response';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-user-select',
    templateUrl: './item-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserSelectComponent),
            multi: true
        }
    ]
})
export class UserSelectComponent extends EntitySelectComponent<User, {}> {
    constructor(
        @Inject('UserService') private userService: UserService,
        fb: FormBuilder
    ) {
        super(fb);
        this.placeholder = 'Selecteer een gebruiker';
    }
    getFilter(): Observable<{}> {
        return of({});
    }

    getLabelText(item: User): string {
        return item.name;
    }

    loadMore(page: number, filter: {term: string}): Observable<PageResponse<User>> {
        return this.userService.getList(page, filter.term);
    }

}
