import { Pipe, PipeTransform } from '@angular/core';

type unitTypes = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
    [u in unitTypes]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
    bytes: 0,
    KB: 0,
    MB: 1,
    GB: 1,
    TB: 2,
    PB: 2
};

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that can be a number or a map for each unit.
 * Usage:
 *   bytes | fileSize:precision
 * @example
 * // returns 1 KB
 * {{ 1500 | fileSize }}
 * @example
 * // returns 2.1 GB
 * {{ 2100000000 | fileSize }}
 * @example
 * // returns 1.46 KB
 * {{ 1500 | fileSize:2 }}
 */
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
    private readonly units: unitTypes[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    transform(bytes: number = 0, precision: number | unitPrecisionMap = defaultPrecisionMap): string {
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) { return '?'; }

        let unitIndex = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unitIndex++;
        }

        const unit = this.units[unitIndex];

        const fractionDigits = typeof precision === 'number' ? +precision : precision[unit];
        const formattedBytes = bytes.toLocaleString('nl-NL', {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        });

        return `${formattedBytes} ${unit}`;
    }
}
